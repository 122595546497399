import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import usePermission from "../../hooks/usePermission";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";

function OperationConfigModal(props: any) {
	const context: any = useContext(HomeContext);

	// form related
	const [formRef] = useForm();
	const [formReset, setFormReset] = useState<boolean>(false);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [activeRowKey, setActiveRowKey] = useState<any>(null);
	const [cancelPopover, setCancelPopover] = useState<boolean>(false);
	const [deletePopover, setDeletePopover] = useState<boolean>(false);
	const [currentProject, setCurrentProject] = useState<any>(
		props.currentProjectData
	);

	const [operationInfo, setOperationInfo] = useState<any>(null);

	const hasDeleteOperationPermission = usePermission(
		"Delete.Operation",
		currentProject?.project_code
	);
	const hasEditOperationPermission = usePermission(
		"Edit.Operation",
		currentProject?.project_code
	);

	useEffect(() => {
		if (props.operationRecord) {
			resetForm(props.operationRecord);
			setActiveRowKey(props.operationRecord._id);
			setOperationInfo(props.operationRecord);
			// console.log(props.operationRecord);
		}
	}, [props.operationRecord]);

	const resetForm = (values: any = null, reset: any = true) => {
		// Ensure formRef exists
		if (formRef) {
			setFormReset(true); // `setFormReset` to `true`
			reset && formRef.resetFields(); // If 'reset' is true, reset the
			formRef.setFieldsValue(values ? { ...values } : null); // Set new values or clear fields
			setInitialForm(values); // Update initial form values
		}
	};

	const handleFormCancel = () => {
		props.setOpen(false);
		setCancelPopover(false);
		context?.handleFormUpdating(false, props.activeRowKey);
		if (operationInfo) {
			resetForm(operationInfo);
			setOperationInfo(operationInfo);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any) => {
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("operation").then((cruda: any) => {
			if (activeRowKey) {
				const form = cruda.getFormList("operation");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				cruda
					.updateLookupItem(
						"operation",
						props?.option,
						activeRowKey,
						context?.currentTenantKey,
						values,
						initialForm,
						{},
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, activeRowKey);
						resetForm(values, true);
						context.setCurrentWell(updatedItem);
						// Emitter.emit("forceUpdate", null);

						// console.log(operationInfo);
						const newOperationData =
							// Iterate through the 'operation_data' array to find the operation item with the matching '_id'
							operationInfo?.operation_data?.map((item: any) => {
								// Check if the current item has the same '_id' as the updating item
								if (item._id === updatedItem._id) {
									// If they match, return the updated item to replace the old one
									return updatedItem;
								}
								// If they don't match, return the original item (no change)
								return item;
							});

						const newRecord = {
							...operationInfo,
							operation_data: newOperationData,
						};

						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newOperationData },
						});
						// console.log(newRecord, newOperationData);
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			} else {
				// Add Operation Item
				// console.log("adding data", props);

				const updatedOperationData =
					operationInfo?.operation_data?.filter(
						// Add all items into the `updatedOperationData` array whose `._id` does not match `activeRowKey`
						(item: any) => item._id !== activeRowKey
					) || [];

				cruda
					.addLookupItem(
						"operation",
						props?.option,
						values,
						{
							project_code: context?.currentProject?.project_code,
							well_id: operationInfo?.well_id,
						},
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");

						resetForm(addedItem, true);
						context.setCurrentWell(addedItem);

						setActiveRowKey(
							addedItem?.uuid ||
								addedItem?.group_uuid ||
								addedItem?.tag_uuid ||
								addedItem?._id
						);

						const newRecord = { ...operationInfo };

						newRecord.operation_data = [...updatedOperationData, addedItem];

						console.log("New Record", updatedOperationData);
						props.setOperationRecord((prev: any) => ({
							...prev,
							...addedItem,
							operation_data: newRecord.operation_data,
						}));
						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newRecord.operation_data },
						});
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = (values: any) => {
		// Signals that the form is performing a delete action on the item identified by `activeRowKey` while setting the updating state to `false`
		context?.handleFormUpdating(false, activeRowKey);
		// Console log the deleting operation item
		console.log("Deleting Operation Item: ", activeRowKey);

		// Retrieves a CRUD handler for operations
		getCrudaClass("operation")
			// If the handler is available, pass the `cruda` object into the callback function
			.then((cruda: any) => {
				cruda
					// Calls custom method `deleteLookupItem` with details to delete the specific item
					.deleteLookupItem(
						"operation", // The lookup type (type of data to delete)
						props?.option, // Property inside `props`
						activeRowKey, // UID for the row or record to be deleted
						context?.currentTenantKey, // Check for `currentTenantKey`
						{ project_code: context?.currentProject?.project_code },
						props.user // User performing the deletion
					)
					// If the `Promise` (operation) is successful
					.then(() => {
						// Shows a success alert with a message
						Emitter.emit("alert", {
							type: "success", // Type of alert ('success')
							message: `Operation has been successfully deleted.`, // The message to show in the alert
							description: "", // Additional description (empty in this case)
							top: true, // Position of the alert (top of the screen)
							closeable: false, // Prevents the alert from being closed manually
							timeout: 3000, // Alert will disappear after 3 seconds
						});

						// Closes the modal
						props.setOpen(false);

						// Closes the delete confirmation popover
						setDeletePopover(false);

						// Resets the active row key, removing selection
						setActiveRowKey(null);

						const newOperationData =
							// Checks the `operation_data` array, which may or may not contain the data
							operationInfo?.operation_data
								// `fitler()` creates a new array by filtering out the items that do not match the condition below
								?.filter(
									// Check for any `item._id` that does not match the `activeRowKey` and keep them in the new array
									(item: any) => item._id !== activeRowKey
								);

						// Update the `operationInfo` with the new data after deletion
						const newRecord = {
							...operationInfo,
							operation_data: newOperationData,
						};

						console.log("Updated Operation Data After Deletion:", newRecord);

						// Emit events to update the form and the data in the UI
						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newOperationData },
						});

						// Reset the form since the operation was deleted
						resetForm(null, true);

						// If a callback function `redirectOnDelete` is provided in the props, it will redirect the user
						if (props.redirectOnDelete) {
							props.redirectOnDelete();
						}
					})
					// If the `Promise` fails (an error occurs)
					.catch(() => {
						// Shows an error alert if something goes wrong
						Emitter.emit("alert", {
							type: "error", // The type of the alert, in this case, 'error'
							message: "Changes are not saved. Please try again.", // The message to show in the alert
							description: "", // Additional description (empty in this case)
							top: true, // Position of the alert (top of the screen)
							closeable: false, // Prevents the alert from being closed manually
							timeout: 3000, // Alert will disappear after 3 seconds
						});
					})
					// Finally block (runs regardless of success or failure)
					.finally(() => {
						// Emit a "loading" event to hide the loading indicator (set it to false)
						// Emitter.emit("loading", false);
					});
			});
	};

	return (
		<>
			{/* Well operation configuration - Popup Modal */}
			<Modal
				closable={false}
				title={"Well - operation configuration"}
				open={props.open}
				centered
				destroyOnClose
				footer={() => {
					return (
						<>
							{hasDeleteOperationPermission && (
								// Delete - Popconfirm
								<Popconfirm
									placement="topLeft"
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											<>
												Permanently delete this well operation?
												<br />
												This action cannot be undone.
											</>
										</div>
									}
									open={deletePopover}
									onConfirm={handleFormDelete}
									overlayClassName="popconfirm-danger"
									onCancel={() => {
										setDeletePopover(false);
									}}
									okText="Delete"
									okType="danger"
									icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
									cancelText={<span>Cancel</span>}
									cancelButtonProps={{ ghost: true }}
								>
									{/* Delete Button */}
									<Button
										disabled={!activeRowKey}
										className="ant-btn-secondary"
										style={{ marginRight: "auto" }}
										danger
										onClick={() => {
											setDeletePopover(true);
										}}
									>
										Delete
									</Button>
								</Popconfirm>
							)}
							{/* Cancel Button - Popconfirm */}
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									setCancelPopover(false);
									setTimeout(() => {
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									setCancelPopover(false);
								}}
							>
								{/* Cancel Button */}
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											handleFormCancel();
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>
							{/* Save Button */}
							{hasEditOperationPermission && (
								<FormButtonSave
									activeKey={activeRowKey}
									form={formRef}
									handleFormSave={handleFormSave}
									formReset={formReset}
									setFormReset={setFormReset}
								/>
							)}
						</>
					);
				}}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						tabKey={"operation"}
						formRef={formRef}
						setFormReset={setFormReset}
						dataOptions={props.extraData}
						activeRowKey={activeRowKey}
						activeRecord={props.operationRecord}
						readOnly={!hasEditOperationPermission}
					/>
				</div>
			</Modal>
		</>
	);
}

export default OperationConfigModal;
