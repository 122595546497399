import React, { useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import { Table } from "antd";

function TimesheetManagement(props: any) {
	const columns = [
		{
			title: (
				<>
					<span style={{ paddingRight: "8px" }}>{GetAntIcon("search")}</span>
					Projects
				</>
			),
			dataIndex: "projects", // Determines which field in the data object populates this column
			key: "projects", // Unique indentifier for the column
		},
		{
			title: (
				<>
					<span style={{ paddingRight: "8px" }}>{GetAntIcon("search")}</span>
					Owner/Operator
				</>
			),
			dataIndex: "owner_operator",
			key: "owner_operator",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>No. of members</span>
					<span style={{ display: "flex", gap: "8px" }}>
						<span>{GetAntIcon("search")}</span>
						<span>{GetAntIcon("filter")}</span>
					</span>
				</div>
			),
			dataIndex: "no_of_members",
			key: "no_of_members",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Total logged hours</span>
					<span>{GetAntIcon("search")}</span>
				</div>
			),
			dataIndex: "total_logged_hours",
			key: "total_logged_hours",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Submitted timesheet</span>
					<span>{GetAntIcon("filter")}</span>
				</div>
			),
			dataIndex: "submitted_timesheet",
			key: "submitted_timesheet",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Pending review</span>
					<span>{GetAntIcon("filter")}</span>
				</div>
			),
			dataIndex: "pending_review",
			key: "pending_review",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Approved</span>
					<span>{GetAntIcon("filter")}</span>
				</div>
			),
			dataIndex: "approved",
			key: "approved",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Rejected</span>
					<span>{GetAntIcon("filter")}</span>
				</div>
			),
			dataIndex: "rejected",
			key: "rejected",
		},
	];

	return (
		<>
			<Table
				// dataSource={rolePermissionTableData}
				columns={columns}
				pagination={false}
				bordered
			/>
		</>
	);
}

export default TimesheetManagement;
