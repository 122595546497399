import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../../assets/scss/projects.scss";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";
import { GetAntIcon } from "../../utils/ant_icons";
import { countryList } from "../../utils/countryCodes";
import Emitter from "../../utils/emitter";
import { checkPrivilege } from "../../utils/utils";
import PieChart from "../Charts/PieChart";
import ProjectCards from "./ProjectCards";
import usePermission from "../../hooks/usePermission";

const statusList = ["New", "In progress", "Resolved"];

function KanbanBoard(props: any) {
	const navigate = useNavigate();
	const [detailMode, setDetailMode] = useState<any>(false);
	const [random, setRandom] = useState<any>([]);

	const { handleContextMenu } = useHandleContextMenu();
	const hasPermission = usePermission("Create.Project");

	const filterProjects = () => {
		const { searchValue, selectedFilters } = props;
		const {
			Type,
			"Project Area": ProjectArea,
			"Owner/Operator": Client,
			Region,
			Country,
			"Operating Office": OperatingOffice,
			Tags,
		} = selectedFilters;

		return props.allProjects.filter((project: any) => {
			return (
				(searchValue === "" ||
					project.project_name
						.toLowerCase()
						.includes(searchValue.toLowerCase())) &&
				(Type.length === 0 || Type.includes(project.project_type)) &&
				(ProjectArea.length === 0 || ProjectArea.includes(project.pro)) &&
				(Client.length === 0 || Client.includes(project.client)) &&
				(Region.length === 0 || Region.includes(project.region)) &&
				(Country.length === 0 || Country.includes(project.country)) &&
				(OperatingOffice.length === 0 ||
					OperatingOffice.includes(project.operating_office)) &&
				((Array.isArray(Tags) && Tags.length === 0) ||
					(Array.isArray(Tags) &&
						Tags.some((tag) => project.project_tags.includes(tag))))
			);
		});
	};

	useEffect(() => {
		if (props.allProjects) {
			let randomData: any = [];
			props.allProjects.forEach((element: any, index: any) => {
				randomData[index] = [
					{
						pie: Math.round(Math.random()),
						length: Math.round(Math.random() * 5),
					},
					{
						pie: Math.round(Math.random()),
						length: Math.round(Math.random() * 5),
					},
					{
						pie: Math.round(Math.random()),
						length: Math.round(Math.random() * 5),
					},
					{
						pie: Math.round(Math.random()),
						length: Math.round(Math.random() * 5),
					},
					{
						pie: Math.round(Math.random()),
						length: Math.round(Math.random() * 5),
					},
				];
			});
			setRandom(randomData);
		}
	}, [props.allProjects]);

	const renderProjectStatusColumns = () => {
		return statusList.map((status: any) => {
			let filteredProjects = props.allProjects
				.filter((project: any) => project.status === status)
				.filter((project: any) => {
					const { searchValue, selectedFilters } = props;
					const {
						Type,
						"Project Area": ProjectArea,
						"Owner/Operator": Client,
						Region,
						Country,
						"Operating Office": OperatingOffice,
						Tags,
					} = selectedFilters;

					return (
						(searchValue === "" ||
							project.project_name
								.toLowerCase()
								.includes(searchValue.toLowerCase())) &&
						(Type?.length === 0 || Type?.includes(project.project_type)) &&
						(ProjectArea?.length === 0 || ProjectArea?.includes(project.project_area)) &&
						(Client?.length === 0 || Client?.includes(project.client)) &&
						(Region?.length === 0 || Region?.includes(project.region)) &&
						(Country?.length === 0 || Country?.includes(project.country)) &&
						(OperatingOffice?.length === 0 ||
							OperatingOffice?.includes(project.operating_office)) &&
						((Array.isArray(Tags) && Tags?.length === 0) ||
							(Array.isArray(Tags) &&
								Tags.some((tag) => project?.project_tags?.includes(tag))))
					);
				})
				.sort((a: any, b: any) => {
					if (!props.sort) {
						return a.project_name.localeCompare(b.project_name);
					} else {
						return b.project_name.localeCompare(a.project_name);
					}
				});

			return (
				<div className="project-status-container">
					<div className="project-status-container-header">
						{GetAntIcon("square-check")} {status}
					</div>
					<div className="project-status-container-content">
						{status == "New" && hasPermission && (
							<Button
								className="add-new-project-button"
								type="primary"
								onClick={() => {
									localStorage.setItem("currentProject", "Add new project");
									Emitter.emit("projectName", "Add new project");
									navigate("add-new-project", {
										replace: false,
									});
								}}
								onContextMenu={(e: any) => {
									const location = `${window.location.href}/add-new-project`;
									handleContextMenu(e, { link: location });
								}}
							>
								+ Add new project
							</Button>
						)}
						{filteredProjects.map((project: any) => {
							return (
								<ProjectCards
									project={project}
									sort={props.sort}
									allProjects={props.allProjects}
									activeProjects={props.activeProjects}
									userRole={props.userRole}
									businessUnits={props.businessUnits}
									projectArea={props.projectArea}
									operatingOffices={props.operatingOffices}
									regions={props.regions}
									projectTags={props.projectTags}
									clients={props.clients}
									countries={countryList}
									setActiveProjects={props.setActiveProjects}
								/>
							);
						})}
					</div>
				</div>
			);
		});
	};

	function generateRandomArray(dataIndex: any, index: any, length: number = 5) {
		// const length = Math.floor(Math.random() * 20) + 1; // Random length between 1 and 20
		let data: any = {};
		if (random[dataIndex]) {
			data = random[dataIndex][index];
		}

		let randomArray: any = [];
		if (data?.pie) {
			for (let i = 0; i < length; i++) {
				randomArray.push(Math.floor(Math.random() * 100) + 1); // Random numbers between 1 and 100
			}
		}

		return randomArray;
	}

	const renderDetail = () => {
		const filteredProjects = filterProjects();
		return (
			<div
				className="kanban-detail-grid-container"
				style={{
					display: "grid",
					gridTemplateRows: `0.1fr repeat(${
						props?.allProjects.length || 0
					}, auto)`,
					gridTemplateColumns: `0.1fr repeat(5, 1fr)`,
					width: "100%",
					// rowGap: "49px",
					// columnGap: "12px",
				}}
			>
				<div style={{ width: "260px" }}></div>
				<div className="kanban-detail-header">
					<div>Backlog</div>
				</div>
				<div className="kanban-detail-header">
					<div>To do</div>
				</div>
				<div className="kanban-detail-header">
					<div>In progress</div>
				</div>
				<div className="kanban-detail-header">
					<div>Late</div>
				</div>
				<div className="kanban-detail-header">
					<div>Resolved</div>
				</div>

				{filteredProjects.map((project: any, index: any) => {
					return (
						<>
							{/* project detail */}
							<div
								key={project.project_code}
								className="kanban-detail-grid-body-title"
								style={{
									cursor: "pointer",
									display: "flex",
									width: "260px",
									margin: "10px 0px",
									padding: "35px 30px",
									flexDirection: "column",

									alignItems: "flex-start",
									gap: "10px",
									borderRadius: "5px 0px 0px 5px",
									background: "#003235",
								}}
								onClick={() => {
									let currentProjects = [...props.activeProjects];
									let foundIndex = props.activeProjects.findIndex(
										(element: any) => element === project.project_code
									);

									if (foundIndex < 0) {
										currentProjects.push(project.project_code);
									} else {
										currentProjects.splice(foundIndex, 1);
									}
									props.setActiveProjects(currentProjects);
								}}
							>
								<ProjectCards
									project={project}
									sort={props.sort}
									allProjects={props.allProjects}
									activeProjects={props.activeProjects}
									userRole={props.userRole}
									businessUnits={props.businessUnits}
									operatingOffices={props.operatingOffices}
									regions={props.regions}
									projectTags={props.projectTags}
									clients={props.clients}
									countries={countryList}
									setActiveProjects={props.setActiveProjects}
								/>
							</div>
							<div className="kanban-detail-grid-body-item kanban-detail-pie">
								<div>
									<PieChart data={generateRandomArray(index, 0)} />
								</div>
							</div>
							<div className="kanban-detail-grid-body-item kanban-detail-pie">
								<div>
									<PieChart data={generateRandomArray(index, 1, 2)} />
								</div>
							</div>
							<div className="kanban-detail-grid-body-item kanban-detail-pie">
								<div>
									<PieChart data={generateRandomArray(index, 2)} />
								</div>
							</div>
							<div className="kanban-detail-grid-body-item kanban-detail-pie">
								<div>
									<PieChart data={generateRandomArray(index, 3, 1)} />
								</div>
							</div>
							<div className="kanban-detail-grid-body-item kanban-detail-pie">
								<div>
									<PieChart data={generateRandomArray(index, 4)} />
								</div>
							</div>
						</>
					);
				})}
			</div>
		);
	};

	const renderAddNewProjectModal = () => {};

	return (
		// <>
		// 	{detailMode ? (
		// 		<div className="kanban-detail-container">{renderDetail()}</div>
		// 	) : (
		// 		<div className="kanban-columns-container">
		// 			{renderProjectStatusColumns()}
		// 		</div>
		// 	)}{" "}
		// </>
		<div className="kanban-board-container">
			{detailMode ? (
				<div className="kanban-detail-container">{renderDetail()}</div>
			) : (
				<div className="kanban-columns-container">
					{renderProjectStatusColumns()}
				</div>
			)}{" "}
		</div>
	);
}

export default KanbanBoard;
