/**
 * Terra's Dashboard page with the usage of Maps, showing client list and showing project list
 */

import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Input, Space, Tag, Typography } from "antd";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckboxDropdown from "../components/CheckboxDropdown";
import Map from "../components/Overview/Map";
import useContainerDimensions from "../hooks/useContainerDimensions";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import usePermission from "../hooks/usePermission";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { getCompanyList } from "../services/api-server/company";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { getProjectTagList } from "../services/api-server/project_tags";
import { getProjects } from "../services/api-server/projects";
import { getRegionList } from "../services/api-server/region";
import { GetAntIcon } from "../utils/ant_icons";
import { countryList } from "../utils/countryCodes";
import Emitter from "../utils/emitter";
import { projectTest } from "../utils/test";
import { HomeContext } from "./Home";
import NoAccess from "./NoAccess";

const { Text } = Typography;

const ring_svg = (
	<svg
		width="102"
		height="102"
		viewBox="0 0 102 102"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.51052 44.2593C-2.21794 71.6324 16.9487 96.8461 44.3218 100.575C63.166 103.142 81.8355 94.8032 92.4966 79.0543L86.2902 76.2425"
			stroke="currentColor"
			strokeWidth="1"
			strokeMiterlimit="10"
		/>
		<path
			d="M100.622 57.8642C104.401 30.4988 85.2805 5.25019 57.9113 1.47125C39.0088 -1.13868 20.2694 7.23482 9.60059 23.0536L15.8613 25.8849"
			stroke="currentColor"
			strokeWidth="1"
			strokeMiterlimit="10"
		/>
	</svg>
);

const statusOrder: any = {
	"In progress": 1,
	New: 2,
	Resolved: 3,
};

const Overview = (props: any) => {
	const navigate = useNavigate();
	const context: any = useContext(HomeContext);
	let userRole = props?.params?.userRole;

	// Hooks
	const { handleContextMenu } = useHandleContextMenu();
	const [tableRef, tableDimensions] = useContainerDimensions();
	const hasPermissionViewProjects = usePermission("View.Project");

	const [selectedProject, setSelectedProject] = useState<any>(null);
	const [selectedProjectInfo, setSelectedProjectInfo] = useState<any>(null);
	const [projectFilterKey, setProjectFilterKey] = useState<any>(null);
	const [projectList, setProjectList] = useState<any>(null);
	const [projectStatusCount, setProjectStatusCount] = useState<any>({});
	const [allProjects, setAllProjects] = useState<any>([]);
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<any>("");

	const [businessUnits, setBusinessUnits] = useState<any>([]);
	const [projectTags, setProjectTags] = useState<any>([]);
	// const [operatingOffices, setOperatingOffices] = useState<any>([]);
	const [regions, setRegions] = useState<any>([]);
	const [companies, setCompanies] = useState<any>([]);

	// states for filtering
	const [filteredCompanies, setFilteredCompanies] = useState<any>([]);
	const [selectedFilters, setSelectedFilters] = useState<any>({
		project_type: [],
	});
	const [selectedCompany, setSelectedCompany] = useState<any>(null);

	// Initial load of data
	useEffect(() => {
		if (context?.currentTenantKey) {
			let projects: any = [];
			let companyList: any = [];
			Promise.all([
				getBusinessUnitList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});

						setBusinessUnits(data);
					}
				}),

				getProjectTagList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.color = item.color;
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						setProjectTags(data);
					}
				}),

				getOperatingOfficeList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = `${item.name}, ${item.country}`;
							return item;
						});
						// setOperatingOffices(data);
					}
				}),

				getProjects(
					context?.currentTenantKey,
					context?.currentTenantData?.company_list
				)
					.then((data: any) => {
						if (data) {
							const userEmail = props?.params.user.email.toLowerCase();
							const userRole = context?.userInfo.userRole;
							data
								.filter((item: any) => {
									if (item.is_removed) return false;
									if (
										userRole.includes(process.env.REACT_APP_SUPER_ADMIN_TAG) ||
										userRole.includes("tenant-admin") ||
										userRole.includes("tenant-members")
									)
										return true;
									const isProjectManager =
										userEmail === item?.project_manager?.toLowerCase();
									const hasRoleAssignment = item?.role_assignments?.some(
										(role: any) =>
											role.appointee.some(
												(email: string) => email.toLowerCase() === userEmail
											)
									);
									const isGeneralMember = (item?.general_members || []).some(
										(email: string) => email.toLowerCase() === userEmail
									);
									const result =
										isProjectManager || hasRoleAssignment || isGeneralMember;
									return result;
								})
								.forEach((item: any) => {
									projects.push(item);
								});
						}
						setAllProjects(projects);
					})
					.catch((error: any) => {
						console.log("Fail to get projects:", error);
					}),

				getRegionList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						setRegions(data);
					}
				}),
				getCompanyList(context?.currentTenantKey, [], true).then(
					(_data: any) => {
						if (_data) {
							let data = _data.map((item: any) => {
								item.value = item.uuid;
								item.label = item.name;
								return item;
							});
							companyList = data;
							// setCompanies(data);
						}
					}
				),
			])
				.then(() => {
					let uniqueCompanies: any = [];
					projects.forEach((project: any) => {
						let foundProject = companyList.find((company: any) => {
							return project.client === company.uuid;
						});
						if (foundProject) {
							let isDuplicate = uniqueCompanies.some(
								(obj: any) => obj.uuid === foundProject.uuid
							);
							if (isDuplicate) {
								console.log("The 'id' already exists in the array.");
							} else {
								console.log(
									"The 'id' doesn't exist in the array. Adding the object."
								);
								uniqueCompanies.push(foundProject);
							}
						}
					});
					if (uniqueCompanies.length > 0) {
						setCompanies(uniqueCompanies);
						setFilteredCompanies(uniqueCompanies);
						setSelectedCompany(uniqueCompanies[0]);
					}
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	}, [context.currentTenantKey]);

	// Filter projects when filter changed and count the status based on number of projects
	useEffect(() => {
		Emitter.emit("loading", true);
		const filterProjectsbyClients = (clientName: any) => {
			let projects = allProjects.filter(
				(element: any) =>
					element.client === clientName || clientName === "Elemental"
			);
			let statusCount: any = {
				inprogress: 0,
				new: 0,
				resolved: 0,
			};
			projects
				.sort((a: any, b: any) => statusOrder[a.status] - statusOrder[b.status])
				.map((element: any) => {
					statusCount[element?.status?.replace(" ", "").toLowerCase()]++;
				});

			setProjectList(projects);
			setProjectStatusCount(statusCount);
		};
		setProjectFilterKey(null);
		selectedCompany
			? filterProjectsbyClients(selectedCompany.uuid)
			: setProjectStatusCount({
					inprogress: 0,
					new: 0,
					resolved: 0,
			  });
		Emitter.emit("loading", false);
	}, [selectedCompany, allProjects, filteredCompanies]);

	// Filter projects based on the selected project status
	useEffect(() => {
		let projects: any = [];
		let statusCount: any = {
			inprogress: 0,
			new: 0,
			resolved: 0,
		};
		if (projectFilterKey) {
			projects = allProjects.filter(
				(element: any) =>
					(element.client === selectedCompany?.uuid ||
						selectedCompany?.uuid === "Elemental") &&
					element.status === projectFilterKey
			);
		} else {
			projects = allProjects.filter(
				(element: any) =>
					element.client === selectedCompany?.uuid ||
					selectedCompany?.uuid === "Elemental"
			);
		}

		projects
			.sort((a: any, b: any) => statusOrder[a.status] - statusOrder[b.status])
			.map((element: any) => {
				statusCount[element?.status?.replace(" ", "").toLowerCase()]++;
			});

		setProjectList(projects);
	}, [projectFilterKey, selectedCompany, allProjects]);

	// Set the projects based on the search client and types of projects
	useEffect(() => {
		let _filteredCompanies = companies || [];
		if (searchValue !== "") {
			_filteredCompanies = _filteredCompanies.filter((company: any) => {
				return company.name.toLowerCase().includes(searchValue.toLowerCase());
			});
		}
		if (
			Object.values(selectedFilters).some((values: any) => values.length > 0)
		) {
			Object.entries(selectedFilters).forEach(([key, values]: any) => {
				if (values.length === 0) return true;
				if (key === "project_type") {
					_filteredCompanies = _filteredCompanies.filter((company: any) => {
						const companyProjects = allProjects.filter(
							(project: any) => project.client === company.uuid
						);
						return companyProjects.some((project: any) => {
							return values.includes(project[key]);
						});
					});
				}
			});
		}
		setFilteredCompanies(_filteredCompanies);
		if (_filteredCompanies.length > 0) {
			setSelectedCompany(_filteredCompanies[0]);
			const filteredProjects = allProjects.filter((project: any) => {
				console.log(projectFilterKey);
				return project.client === _filteredCompanies[0].uuid;
			});
			setProjectList(filteredProjects);
		} else {
			setProjectList([]);
			setSelectedCompany(null);
		}
	}, [selectedFilters, searchValue]);

	// Use to expand and collapse the client list
	const handleExpandCollapse = () => {
		setCollapsed(!collapsed);
	};

	// Use to set the content of the project information for extended view
	const setProjectContent = (currentProject: any, infoKey: any) => {
		let content = "-";
		if (currentProject[infoKey]) {
			if (infoKey === "region") {
				let found = regions.find((element: any) => {
					return element.uuid === currentProject[infoKey];
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "country") {
				let found = countryList.find((element: any) => {
					return currentProject.country === element["alpha-3"];
				});
				if (found) {
					content = found.name;
				} else {
					content = "Aberdeen";
				}
			} else if (infoKey === "client") {
				let found = companies.find((element: any) => {
					return element.uuid === currentProject[infoKey];
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "business_unit") {
				let found = businessUnits.find((element: any) => {
					return element.uuid == currentProject.business_unit;
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "project_tags") {
				let tags = currentProject.project_tags.map((uuid: any) => {
					let found = projectTags.find((element: any) => {
						return element.uuid == uuid;
					});
					return found;
				});

				if (tags.length === 0) {
					return <span>-</span>;
				}

				return tags.map((tag: any) => {
					if (tag) {
						return (
							<Tag className="project-tag" color={tag.color} key={tag.name}>
								{tag.name}
							</Tag>
						);
					}
				});
			} else {
				content = currentProject[infoKey];
			}
		}
		return content;
	};

	// Set the project filtering when status is selected
	const handleProjectFiltering = (key: any) => {
		if (projectFilterKey === key) {
			setProjectFilterKey(null);
		} else {
			setProjectFilterKey(key);
		}
	};

	const selectProject = (projectKey: any, project: any) => {
		if (selectedProject != projectKey) {
			setSelectedProject(projectKey);
			setSelectedProjectInfo(project);
			const selectedElement = document.getElementById(`project-${projectKey}`);

			setTimeout(() => {
				if (selectedElement) {
					selectedElement.scrollIntoView({ behavior: "smooth" });
				}
			}, 1);
		} else {
			setSelectedProject(null);
			setSelectedProjectInfo(null);
		}
	};

	// Show the list of clients and the number of projects for each client
	const listClients = () => {
		const projectCount = (clientName: any) => {
			let count = allProjects.filter(
				(element: any) =>
					element.client === clientName || clientName === "Elemental"
			).length;

			return `${count} project${count > 1 ? "s" : ""}`;
		};

		if (filteredCompanies.length === 0) {
			return (
				<Button
					type="link"
					className={"client-list-button"}
					shape="circle"
					icon={ring_svg}
				>
					<Space
						direction="vertical"
						size={2}
						style={{ position: "absolute", fontSize: "13px" }}
						styles={{ item: { width: "83px", whiteSpace: "initial" } }}
					>
						<span className="client-list-project-name client-list-project-name-no-data">
							No data found
						</span>
					</Space>
				</Button>
			);
		}

		return (
			<>
				{filteredCompanies?.map((element: any) => {
					return (
						<Button
							key={element.uuid}
							type="link"
							className={
								selectedCompany.uuid === element.uuid
									? "client-list-button client-list-button-selected"
									: "client-list-button"
							}
							shape="circle"
							icon={ring_svg}
							onClick={() => {
								// selectClient(element.uuid);
								// setSelectedClient(element.uuid);
								setSelectedCompany(element);
								const filteredProjects = allProjects.filter((project: any) => {
									return project.client === element.uuid;
								});
								setProjectList(filteredProjects);
							}}
						>
							<Space
								direction="vertical"
								size={2}
								style={{ position: "absolute", fontSize: "13px" }}
								styles={{ item: { width: "83px", whiteSpace: "initial" } }}
							>
								{element.icon ? (
									element.icon
								) : (
									<span className="client-list-project-name">
										{element.name}
									</span>
								)}
								<span
									className={
										selectedCompany.uuid === element.uuid
											? "client-list-project-counter-selected"
											: "client-list-project-counter"
									}
								>
									{projectCount(element.uuid)}
								</span>
							</Space>
						</Button>
					);
				})}
			</>
		);
	};

	// Show the project list based on the selected client
	const listProjects = () => {
		const renderStatus = (projectStatus: String) => {
			switch (projectStatus) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>In Progress</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved</span>
						</div>
					);
			}
		};

		// Show detailed view of the project card
		const renderDetail = (project: any) => {
			const isplaceholder = projectTest.find((element: any) => {
				return element.project_name === project.project_name;
			});
			const showDetailInfo = () => {
				const infoKeyList = [
					"project_code",
					"client",
					"region",
					"country",
					"business_unit",
					// "project_manager",
					// "regional_manager",
					// "rig_type",
					// "spud_date",
					// "planned_well_duration",
					// "water_depth",
				];
				return (
					<>
						{infoKeyList.map((infoKey: any) => {
							return (
								<Space direction="vertical" size={0}>
									<span
										style={{
											opacity: 0.45,
											fontSize: "12px",
										}}
									>
										{infoKey
											.split("_")
											.join(" ")
											.replace(/^./, infoKey[0].toUpperCase()) +
											(infoKey.includes("depth") ? " (meters)" : "")}
									</span>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											fontSize: "14px",
											minWidth: "100%",
											width: "0",
										}}
									>
										<Text
											title=""
											style={
												infoKey == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
											ellipsis={{
												// onEllipsis: () => {},
												tooltip: (
													<span>{setProjectContent(project, infoKey)}</span>
												),
											}}
										>
											<span
												className="project-content"
												style={
													infoKey == "country"
														? {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
														  }
														: {}
												}
											>
												{setProjectContent(project, infoKey)}
											</span>
										</Text>
									</div>
									{/* <span>{setProjectContent(project, infoKey)}</span> */}
								</Space>
							);
						})}
					</>
				);
			};
			const showDetailInfoProjectTags = () => {
				return (
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "8px",
							// color: "rgba(255,255,255,0.45)",
							fontSize: "12px",
							minWidth: "100%",
							width: "0",
						}}
					>
						<span>{GetAntIcon("tags")}</span>
						<span
							style={{
								display: "flex",
								flexWrap: "wrap",
								rowGap: "10px",
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}}
						>
							{setProjectContent(project, "project_tags")}
						</span>
					</div>
				);
			};

			return (
				<div className="project-detailed-container">
					<Space
						direction="vertical"
						style={{
							width: "100%",
						}}
						size={15}
					>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span
								className="project-title"
								style={{
									// color: "rgba(255,255,255,0.45)",
									opacity: 0.45,
									fontSize: "14px",
									lineHeight: "25px",
								}}
							>
								Project title
							</span>
							<div style={{ fontSize: "20px", lineHeight: "25px" }}>
								<span
									className={"project-title-clickable"}
									style={{
										whiteSpace: "normal",
										display: "inline",
										overflowWrap: "break-word",
									}}
									onClick={() => {
										Emitter.emit("projectName", project.project_name);
										localStorage.setItem(
											"currentProject",
											project.project_code
										);
										navigate(`/projects/${project.project_code}`, {
											replace: false,
											state: { ...project },
										});
									}}
									onContextMenu={(event: any) => {
										const location = `${window.location.origin}/projects/${project.project_code}`;
										handleContextMenu(event, { link: location });
									}}
								>
									{project.project_name}
								</span>
							</div>
						</Space>
						<div className="project-status">{renderStatus(project.status)}</div>
						<div className="project-grid-container">{showDetailInfo()}</div>
						<div>
							{project?.project_tags?.length > 0 && showDetailInfoProjectTags()}
						</div>
					</Space>
				</div>
			);
		};

		// Show compact view of the project card
		const renderCompact = (project: any) => {
			return (
				<div className="project-mini-container">
					<Space direction="vertical" size={9}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span
								style={{
									//  color: "rgba(255,255,255,0.45)",
									opacity: 0.45,
									fontSize: "12px",
								}}
							>
								Project title
							</span>
							<span
								className={"project-title-clickable"}
								style={{
									whiteSpace: "normal",
									display: "inline",
									overflowWrap: "break-word",
								}}
								onClick={() => {
									Emitter.emit("projectName", project.project_name);
									localStorage.setItem("currentProject", project.project_code);
									navigate(`/projects/${project.project_code}`, {
										replace: false,
										state: { ...project },
									});
								}}
								onContextMenu={(event: any) => {
									const location = `${window.location.origin}/projects/${project.project_code}`;
									handleContextMenu(event, { link: location });
								}}
							>
								{project.project_name}
							</span>
						</Space>
						<div className="project-status">{renderStatus(project.status)}</div>
					</Space>
				</div>
			);
		};

		if (projectList && projectList.length > 0) {
			return projectList.map((element: any) => {
				return (
					<div
						key={`project-${element.project_code}`}
						id={`project-${element.project_code}`}
						className={`overview-card-list-container`}
						onClick={() => selectProject(element.project_code, element)}
					>
						{element.project_code === selectedProject ? (
							<>{renderDetail(element)}</>
						) : (
							<>{renderCompact(element)}</>
						)}
					</div>
				);
			});
		} else {
			return null;
		}
	};

	// Project counter for each status
	const listProjectCounter = () => {
		return (
			<Space
				key="project-list-counter"
				className="project-list-status-counter-container"
				align="center"
				direction="horizontal"
				styles={{ item: { cursor: "pointer" } }}
			>
				<div
					id="inprogress"
					style={{ display: "flex", alignItems: "center" }}
					onClick={(ev: any) => {
						handleProjectFiltering("In progress");
					}}
				>
					{" "}
					<Avatar
						className="status-counter status-counter-in-progress"
						shape="square"
					>
						{projectStatusCount.inprogress}
					</Avatar>
					<span
						className={`${
							projectFilterKey == "In progress" && "status-counter-active"
						}`}
					>
						{" "}
						In Progress
					</span>
				</div>
				<div
					id="new"
					style={{ display: "flex", alignItems: "center" }}
					onClick={(ev: any) => {
						handleProjectFiltering("New");
					}}
				>
					{" "}
					<Avatar className="status-counter status-counter-new" shape="square">
						{projectStatusCount.new}
					</Avatar>
					<span
						className={`${
							projectFilterKey == "New" && "status-counter-active"
						}`}
					>
						New
					</span>
				</div>
				<div
					id="completed"
					style={{ display: "flex", alignItems: "center" }}
					onClick={(ev: any) => {
						handleProjectFiltering("Resolved");
					}}
				>
					{" "}
					<Avatar
						className="status-counter status-counter-completed"
						shape="square"
					>
						{projectStatusCount.resolved}
					</Avatar>
					<span
						className={`${
							projectFilterKey == "Resolved" && "status-counter-active"
						}`}
					>
						Resolved
					</span>
				</div>
			</Space>
		);
	};

	const handleMenuClick = (key: any, selectedValues: any) => {
		setSelectedFilters({ ...selectedFilters, [key]: selectedValues });
	};

	// handle search client
	const handleSearch = (value: any) => {
		setSearchValue(value);
	};

	if (
		!hasPermissionViewProjects &&
		typeof hasPermissionViewProjects === "boolean"
	) {
		return (
			<NoAccess text="You currently do not have access. Please contact the administrator for assistance." />
		);
	}

	if (typeof hasPermissionViewProjects !== "boolean") {
		return <></>;
	}

	return (
		<div
			style={{
				display: "flex",
				height: "100%",
				flexDirection: "column",
			}}
		>
			{!collapsed && (
				<div className={`client-list ${collapsed ? "collapsed" : "expanded"}`}>
					<Space
						direction={collapsed ? "horizontal" : "vertical"}
						style={{
							width: "100%",
							height: "100%",
							padding: collapsed ? "10px 30px 8px 30px" : "2px 30px 8px 30px",
						}}
						styles={{
							item: {
								width: "100%",
								height: "100%",
								position: "relative",
							},
						}}
						size={0}
					>
						{!collapsed && (
							<Space
								direction="horizontal"
								style={{ width: "100%" }}
								styles={{ item: { padding: "10px" } }}
								size={10}
							>
								{listClients()}
							</Space>
						)}
					</Space>
				</div>
			)}
			<div
				style={{
					position: "absolute",
					top: collapsed ? "10px" : "150px",
					zIndex: 500,
					display: "flex",
					width: "100%",
					justifyContent: "center",
				}}
			>
				<div
					className={`current-viewed-company ${
						collapsed ? "collapsed" : "expanded"
					}`}
				>
					<span style={{ marginRight: "20px", alignItems: "center" }}>
						Currently viewing:
					</span>
					<Input
						className="search-input"
						value={selectedCompany?.name}
						style={{ width: "320px", margin: "auto" }}
						disabled
					/>
				</div>
			</div>

			<div
				className={`filter-container ${collapsed ? "collapsed" : "expanded"}`}
			>
				{!collapsed && (
					<>
						<Input
							placeholder="Search client"
							className="input-clearable search-input"
							onChange={(e: any) => handleSearch(e.target.value)}
							onBlur={(e: any) => handleSearch(e.target.value)}
							onPressEnter={(e: any) => handleSearch(e.target.value)}
							value={searchValue}
							allowClear
							prefix={<SearchOutlined />}
						/>

						<CheckboxDropdown
							icon={GetAntIcon("appstore")}
							options={[
								{
									label: "Consulting/Study",
									value: "Consulting/Study",
								},
								{
									label: "Project Management",
									value: "Project Management",
								},
							]}
							onChange={(selectedValues) => {
								handleMenuClick("project_type", selectedValues);
							}}
							onClear={""}
							defaultValue={selectedFilters.project_type}
							Key="project_type"
							label="Type"
						/>
						<Divider
							type="vertical"
							style={{ height: "18px", margin: "0 10px" }}
						/>
					</>
				)}
				<Button
					icon={
						collapsed
							? GetAntIcon("double-arrow-down")
							: GetAntIcon("double-arrow-up")
					}
					style={{
						// color: themeContext.themeVariables["ribbon-text-color"],
						border: "0px",
					}}
					onClick={handleExpandCollapse}
					ghost
				/>
			</div>

			<div
				className="map-container"
				style={{ flex: "1", position: "relative" }}
				ref={tableRef}
			>
				<Map
					projectList={projectList}
					selectProject={selectProject}
					selectedProject={selectedProject}
					selectedProjectInfo={selectedProjectInfo}
					keyType={"project_code"}
				/>
				<div
					className="sub-container"
					style={{
						position: "absolute",
						zIndex: 401,
						width: "470px",
						padding: "60px 30px 10px 0px",
						right: 0,
						top: 0,
						display: "flex",
						maxHeight: "100%",
					}}
				>
					<div className="project-main-container">
						<div
							style={{
								backgroundColor: "rgba(255,255,255, 0.45)",
								width: "100%",
								height: "auto",
								maxHeight: "100%",
								padding: "10px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{listProjectCounter()}
							<Space
								key="project-list"
								className="project-list"
								direction="vertical"
							>
								{listProjects()}
							</Space>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Overview.propTypes = {
	params: PropTypes.any,
};

export default Overview;
