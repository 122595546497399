import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "../assets/scss/projects.scss";
import CheckboxDropdown from "../components/CheckboxDropdown";
import Gallery from "../components/Projects/Gallery";
import KanbanBoard from "../components/Projects/KanbanBoard";
import usePermission from "../hooks/usePermission";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { getCompanyList } from "../services/api-server/company";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { getProjectTagList } from "../services/api-server/project_tags";
import { getProjects } from "../services/api-server/projects";
import { getRegionList } from "../services/api-server/region";
import { GetAntIcon } from "../utils/ant_icons";
import { countryList } from "../utils/countryCodes";
import Emitter from "../utils/emitter";
import { projectArea, projectType } from "../utils/test";
import { HomeContext } from "./Home";
import NoAccess from "./NoAccess";

const { Search } = Input;

function Projects(props: any) {
	const location = useLocation();
	const navigate = useNavigate();
	const context: any = useContext(HomeContext);
	const [allProjects, setAllProjects] = useState<any>([]);
	const [activeProjects, setActiveProjects] = useState<any>([]);
	const [businessUnitList, setBusinessUnitList] = useState<any>([]);
	const [projectTags, setProjectTags] = useState<any>([]);
	const [operatingOffices, setOperatingOffices] = useState<any>([]);
	const [regions, setRegions] = useState<any>([]);
	const [countries, setCountries] = useState<any>([]);
	const [companies, setCompanies] = useState<any>([]);
	const [activePage, setActivePage] = useState<any>("kanban");
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [descMode, setDescMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const filtersFromState = (location.state as any)?.selectedFilters;

	const hasPermissionViewProjects = usePermission("View.Project");

	const handleMenuClick = (field: any, selectedValues: any[]) => {
		// Update the selected filters state
		setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

		localStorage.setItem(
			"selectedFilters",
			JSON.stringify({ ...selectedFilters, [field]: selectedValues })
		);
	};

	const [selectedFilters, setSelectedFilters] = useState({
		Type: [],
		"Project Area": [],
		"Owner/Operator": [],
		Region: [],
		Country: [],
		"Operating Office": [],
		Tags: [],
	});

	// this dynamically updates the filter dropdown label with number of filters
	const generateDefaultValue = (filterName: any, selectedValues: any) => {
		const count = selectedValues ? selectedValues.length : 0;
		return count > 0 ? `${filterName} (${count})` : filterName;
	};

	// // this gets the key for each filter dropdown
	// const generateStorageKey = (field: any) => {
	// 	return `${field}Dropdown`;
	// };

	// useEffect(() => {
	// 	// Default remove all filters when component mounts
	// 	if (localStorage.getItem("selectedFilters")) {
	// 		localStorage.removeItem("selectedFilters");
	// 	}
	// }, []);

	// ?
	useEffect(() => {
		if (filtersFromState) {
			// Update the selected filters state
			setSelectedFilters(filtersFromState);

			// Update URL with filter parameters
			const searchParams = new URLSearchParams(location.search);
			Object.entries(filtersFromState).forEach(([key, value]) => {
				if (Array.isArray(value)) {
					searchParams.set(key, value.join(",")); // Convert array to comma-separated string
				}
			});
			navigate(`${location.pathname}?${searchParams.toString()}`, {
				replace: true,
			});
		}
	}, [filtersFromState, location.search, navigate]);

	const handleClearAllFilters = () => {
		// Reset all filters to empty arrays
		setSelectedFilters({
			Type: [],
			"Project Area": [],
			"Owner/Operator": [],
			Region: [],
			Country: [],
			"Operating Office": [],
			Tags: [],
		});
		setFilterMode(false);

		// Clear session storage for filter values and checkboxes
		localStorage.removeItem("selectedFilters");

		// Object.keys(selectedFilters).forEach((field) => {
		// 	const storageKey = generateStorageKey(field);
		// 	localStorage.removeItem(storageKey);
		// });

		setTimeout(() => {
			setFilterMode(true);
		}, 5);
	};

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some((values) => values.length > 0);
	};

	// Function to sort array of objects alphabetically based on property's name
	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	useEffect(() => {
		if (context.currentTenantKey) {
			Emitter.emit("loading", true);
			let projects: any = [];

			if (localStorage.getItem("selectedFilters")) {
				let selectedFiltersStore: any = localStorage.getItem("selectedFilters");
				let selectedFiltersStoreJson = JSON.parse(selectedFiltersStore);
				setSelectedFilters(selectedFiltersStoreJson);
				setFilterMode(true);
			}

			Promise.all([
				getProjects(
					context.currentTenantKey,
					context.currentTenantData.company_list
				)
					.then((data: any) => {
						if (data) {
							data
								.filter((item: any) => !item.is_removed)
								.forEach((item: any) => {
									projects.push(item);
								});
						}
					})
					.catch((error: any) => {})
					.finally(() => {
						setAllProjects(projects);
						let countries = projects.map((project: any) => project.country);
						let filteredCountries = countryList.filter((country: any) =>
							countries.includes(country["alpha-3"])
						);
						setCountries(filteredCountries);
						Emitter.emit("loading", false);
					}),
				getBusinessUnitList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						setBusinessUnitList(data);
					}
				}),

				getProjectTagList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.color = item.color;
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						setProjectTags(data);
					}
				}),

				getOperatingOfficeList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = `${item.name}, ${item.country}`;
							return item;
						});
						data = sortData(data);
						setOperatingOffices(data);
					}
				}),

				getRegionList(context.currentTenantKey).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						setRegions(data);
					}
				}),
				// getCompanyList(context?.currentTenantData?.company_list).then(
				getCompanyList(context?.currentTenantKey, [], true).then(
					(_data: any) => {
						if (_data) {
							let data = _data.map((item: any) => {
								item.value = item.uuid;
								item.label = item.name;
								return item;
							});
							data = sortData(data);
							setCompanies(data);
						}
					}
				),
			])
				.then(() => {})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	}, [context.currentTenantKey]);

	return (
		<>
			{hasPermissionViewProjects ? (
				<div className="generic-container" style={{ gap: "20px" }}>
					<div
						className="main-container"
						style={{
							flex: 1,
							width: "100%",
							maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
							borderRadius: "5px",
						}}
					>
						<div className="project-header">
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<div
									className={
										activePage === "kanban" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("kanban");
									}}
								>
									{GetAntIcon("project")}
									<span>Kanban</span>
								</div>{" "}
								<div
									className={
										activePage === "gallery" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("gallery");
									}}
								>
									{GetAntIcon("app")}
									<span>Gallery</span>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search by project name"
									className="input-clearable-panel"
									style={{ maxWidth: "265px" }}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
									<div
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setDescMode(!descMode);
										}}
									>
										{descMode
											? GetAntIcon("descending")
											: GetAntIcon("ascending")}
									</div>
									{/* <div style={{ padding: "4px 10px", cursor: "pointer" }}>
								{GetAntIcon("setting")}
							</div> */}
								</div>
							</div>
						</div>
						<div
							className="generic-content project-overview-container"
							style={{
								flex: 1,
								display: "flex",
								flexWrap: "inherit",
								flexDirection: "column",
								alignItems: "flex-start",
								padding: 0,
								gap: 0,
								overflowX: "auto",
								maxHeight: "100%",
							}}
						>
							<div
								className={`filter-area ${
									filterMode ? "show-filter" : "hide-filter"
								}`}
							>
								{filterMode && (
									<>
										{" "}
										<CheckboxDropdown
											options={projectType}
											onChange={(selectedValues) =>
												handleMenuClick("Type", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.Type}
											Key="Type"
											label="Type"
										/>
										<CheckboxDropdown
											options={projectArea}
											onChange={(selectedValues) =>
												handleMenuClick("Project Area", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters["Project Area"]}
											Key="Project Area"
											label="Project Area"
										/>
										<CheckboxDropdown
											options={companies}
											onChange={(selectedValues) =>
												handleMenuClick("Owner/Operator", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters["Owner/Operator"]}
											Key="Owner/Operator"
											label="Owner/Operator"
										/>
										<CheckboxDropdown
											options={regions}
											onChange={(selectedValues) =>
												handleMenuClick("Region", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.Region}
											Key="Region"
											label="Region"
										/>
										<CheckboxDropdown
											options={(countries as any[]).map((country) => ({
												label: country.name,
												value: country["alpha-3"],
											}))}
											onChange={(selectedValues) =>
												handleMenuClick("Country", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.Country}
											Key="Country"
											label="Country"
										/>
										<CheckboxDropdown
											options={operatingOffices}
											onChange={(selectedValues) =>
												handleMenuClick("Operating Office", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters["Operating Office"]}
											Key="Operating Office"
											label="Operating Office"
										/>
										<CheckboxDropdown
											options={(projectTags as any[]).map((tag) => ({
												label: tag.label,
												value: tag.uuid,
											}))}
											onChange={(selectedValues) =>
												handleMenuClick("Tags", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.Tags}
											Key="Tags"
											label="Tags"
										/>
										<div className="test">
											<Button
												type="link"
												// style={{ color: "rgba(255, 255, 255, 0.5)" }}
												onClick={handleClearAllFilters}
											>
												Reset All
											</Button>
										</div>
									</>
								)}
							</div>
							{activePage == "kanban" && (
								<KanbanBoard
									sort={descMode}
									allProjects={allProjects}
									activeProjects={activeProjects}
									userRole={props?.params.userRole}
									// businessUnits={businessUnitList}
									operatingOffices={operatingOffices}
									projectArea={projectArea}
									regions={regions}
									projectTags={projectTags}
									clients={companies}
									country={countries}
									setActiveProjects={setActiveProjects}
									selectedFilters={selectedFilters}
									searchValue={searchValue}
								/>
							)}
							{activePage == "gallery" && (
								<Gallery
									sort={descMode}
									allProjects={allProjects}
									activeProjects={activeProjects}
									userRole={props?.params.userRole}
									// businessUnits={businessUnitList}
									operatingOffices={operatingOffices}
									regions={regions}
									projectTags={projectTags}
									country={countries}
									clients={companies}
									setActiveProjects={setActiveProjects}
									selectedFilters={selectedFilters}
									searchValue={searchValue}
								/>
							)}
						</div>
					</div>
				</div>
			) : (
				<NoAccess
					text={
						"Oops, looks like you don't have the authorisation to view Projects."
					}
				/>
			)}
		</>
	);
}

Projects.propTypes = {
	params: PropTypes.any,
	component: PropTypes.string,
};

export default Projects;
