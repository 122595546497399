import axios, { AxiosProgressEvent } from "axios";
import { url, AuthHeader } from "../../utils/_exports";
import Emitter from "../../utils/emitter";
const apiURL = "sharepoint";

const getRootFolderId = (tenantKey: any, well_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${url}/${tenantKey}/${apiURL}/root-folder-id?well_id=${well_id}`,
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data?.folder_id) {
					resolve(_data.data.folder_id);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const getFileMetadata = (tenantKey: any, well_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${url}/${tenantKey}/${apiURL}/get-metadata?well_id=${well_id}`,
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const listFolderItems = (
	tenantKey: any,
	well_id: any,
	folder_id: any,
	container_id: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/list-items`,
				{
					tenant_key: tenantKey,
					well_id,
					folder_id,
					container_id,
				},
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const createNewFolder = (
	foldername: any,
	tenantKey: any,
	well_id: any,
	folder_id: any,
	container_id: any,
	folder_hierarchy: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/create-folder`,
				{
					foldername,
					tenant_key: tenantKey,
					well_id,
					folder_id,
					container_id,
					folder_hierarchy,
					user,
				},
				AuthHeader
			)
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((error: any) => {
				reject(error?.response?.data || error?.response || error);
			});
	});
};

const uploadFiles = (
	files: any,
	tenantKey: any,
	well_id: any,
	folder_id: any,
	container_id: any,
	folder_hierarchy: any,
	user: any,
	socket_id: any
) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();

		// Append each file to the formData object
		files.forEach((file: any, index: any) => {
			formData.append(`files`, file);
		});

		// Append other parameters
		formData.append("tenant_key", tenantKey);
		formData.append("well_id", well_id);
		formData.append("folder_id", folder_id);
		formData.append("container_id", container_id);
		formData.append("folder_hierarchy", folder_hierarchy);
		formData.append("email", user.email);
		formData.append("id", user.id);
		formData.append("socket_id", socket_id);

		axios
			.post(`${url}/${tenantKey}/${apiURL}/upload`, formData, {
				...AuthHeader,
				onUploadProgress: (progressEvent: AxiosProgressEvent) => {
					if (progressEvent.lengthComputable) {
						Emitter.emit(
							"ACTION-PROGRESS",
							(((progressEvent?.progress || 0) / 2) * 100).toFixed(0)
						);
					}
				},
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

const getFilePreview = (tenantKey: any, file_id: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/preview`,
				{
					file_id,
					container_id
				},
				AuthHeader
			)
			.then((response: any) => {
				resolve(response.data);
			});
	});
};

const downloadFile = (fileid: any) => { };

const getFileThumbnail = (tenantKey: any, file_id: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${url}/${tenantKey}/${apiURL}/thumbnail?file_id=${file_id}&container_id=${container_id}`,
				AuthHeader
			)
			.then((response: any) => {
				resolve(response.data);
			});
	});
};

const getFile = (tenantKey: any, file_id: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${url}/${tenantKey}/${apiURL}/get-file?file_id=${file_id}&container_id=${container_id}`,
				AuthHeader
			)
			.then((response: any) => {
				resolve(response.data);
			});
	});
};

const collabo = (tenantKey: any, file_id: any, container_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/collaboration`,
				{
					file_id,
					container_id,
				},
				AuthHeader
			)
			.then((response: any) => {
				resolve(response.data);
			});
	});
};

const deleteFiles = (
	tenantKey: any,
	item_ids: any,
	container_id: any,
	socket_id: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/remove`,
				{
					item_ids,
					container_id,
					socket_id,
				},
				AuthHeader
			)
			.then((response: any) => {
				resolve(response.data);
			});
	});
};

const renameFile = (
	tenantKey: any,
	item_id: any,
	container_id: any,
	new_name: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.patch(
				`${url}/${tenantKey}/${apiURL}/rename`,
				{
					item_id,
					container_id,
					new_name,
					user,
				},
				AuthHeader
			)
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((error: any) => {
				reject(error?.response?.data || error?.response || error);
			});
	});
};

export {
	getFileMetadata,
	getRootFolderId,
	listFolderItems,
	createNewFolder,
	uploadFiles,
	renameFile,
	deleteFiles,
	getFilePreview,
	downloadFile,
	getFileThumbnail,
	getFile,
	collabo,
};
