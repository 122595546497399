import {
	Button,
	Divider,
	Form,
	Image,
	Input,
	Modal,
	Radio,
	Select,
	Space,
	Tabs,
} from "antd";
import { useForm } from "antd/es/form/Form";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";

import darkModePreview from "../assets/image/Darkmode_preview.png";
import lightModePreview from "../assets/image/Lightmode_preview.png";
import FormButtonCancel from "../components/FormButtonCancel";
import FormButtonSave from "../components/FormButtonSave";
import UploadComponent from "../components/UploadComponent";
import { updateUser } from "../services/api-server/user";
import { uniqueCountryCodes } from "../utils/countryCodes";
import Emitter from "../utils/emitter";
import { getInitials } from "../utils/utils";

const { Option } = Select;

function Profile(props: any) {
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [initials, setInitials] = useState<string>("");

	const [linkedInModalOpen, setLinkedInModalOpen] = useState<boolean>(false);
	const [linkedChange, setLinkedChange] = useState<boolean>(false);
	const [linkedinUrl, setLinkedInUrl] = useState<any>(null);

	const [pp, setpp] = useState<any>(null);

	const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);

	const resetForm = (values: any = null) => {
		if (formRef) {
			setFormReset(true);
			formRef.resetFields();
			formRef.setFieldsValue(values ? values : props.params.user);
		}
	};

	useEffect(() => {
		resetForm();
		setInitials(getInitials(props.params.user?.name, 2));
		setLinkedInUrl(props.params.user?.linkedin_url);
	}, [props.params.user]);

	const handleFormSave = (values: any) => {
		Emitter.emit("loading", true);
		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});
		updateUser(values)
			.then((data: any) => {
				Emitter.emit("userSaved", data);

				Emitter.emit("alert", {
					type: "success",
					message: "Your user profile has been successfully saved.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch((error: any) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to update. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const handleLinkedInSave = () => {
		Emitter.emit("loading", true);
		updateUser({ ...props.params.user, linkedin_url: linkedinUrl })
			.then((data: any) => {
				Emitter.emit("userSaved", data);
				Emitter.emit("alert", {
					type: "success",
					message: "Your LinkedIn profile has been added.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch((error: any) => {
				const alertType = "error";
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to update. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const handleProfilePhotoSave = (image: any) => {
		Emitter.emit("loading", true);
		let pp = image;
		updateUser({
			...props.params.user,
			profile_picture: pp,
		})
			.then((data: any) => {
				setpp(pp);
				Emitter.emit("userSaved", data);
				Emitter.emit("alert", {
					type: "success",
					message: "Your profile photo has been successfully updated.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch((error: any) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to update. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const handleProfilePhotoRemove = () => {
		Emitter.emit("loading", true);
		updateUser({
			...props.params.user,
			profile_picture: null,
		})
			.then((data: any) => {
				Emitter.emit("userSaved", data);
				Emitter.emit("alert", {
					type: "success",
					message: "Your profile photo has been successfully removed.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch((error: any) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to update. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	return (
		<div className="generic-container" style={{ overflow: "auto" }}>
			<Space direction="vertical">
				{/* Profile Tab Buttons */}
				<Tabs
					defaultValue={"overview"}
					items={[{ label: "Overview", key: "overview" }]}
				></Tabs>
			</Space>
			<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
				{/* My Profile Container */}
				<div
					className="main-container"
					style={{
						width: "70%",
						display: "flex",
						flexDirection: "column",
						borderRadius: "3px",
					}}
				>
					{/* My Profile Header */}
					<div className="generic-header" style={{ height: "50px" }}>
						My Profile
					</div>
					{/* My Profile Body */}
					<div className="generic-content" style={{ flex: "1", gap: 0 }}>
						<div
							style={{
								width: "210px",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "10px",
								padding: "40px 0px",
								flexGrow: 1,
							}}
						>
							<UploadComponent
								label={"photo"}
								prefix={"profile"}
								initials={initials}
								setEmptyModalOpen={setEmptyModalOpen}
								image={pp || props.params?.user?.profile_picture}
								setImage={setpp}
								removeMessage={`Remove photo? Profile photo helps others identify you.`}
								handleImageSave={handleProfilePhotoSave}
								handleImageRemove={handleProfilePhotoRemove}
							></UploadComponent>
							{/* LinkedIn Profile Link */}
							<a
								className="default-btn-color"
								onClick={() => {
									setLinkedInModalOpen(true);
									setLinkedInUrl(props.params.user?.linkedin_url);
								}}
							>
								{" "}
								{GetAntIcon("linkedin")} LinkedIn profile
							</a>
						</div>
						<div
							className="profile-container"
							style={{ flex: "1", flexGrow: 1, padding: "20px" }}
						>
							<Form
								layout={"vertical"}
								form={formRef}
								requiredMark="optional"
								onChange={() => {
									setFormReset(false);
								}}
								style={{
									gap: "20px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Divider className="divider" orientation="left" plain>
									User details
								</Divider>
								<Form.Item label="Full name" name="name" required={true}>
									<Input type={""} disabled />
								</Form.Item>
								<div style={{ display: "flex", gap: "20px" }}>
									<Form.Item
										required={true}
										label="Email"
										name="email"
										style={{ flex: "1", width: "60%" }}
									>
										<Input disabled />
									</Form.Item>
									<Form.Item
										label="Phone"
										name="phone_number"
										style={{ flex: "1" }}
									>
										<Input
											// onKeyPress={}
											onChange={(event: any) => {
												const numericValue =
													event.target.value.replace(/\D/g, "") || null;

												formRef.setFieldsValue({
													phone_number: numericValue,
												});
											}}
											addonBefore={
												<Form.Item name="country_code" noStyle>
													<Select
														getPopupContainer={(trigger: any) =>
															trigger.parentNode
														}
														style={{ width: "80px" }}
														showSearch
														removeIcon
														onChange={(value: any) => {
															// handleFormChange("country_code", value);
														}}
													>
														{uniqueCountryCodes.map((code: any) => (
															<Option value={code}>{code}</Option>
														))}
													</Select>
												</Form.Item>
											}
											style={{ width: "100%" }}
										/>
									</Form.Item>
								</div>
								<Divider orientation="left" plain>
									App preferences
								</Divider>
								<Form.Item
									name="theme"
									label="Mode"
									initialValue={"dark"}
									required
								>
									<Radio.Group className="light-dark-mode-radio">
										<Radio.Button
											className="light-dark-mode-radio-button"
											value={"light"}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												<Image
													width={300}
													src={lightModePreview}
													alt="Light mode"
													preview={false}
												/>
												Light mode
											</div>
										</Radio.Button>
										<Radio.Button
											className="light-dark-mode-radio-button"
											value={"dark"}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												<Image
													width={300}
													src={darkModePreview}
													alt="Dark mode"
													preview={false}
												/>
												Dark mode
											</div>
										</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Form>
						</div>
					</div>
					<div className="generic-footer" style={{ height: "50px" }}>
						<FormButtonCancel
							form={formRef}
							handleFormCancel={resetForm}
							formReset={formReset}
						/>
						<FormButtonSave
							form={formRef}
							handleFormSave={handleFormSave}
							formReset={formReset}
						/>
					</div>
				</div>
				<div className="side-container" style={{ flex: "1" }}></div>
				<Modal
					destroyOnClose
					closable={false}
					open={linkedInModalOpen}
					title={
						<div className="modal-generic-header">Link to LinkedIn Profile</div>
					}
					footer={
						<div>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									setLinkedInModalOpen(false);
									setLinkedInUrl(null);
									setLinkedChange(false);
								}}
							>
								Cancel
							</Button>
							<Button
								disabled={!linkedChange}
								type={"primary"}
								onClick={() => {
									setLinkedInModalOpen(false);
									setLinkedInUrl(null);
									setLinkedChange(false);
									handleLinkedInSave();
								}}
							>
								Save
							</Button>
						</div>
					}
					centered
				>
					<div className="input-form">
						<div className="input-form-name">LinkedIn URL</div>
						<div
							style={{ display: "flex", flexDirection: "row", width: "100%" }}
						>
							<Input
								defaultValue={props?.params?.user?.linkedin_url}
								onChange={(ev: any) => {
									setLinkedInUrl(ev.target.value);
									setLinkedChange(true);
								}}
							/>
							<Button
								disabled={!linkedinUrl}
								onClick={() => {
									if (
										!linkedinUrl.startsWith("https://") &&
										!linkedinUrl.startsWith("http://")
									)
										window.open("https://" + linkedinUrl, "_blank");
									else window.open(linkedinUrl, "_blank");
								}}
								icon={GetAntIcon("export")}
								className="addon-button"
							/>
						</div>
					</div>
				</Modal>
				<Modal
					className="empty-modal"
					open={emptyModalOpen}
					closable={false}
					maskClosable={true}
					onCancel={() => {
						setEmptyModalOpen(false);
					}}
					zIndex={1050}
					footer={<></>}
					style={{ background: "transparent" }}
				>
					<></>
				</Modal>
			</div>
		</div>
	);
}

Profile.propTypes = {
	params: PropTypes.any,
};

export default Profile;
