import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import {
	Button,
	Checkbox,
	ColorPicker,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Popconfirm,
	Popover,
	Radio,
	Select,
	Space,
	Switch,
	Tag,
	TimePicker,
	Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { HomeContext } from "../containers/Home";
import { GetAntIcon } from "../utils/ant_icons";
import { uniqueCountryCodes } from "../utils/countryCodes";
import Emitter from "../utils/emitter";
import { getCrudaClass, multiCheckItems } from "../utils/lookup_list";
import { convertToDMS, getInitials } from "../utils/utils";
import LookupMissingTooltip from "./LookupMissingTooltip";
import ReorderList from "./ReorderList";
import UploadComponent from "./UploadComponent";
import moment from "moment";
dayjs.extend(utc);

const CustomForm = (props: any) => {
	const [initialForm, setInitialForm]: any = useState<any>(null);
	const [selectedRole, setSelectedRole] = useState<any>(null);
	const [selectFieldValues, setSelectFieldValues] = useState<any>({});
	const [dynamicInput, setDynamicInput] = useState<any>({});
	const [firstField, setFirstField] = useState<any>(null);
	const [image, setImage] = useState<any>(null);
	const [filteredDataOptions, setFilteredDataOptions] = useState<any>(null);
	const [errorMessages, setErrorMessages] = useState<any>({});
	const [editLatLong, setEditLatLong] = useState<any>({
		latitude: false,
		longitude: false,
	});

	const context: any = useContext(HomeContext);
	const navigate: any = useNavigate();

	// Function to force re-render form data render
	const [forceRender, forceRerender] = useState<any>(0);
	const [formList, setFormList] = useState<any>(null);

	const formRef = props.formRef;
	const dataOptions = props.dataOptions; // Data from the table which is previously named as extraData
	const activeRowKey = props?.activeRowKey;
	const activeRecord = props?.activeRecord;
	const setActiveRecord = props?.setActiveRecord;
	const handleFormCancel = props?.handleFormCancel;
	const handleFormSave = props?.handleFormSave;
	const handleFormDelete = props?.handleFormDelete;
	const getDeleteMessage = props?.getDeleteMessage;
	const resetForm = props?.resetForm;
	const disabledTooltipMessage = props?.disabledTooltipMessage;
	const setFormReset = props?.setFormReset;
	const tabKey = props?.tabKey;
	const formOption = props?.formOption;
	const readOnly = props?.readOnly || false;

	Form.useWatch([], formRef);

	useEffect(() => {
		if (props.filteredDataOptions) {
			setFilteredDataOptions(props.filteredDataOptions);
		}
	}, [props.filteredDataOptions]);

	useEffect(() => {
		getCrudaClass(tabKey)
			.then((crudaClassInstance: any) => {
				let formList: any = null;
				formList = crudaClassInstance?.getFormList(
					tabKey,
					formOption,
					activeRecord,
					navigate
				);

				setFormList(formList);
			})
			.catch((e: any) => {
				console.error(e);
			});
	}, [tabKey, activeRowKey]);

	const filterOption = (input: any, option: any) => {
		return option.label.toLowerCase().includes(input.toLowerCase());
	};

	const renderLookupMissing = (
		key: any,
		index: any,
		identifier: any,
		others: any
	) => {
		let value = formRef.getFieldValue(key);
		let found: any = null;
		// use the index to identify the value
		if (Array.isArray(value)) {
			const item = value[index];
			// look of the item[identifier] is in the lookup data which is the context.allData.roles
			found = context.allData?.roles?.find((role: any) => {
				return role._id === item[identifier];
			});
		}

		if (!found) {
			return (
				<LookupMissingTooltip
					// userRole={props.params.userRole}
					content={
						<div className="lookup-missing-tooltip">
							<WarningOutlined />
							Lookup missing
						</div>
					}
				/>
			);
		}
	};

	const handleSelectOptions = (
		keyProperty: any,
		keyLabel: any,
		data: any = null,
		relay: any = null,
		dependsOn: any = null
	) => {
		let mappedArray: any = [];
		const uniqueValues = new Set();

		// set Dropdown selection list
		if (keyProperty && keyLabel) {
			let dataArray = data;

			// If it requires a relay but the relay value has no data, return empty
			if (
				(relay && formRef.getFieldValue(relay) == undefined) ||
				formRef.getFieldValue(relay) == ""
			) {
				mappedArray = [];
			} else {
				if (dependsOn?.length) {
					let allFilteredValues: any[] = [];

					dependsOn.forEach((dependency: string) => {
						const dependValue = formRef.getFieldValue(dependency);
						let filterOutData: any = null;

						if (typeof dependValue === "string") {
							filterOutData = dependValue;
							allFilteredValues.push(filterOutData);
						} else if (Array.isArray(dependValue)) {
							if (dependValue.every((item) => typeof item === "string")) {
								filterOutData = Array.from(new Set(dependValue));
							} else {
								filterOutData = Array.from(
									new Set(dependValue.flatMap((item: any) => item.appointee))
								);
							}
							allFilteredValues = allFilteredValues.concat(filterOutData);
						}
					});

					// Remove duplicates from all filtered values
					allFilteredValues = Array.from(new Set(allFilteredValues));

					// Filter the dataArray based on allFilteredValues
					dataArray = dataArray?.filter(
						(item: any) => !allFilteredValues.includes(item.value)
					);
				}
				mappedArray = dataArray
					?.map((obj: any) => {
						const label = obj[keyLabel];
						const key = obj[keyProperty];

						// Check if the value is unique
						if (!uniqueValues.has(key) && label != "" && key) {
							uniqueValues.add(key);
							return { label: label, value: key };
						}

						return null; // If not unique, return null
					})
					.filter(Boolean) // Remove null values
					.sort((a: any, b: any) => {
						if (a.label.toLowerCase() < b.label.toLowerCase()) {
							return -1;
						}
						if (a.label.toLowerCase() > b.label.toLowerCase()) {
							return 1;
						}
						return 0;
					});
			}
		}
		return mappedArray;
	};

	// Custom validation for setting rules for all Inputs
	const customValidatorRules = (ruleList: any) => {
		return ruleList?.map((rule: any) => {
			return {
				validator: (x: any, value: any) => {
					if (rule.namesToBeValidated) {
						// get the values of the array field rule.namesToBeValidated
						let values = rule.namesToBeValidated.map((key: any) =>
							formRef.getFieldValue(key)
						);
						console.log("Values: ", values);
						if (rule.type === "comparison") {
							let boolean =
								values[0] > values[1] || values[0]?.isAfter(values[1]);
							if (boolean) {
								return Promise.reject(new Error(rule.message));
							} else {
								return Promise.resolve();
							}
						} else {
							if (values.every((value: any) => value == rule.value)) {
								return Promise.reject(new Error(rule.message));
							} else {
								return Promise.resolve();
							}
						}
					} else {
						return Promise.resolve();
					}
				},
			};
		});
	};

	// Set the form field type based on the type from the form list
	const setInputType = (element: any, index: any, isChild: boolean = false) => {
		let dataObject: any = null;
		const disabled: any = element.disabled || activeRecord?.[props.disabledKey];
		if (element.displayWhen) {
			if (
				formRef.getFieldValue(element.displayWhen.name) !==
				element.displayWhen.value
			)
				return <></>;
		}
		switch (element.type) {
			case "input":
				return (
					<Tooltip
						title={
							activeRecord?.[props.disabledKey] && disabledTooltipMessage
								? disabledTooltipMessage
								: ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							rules={[
								{ required: element.required, message: "" },
								...(element.validatorFunctions || []).map((validator: any) => ({
									validator,
								})),
							]}
							tooltip={
								element.tooltip
									? {
											title: element.tooltip,
											icon: GetAntIcon("question3"),
											placement: "right",
											overlayStyle: { maxWidth: "400px" },
									  }
									: undefined
							}
						>
							<Input
								// autoFocus={index == 0 && !activeRowKey && !isChild}
								autoComplete="off"
								count={
									element.count && {
										show: true,
										max: element.count,
									}
								}
								onChange={(event: any) => {
									let value = event.target.value
										.replace(/^ /, "")
										.replace(/\s+/g, " ");
									if (element.count) {
										value = value.slice(0, element.count);
									}
									formRef.setFieldValue(element.name, value);

									setFormReset(false);
								}}
								style={{
									width: element.width ? `${element.width * 4}%` : "100%",
								}}
								disabled={disabled}
								readOnly={readOnly}
							></Input>
						</Form.Item>
					</Tooltip>
				);
			case "textArea":
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						>
							<TextArea
								// autoFocus={index == 0 && !activeRowKey && !isChild}
								count={
									element.count && {
										show: true,
										max: element.count,
									}
								}
								disabled={disabled}
								autoSize={element.size || { minRows: 3, maxRows: 5 }}
								onChange={(event: any) => {
									let value = event.target.value
										.replace(/^ /, "")
										.replace(/\s+/g, " ");

									if (element.count) {
										value = value.slice(0, element.count);
									}
									formRef.setFieldValue(element.name, value);

									setFormReset(false);
								}}
								style={{
									resize: element.resize == false ? "none" : "vertical",
								}}
								readOnly={readOnly}
							></TextArea>
						</Form.Item>
					</Tooltip>
				);
			case "select":
				dataObject = filteredDataOptions || dataOptions;
				// console.log("Data Options: ", dataOptions);
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							rules={[
								{ required: element.required, message: "" },
								...customValidatorRules(element.validationRules || []),
							]}
							tooltip={
								element.tooltip
									? {
											title: element.tooltip,
											icon: GetAntIcon("question3"),
											placement: "right",
											overlayStyle: { maxWidth: "400px" },
									  }
									: undefined
							}
						>
							<Select
								getPopupContainer={(trigger: any) => trigger.parentNode}
								// autoFocus={index == 0 && !activeRowKey && !isChild}
								allowClear
								filterOption={filterOption}
								showSearch
								options={
									element?.options ||
									handleSelectOptions(
										element.optionKey,
										element.optionLabel,
										element.extraKey ? dataObject[element.extraKey] : null,
										element.relayFrom,
										element.dependsOn
									)
								}
								mode={element.mode || undefined}
								tagRender={
									element.mode == "tags"
										? (props: any) => {
												if (element.mode === "tags") {
													let tag = dataOptions[element.extraKey]?.find(
														(element: any) => {
															return props.label == element.name;
														}
													);
													if (tag) {
														return (
															<Tag
																style={{
																	whiteSpace: "normal",
																	wordWrap: "break-word",
																}}
																color={tag.color}
															>
																{tag.name}
															</Tag>
														);
													}
												}
												return <></>;
										  }
										: undefined
								}
								onChange={(value: any) => {
									if (element.relayTo) {
										formRef.setFieldValue(element.relayTo, undefined);
										let indexArray = dataOptions[element.extraKey].find(
											(object: any) => object[element.optionKey] === value
										);
										let relayArray = dataOptions[element.relayTo];
										if (relayArray) {
											let filteredData: any = {};
											if (indexArray) {
												filteredData[element.relayTo] = relayArray
													?.map((object: any) => {
														if (
															indexArray[element.relayIndexKey]?.includes(
																object[element.relayLookupKey]
															)
														) {
															return object;
														}
													})
													.filter((item: any) => item);
											} else {
												filteredData = relayArray;
											}

											if (filteredData) {
												setFilteredDataOptions({
													...dataObject,
													...filteredData,
												});
											}
										}
									}
									setFormReset(false);
								}}
								disabled={disabled || readOnly}
							></Select>
						</Form.Item>
					</Tooltip>
				);
			case "select-multiple":
				let fieldValues = formRef.getFieldValue(element.name) || [];
				dataObject = filteredDataOptions || dataOptions;
				console.log("Select multiple", filteredDataOptions);
				let initialValues = initialForm ? initialForm[element.name] || [] : [];
				return (
					<div
						style={{ gap: "10px", display: "flex", flexDirection: "column" }}
					>
						<div
							style={{ gap: "8px", display: "flex", flexDirection: "column" }}
						>
							<Form.Item
								style={{ display: "none" }}
								name={element.name}
								rules={[{ required: element.required, message: "" }]}
							></Form.Item>
							<span>
								{element.label}{" "}
								{!element.required && (
									<span style={{ opacity: "0.45" }}>(optional)</span>
								)}
							</span>
							{!disabled && !readOnly && (
								<Tooltip
									title={
										disabled && disabledTooltipMessage
											? disabledTooltipMessage
											: ""
									}
								>
									<Select
										getPopupContainer={(trigger: any) => trigger.parentNode}
										// autoFocus={index == 0 && !activeRowKey && !isChild}
										filterOption={filterOption}
										showSearch
										options={handleSelectOptions(
											element.optionKey,
											element.optionLabel,
											element.extraKey ? dataObject[element.extraKey] : null,
											element.relayFrom,
											element.dependsOn
										)}
										value={null}
										autoClearSearchValue
										mode={element.mode || undefined}
										tagRender={(props: any) => {
											if (element.mode === "tags") {
												let tag = dataOptions?.find((element: any) => {
													return props.label == element.name;
												});
												if (tag) {
													return (
														<Tag
															style={{
																whiteSpace: "normal",
																wordWrap: "break-word",
															}}
															color={tag.color}
														>
															{tag.name}
														</Tag>
													);
												}
											}
											return <></>;
										}}
										onChange={(value: any) => {
											setFormReset(false);
											let formValues =
												formRef.getFieldValue(element.name) || [];
											formValues = [...formValues];

											formValues.unshift(value);
											formRef.setFieldValue(element.name, formValues);

											let dataState: any = [];
											let data: any = {};

											if (filteredDataOptions) {
												dataState = [...filteredDataOptions[element.extraKey]];
												data = { ...filteredDataOptions };
												console.log("Got filter data options", data);
											} else {
												dataState = [...dataOptions[element.extraKey]];
												data = { ...dataOptions };
												console.log("No Got filter data options", data);
											}
											let filteredData = dataState.filter(
												(item: any) => item[element.optionKey] != value
											);
											console.log("On change select multiple", filteredData);
											data[element.extraKey] = [...filteredData];
											setFilteredDataOptions(data);

											//BAD
											if (tabKey == "company") {
												let formValue = formRef.getFieldValue("parent");
												if (value == formValue) {
													formRef.setFieldValue("parent", undefined);
												}
											}
										}}
										disabled={disabled}
									></Select>
								</Tooltip>
							)}
						</div>
						{dataOptions[element.extraKey]
							?.filter((item: any) => {
								return fieldValues?.includes(item[element.optionKey]);
							})
							.sort((a: any, b: any) => {
								if (
									!initialValues.includes(a[element.optionKey]) ||
									!initialValues.includes(b[element.optionKey])
								) {
									return (
										fieldValues.indexOf(a[element.optionKey]) -
										fieldValues.indexOf(b[element.optionKey])
									);
								} else {
									return a[element.optionLabel].localeCompare(
										b[element.optionLabel]
									);
								}
							})
							?.map((item: any) => {
								return (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
											alignSelf: "stretch",
										}}
									>
										<Input
											style={{ flex: 1 }}
											disabled
											value={item[element.optionLabel]}
										/>
										{!disabled && !readOnly && (
											<span
												className="clickable-text"
												style={{ cursor: "pointer" }}
												onClick={() => {
													let formValues =
														formRef.getFieldValue(element.name) || [];
													formValues = [...formValues];
													let dataState: any = [];
													let data: any = {};

													if (filteredDataOptions) {
														dataState = [
															...filteredDataOptions[element.extraKey],
														];
														data = { ...filteredDataOptions };
													} else {
														dataState = [...dataOptions[element.extraKey]];
														data = { ...dataOptions };
													}

													let filteredData = dataState;
													let foundData = dataOptions[element.extraKey].find(
														(_element: any) =>
															_element[element.optionKey] ===
															item[element.optionKey]
													);
													let foundIndex = formValues.findIndex(
														(_element: any) =>
															_element === item[element.optionKey]
													);
													if (foundIndex != -1 && foundData) {
														formValues.splice(foundIndex, 1);
														filteredData.push(foundData);
														filteredData = filteredData.sort((a: any, b: any) =>
															a[element.optionLabel]?.localeCompare(
																b[element.optionLabel]
															)
														);
														formRef.setFieldValue(element.name, formValues);
														data[element.extraKey] = [...filteredData];
													}
													setFilteredDataOptions(data);
													setFormReset(false);
												}}
											>
												{GetAntIcon("close")}
											</span>
										)}
									</div>
								);
							})}
					</div>
				);
			case "role-dynamic-form":
				const formChanged = () => {
					let formData = formRef.getFieldValue(element.name);
					let filteredData =
						dataOptions[element.extraKey]?.filter(
							(data: any) =>
								data["name"].toLowerCase() !== "project manager" &&
								!formData.some((item: any) => item["role"] === data["name"])
						) || [];

					let data = { ...dataOptions };
					data[element.extraKey] = [...filteredData];
					setFilteredDataOptions(data);
					setFormReset(false);
				};
				dataObject = filteredDataOptions || dataOptions;

				return (
					<div
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Create a form item that will use to select options as its value */}
						<div
							style={{
								gap: "8px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
									gap: `${element.gap}`,
								}}
							>
								<Form.List name={element.name}>
									{(fields, { add, remove }) => (
										<>
											{/* First loop for trigerring the mapping */}
											{element.children?.map((child: any, index: any) => (
												<Tooltip
													title={
														disabled && disabledTooltipMessage
															? disabledTooltipMessage
															: ""
													}
												>
													<Form.Item
														label={child.label}
														required={child.required}
													>
														<Select
															getPopupContainer={(trigger: any) =>
																trigger.parentNode
															}
															onClear={() => {
																if (element.children.length - 1 !== index) {
																	setSelectedRole(null);
																}
															}}
															allowClear
															onDropdownVisibleChange={() => formChanged()}
															// autoFocus={index == 0 && !activeRowKey && !isChild}
															showSearch
															mode={child.mode}
															disabled={
																(element.children.length - 1 === index &&
																	!selectedRole) ||
																disabled
															}
															options={handleSelectOptions(
																child.optionKey,
																child.optionLabel,
																child.extraKey
																	? dataObject[child.extraKey]
																	: null,
																child.relayFrom,
																child.dependsOn
															)}
															autoClearSearchValue
															value={selectFieldValues[child.name]}
															onChange={(value: any, option: any) => {
																if (element.children.length - 1 !== index) {
																	// option.label is assigned to store the label of the option which is the role name
																	let formValues = selectFieldValues;
																	formValues[child.uniqueKeyName] =
																		option?.value;
																	formValues[child.name] = option?.label;
																	setSelectFieldValues(formValues);
																	setSelectedRole(option?.label);
																} else {
																	// value is pushed to store the email of the appointee
																	let formValues = selectFieldValues;
																	formValues[child.name] = value;
																	setSelectFieldValues(formValues);
																}

																// The dynamic form will be added only if the all the fields are filled
															}}
															onBlur={() => {
																if (
																	Object.keys(selectFieldValues).length ===
																	element.children.length + 1
																) {
																	add(selectFieldValues);
																	setSelectedRole(null);
																	setSelectFieldValues({});
																}
															}}
														></Select>
													</Form.Item>
												</Tooltip>
											))}
											<div
												style={{
													margin: "10px 0px 10px 0px",
													gridColumn: "1 / -1",
													display:
														formRef.getFieldValue(element.name)?.length === 0
															? "none"
															: "block",
												}}
												key={element.label}
											>
												<span
													style={{
														marginRight: "10px",
													}}
												>
													{GetAntIcon("team")}
												</span>
												List
											</div>
											{/* Dynamic Part where mapping is */}
											{fields?.map(({ key, name, ...restField }) => (
												<>
													{element.children?.map((child: any, index: any) =>
														element.children.length - 1 !== index ? (
															<>
																<Form.Item
																	key={child.uniqueKeyName}
																	{...restField}
																	name={[name, child.name]}
																	rules={[
																		{
																			required: true,
																			message: "",
																		},
																	]}
																>
																	<Input
																		style={{
																			flex: 1,
																		}}
																		disabled
																	/>
																	{/* This parameter passing is still messy. */}
																	{renderLookupMissing(
																		"role_assignments",
																		name,
																		child.uniqueKeyName,
																		{ ...restField }
																	)}
																</Form.Item>
															</>
														) : (
															<div
																style={{
																	display: "flex",
																	alignContent: "center",
																	alignItems: "center",
																	gap: "10px",
																}}
																key={child.uniqueKeyName}
															>
																<Tooltip
																	title={
																		disabled && disabledTooltipMessage
																			? disabledTooltipMessage
																			: ""
																	}
																>
																	<Form.Item
																		{...restField}
																		name={[name, child.name]}
																		rules={[
																			{
																				required: true,
																				message: "",
																			},
																		]}
																		style={{
																			flex: 1,
																		}}
																	>
																		<Select
																			getPopupContainer={(trigger: any) =>
																				trigger.parentNode
																			}
																			allowClear
																			disabled={disabled}
																			mode={child.mode || undefined}
																			filterOption={filterOption}
																			showSearch
																			options={handleSelectOptions(
																				child.optionKey,
																				child.optionLabel,
																				child.extraKey
																					? dataObject[child.extraKey]
																					: null,
																				child.relayFrom,
																				child.dependsOn
																			)}
																			onChange={(value: any) => {
																				setFormReset(false);
																			}}
																		></Select>
																	</Form.Item>
																</Tooltip>
																{/* Used Form.Item to use the same css settings as other form items. */}
																<Form.Item>
																	{/* Close Button */}
																	<Popconfirm
																		icon={
																			<ExclamationCircleOutlined
																				style={{ color: "grey" }}
																			/>
																		}
																		title={
																			<div
																				style={{
																					maxWidth: "300px",
																					padding: "10px",
																				}}
																			>
																				<>{element.removeItemMessage}</>
																			</div>
																		}
																		placement="topRight"
																		cancelButtonProps={{ ghost: true }}
																		cancelText="Cancel"
																		okText="Remove"
																		okType="default"
																		onConfirm={() => {
																			remove(name);
																			formChanged();
																			// setFormReset(false);
																		}}
																	>
																		<span
																			className="clickable-text"
																			style={{
																				cursor: "pointer",
																				margin: "auto",
																				padding: "2px",
																			}}
																			// onClick={() => {
																			// 	remove(name);
																			// 	formChanged();
																			// }}
																		>
																			{GetAntIcon("close")}
																		</span>
																	</Popconfirm>
																</Form.Item>
															</div>
														)
													)}
												</>
											))}
										</>
									)}
								</Form.List>
							</div>
						</div>
					</div>
				);
			case "temporary-dynamic":
				return (
					<div
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Create a form item that will use to select options as its value */}
						<div
							style={{
								gap: "8px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
									gap: `${element.gap}`,
								}}
							>
								<Form.List name={element.name}>
									{(fields, { add, remove }) => (
										<>
											{/* First loop for trigerring the mapping */}
											{element.children?.map((child: any, index: any) => (
												<Tooltip
													title={
														disabled && disabledTooltipMessage
															? disabledTooltipMessage
															: ""
													}
												>
													<Form.Item
														label={child.label}
														required={child.required}
													>
														<Input
															// autoFocus={index == 0 && !activeRowKey && !isChild}
															autoComplete="off"
															count={
																child.count && {
																	show: true,
																	max: child.count,
																}
															}
															value={dynamicInput[child.name]}
															onChange={(event: any) => {
																let value = event.target.value
																	.replace(/^ /, "")
																	.replace(/\s+/g, " ");
																if (child.count) {
																	value = value.slice(0, child.count);
																}
																// formRef.setFieldValue(child.name, value);
																// setFormReset(false);

																setDynamicInput({
																	...dynamicInput,
																	[child.name]: value,
																});
															}}
															onBlur={() => {
																if (
																	Object.keys(dynamicInput).length ===
																	element.children.length
																) {
																	add(dynamicInput);
																	setDynamicInput({});
																}
															}}
															style={{
																width: child.width
																	? `${child.width * 4}%`
																	: "100%",
															}}
															disabled={child.disabled || disabled}
														></Input>
													</Form.Item>
												</Tooltip>
											))}
											<div
												style={{
													margin: "10px 0px 10px 0px",
													gridColumn: "1 / -1",
													display:
														formRef.getFieldValue(element.name)?.length === 0 ||
														formRef.getFieldValue(element.name) === undefined
															? "none"
															: "block",
												}}
												key={element.label}
											>
												<span
													style={{
														marginRight: "10px",
													}}
												>
													{GetAntIcon("team")}
												</span>
												List
											</div>
											{/* Dynamic Part where mapping is */}
											{fields?.map(({ key, name, ...restField }) => (
												<>
													{element.children?.map((child: any, index: any) =>
														element.children.length - 1 !== index ? (
															<>
																<Tooltip
																	title={
																		disabled && disabledTooltipMessage
																			? disabledTooltipMessage
																			: ""
																	}
																>
																	<Form.Item
																		{...restField}
																		name={[name, child.name]}
																		rules={[
																			{
																				required: true,
																				message: "",
																			},
																		]}
																		style={{
																			flex: 1,
																		}}
																	>
																		<Input
																			onChange={() => {
																				setFormReset(false);
																			}}
																			disabled={disabled}
																		></Input>
																	</Form.Item>
																</Tooltip>
															</>
														) : (
															<div
																style={{
																	display: "flex",
																	alignContent: "center",
																	alignItems: "center",
																	gap: "10px",
																}}
															>
																<Tooltip
																	title={
																		disabled && disabledTooltipMessage
																			? disabledTooltipMessage
																			: ""
																	}
																>
																	<Form.Item
																		{...restField}
																		name={[name, child.name]}
																		rules={[
																			{
																				required: true,
																				message: "",
																			},
																		]}
																		style={{
																			flex: 1,
																		}}
																	>
																		<Input
																			onChange={() => {
																				setFormReset(false);
																			}}
																			disabled={disabled}
																		></Input>
																	</Form.Item>
																</Tooltip>
																{/* Used Form.Item to use the same css settings as other form items. */}
																<Form.Item>
																	{/* Close Button */}
																	<span
																		className="clickable-text"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																			padding: "2px",
																		}}
																		onClick={() => {
																			remove(name);
																			// formChanged();
																			setFormReset(false);
																		}}
																	>
																		{GetAntIcon("close")}
																	</span>
																</Form.Item>
															</div>
														)
													)}
												</>
											))}
										</>
									)}
								</Form.List>
							</div>
						</div>
					</div>
				);
			case "document-link":
				// only for 2 input field dynamic form
				// combine role-dynamic-form and temporary-dynamic

				const dependencies = (
					name: any,
					childname: any,
					itemLength: number
				) => {
					// loop through the itemLength to create the array of dependencies
					let dependencies = [];
					for (let i = 0; i < itemLength; i++) {
						dependencies.push([name, i, childname]);
					}
					return dependencies;
				};

				return (
					<div
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Create a form item that will use to select options as its value */}
						<div
							style={{
								gap: "8px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
									gap: `${element.gap}`,
								}}
							>
								<Form.List
									name={element.name}
									rules={[
										{
											validator: async (rule, value) => {},
										},
									]}
								>
									{(fields, { add, remove }) => (
										<>
											{element.children?.map((child: any, index: any) => (
												<Tooltip
													title={
														disabled && disabledTooltipMessage
															? disabledTooltipMessage
															: ""
													}
												>
													<Form.Item
														label={child.label}
														required={child.required}
														tooltip={
															child.tooltip
																? {
																		title: child.tooltip,
																		icon: GetAntIcon("question3"),
																		placement: "right",
																		overlayStyle: { maxWidth: "400px" },
																  }
																: undefined
														}
													>
														{/* condition based on child.type */}
														{child.type === "input" ? (
															<Input
																// autoFocus={
																// 	index == 0 && !activeRowKey && !isChild
																// }
																autoComplete="off"
																count={
																	child.count && {
																		show: true,
																		max: child.count,
																	}
																}
																value={dynamicInput[child.name]}
																onChange={(event: any) => {
																	let value = event.target.value
																		.replace(/^ /, "")
																		.replace(/\s+/g, " ");
																	if (child.count) {
																		value = value.slice(0, child.count);
																	}

																	if (element.children.length - 1 !== index) {
																		setFirstField(event.target.value);
																	}

																	setDynamicInput({
																		...dynamicInput,
																		[child.name]: value,
																	});
																}}
																onBlur={(event: any) => {
																	let duplicateTitleExist = false;
																	let invalidUrl = false;
																	let errorMsg: any = {};
																	if (
																		Object.keys(dynamicInput).length ===
																		element.children.length
																	) {
																		// check if the value of the first field is already exist in the list
																		const currentList: any =
																			formRef.getFieldValue(element.name);
																		if (currentList && currentList.length > 0) {
																			const currentTitleList = currentList.map(
																				(item: any) =>
																					item[element.children[0].name]
																			);
																			if (
																				currentTitleList.includes(
																					dynamicInput[element.children[0].name]
																				)
																			) {
																				duplicateTitleExist = true;
																				errorMsg = {
																					...errorMsg,
																					[element.children[0]
																						.name]: `Duplicate ${element.children[0].name} is not allowed`,
																				};
																			} else {
																				duplicateTitleExist = false;
																				delete errorMsg[
																					element.children[1].name
																				];
																			}
																		}

																		// Check url
																		const url =
																			dynamicInput[element.children[1].name];
																		const urlRegex = new RegExp(
																			/^((http[s]?|ftp)?:\/\/)?(www\.)+[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
																		);
																		if (!urlRegex.test(url)) {
																			invalidUrl = true;
																			errorMsg = {
																				...errorMsg,
																				[element.children[1]
																					.name]: `Invalid URL`,
																			};
																		} else {
																			invalidUrl = false;
																			delete errorMsg[element.children[1].name];
																		}

																		// Add and reset dynamic input if no duplicate title and valid url
																		if (!duplicateTitleExist && !invalidUrl) {
																			if (
																				event.target.value &&
																				event.target.value !== ""
																			) {
																				setErrorMessages({});
																				add(dynamicInput);
																				setFirstField(null);
																				setDynamicInput({});
																			}
																		} else {
																			setErrorMessages(errorMsg);
																		}
																	}
																}}
																style={{
																	width: child.width
																		? `${child.width * 4}%`
																		: "100%",
																}}
																disabled={
																	(element.children.length - 1 === index &&
																		!firstField) ||
																	disabled
																}
																readOnly={readOnly}
															></Input>
														) : child.type === "select" ? (
															<Select disabled={disabled}></Select>
														) : (
															<Input disabled></Input>
														)}
													</Form.Item>
													{Object.values(errorMessages).length > 0 && (
														<div
															style={{
																color: "#dc4446",
																fontSize: "14px",
																marginTop: "-10px",
															}}
														>
															{errorMessages[child.name]}
														</div>
													)}
												</Tooltip>
											))}
											<div
												style={{
													margin: "10px 0px 10px 0px",
													color: "rgba(255,255,255,0.45)",
													gridColumn: "1 / -1",
													display:
														formRef.getFieldValue(element.name)?.length === 0 ||
														formRef.getFieldValue(element.name) === undefined
															? "none"
															: "block",
												}}
												key={element.label}
											>
												{element.listLabel}
											</div>
											{fields?.map(({ key, name, ...restField }) => (
												// key and name is index
												<>
													{element.children?.map((child: any, index: any) =>
														element.children.length - 1 !== index ? (
															<>
																<Tooltip
																	title={
																		disabled && disabledTooltipMessage
																			? disabledTooltipMessage
																			: ""
																	}
																>
																	<Form.Item
																		{...restField}
																		name={[name, child.name]}
																		dependencies={dependencies(
																			element.name,
																			child.name,
																			formRef.getFieldValue(element.name).length
																		)}
																		rules={[
																			{
																				required: true,
																				message: "",
																			},
																			{
																				// create validator to check whether the input already exist in the list
																				validator: async (_, value) => {
																					const currentDocumentLink =
																						formRef.getFieldValue(element.name);
																					// check if the value is already exist in the list
																					// dont include the current value so that it won't compare with itself
																					if (
																						currentDocumentLink &&
																						currentDocumentLink.length > 0
																					) {
																						const isExist =
																							currentDocumentLink.filter(
																								(item: any, _index: any) => {
																									if (_index !== key) {
																										item.index = _index;
																										return (
																											item[child.name] === value
																										);
																									}
																								}
																							);

																						if (isExist.length > 0) {
																							return Promise.reject(
																								`Duplicate ${child.name} is not allowed`
																							);
																						}
																					}
																				},
																			},
																		]}
																		style={{
																			flex: 1,
																		}}
																	>
																		<Input
																			disabled={disabled}
																			onChange={() => {
																				setFormReset(false);
																			}}
																			readOnly={readOnly}
																		></Input>
																	</Form.Item>
																</Tooltip>
															</>
														) : (
															// Input field for the last child
															<div
																style={{
																	display: "flex",
																	alignContent: "center",
																	alignItems: "center",
																	gap: "10px",
																}}
															>
																<Space.Compact
																	style={{ width: "100%", flex: 1 }}
																>
																	<Tooltip
																		title={
																			disabled && disabledTooltipMessage
																				? disabledTooltipMessage
																				: ""
																		}
																	>
																		<Form.Item
																			{...restField}
																			name={[name, child.name]}
																			rules={[
																				{
																					required: true,
																					message: "",
																				},
																				{ type: "url", message: "Invalid URL" },
																			]}
																			style={{ width: "100%" }}
																		>
																			<Input
																				disabled={disabled}
																				onChange={() => {
																					setFormReset(false);
																				}}
																				readOnly={readOnly}
																			></Input>
																		</Form.Item>
																	</Tooltip>
																	<Button
																		onClick={() => {
																			const docLinkArray =
																				formRef.getFieldValue(element.name);
																			const docLinkObject = docLinkArray[key]; //key is from fields key
																			if (
																				!docLinkObject[child.name].startsWith(
																					"https://"
																				) &&
																				!docLinkObject[child.name].startsWith(
																					"http://"
																				)
																			)
																				window.open(
																					"https://" +
																						docLinkObject[child.name],
																					"_blank"
																				);
																			else
																				window.open(docLinkObject[child.name]);
																		}}
																	>
																		{GetAntIcon("export")}
																	</Button>
																</Space.Compact>
																{/* Used Form.Item to use the same css settings as other form items. */}
																{/* Close Button */}
																<Form.Item>
																	{element.removeItemMessage ? (
																		<Popconfirm
																			icon={
																				<ExclamationCircleOutlined
																					style={{ color: "grey" }}
																				/>
																			}
																			title={
																				<div
																					style={{
																						maxWidth: "300px",
																						padding: "10px",
																					}}
																				>
																					<>{element.removeItemMessage}</>
																				</div>
																			}
																			placement="topRight"
																			cancelButtonProps={{ ghost: true }}
																			cancelText="Cancel"
																			okText="Remove"
																			okType="default"
																			onConfirm={() => {
																				remove(name);
																				// formChanged();
																				setFormReset(false);
																			}}
																		>
																			<span
																				className="clickable-text"
																				style={{
																					cursor: "pointer",
																					margin: "auto",
																					padding: "2px",
																					display: readOnly ? "none" : "block",
																				}}
																			>
																				{GetAntIcon("close")}
																			</span>
																		</Popconfirm>
																	) : (
																		<span
																			className="clickable-text"
																			style={{
																				cursor: "pointer",
																				margin: "auto",
																				padding: "2px",
																				display: readOnly ? "none" : "block",
																			}}
																			onClick={() => {
																				remove(name);
																				// formChanged();
																				setFormReset(false);
																			}}
																		>
																			{GetAntIcon("close")}
																		</span>
																	)}
																</Form.Item>
															</div>
														)
													)}
												</>
											))}
										</>
									)}
								</Form.List>
							</div>
						</div>
					</div>
				);
			case "rolesPermissions":
				let arrayList: any = formRef.getFieldValue(element.name) || [];
				arrayList = [...arrayList];

				return (
					<div
						style={{ display: "flex", flexDirection: "column", gap: "10px" }}
					>
						<Form.Item
							style={{ display: "none" }}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						></Form.Item>
						{multiCheckItems(tabKey)?.map((permissions: any) => {
							return (
								<div style={{ border: "1px solid #424242", padding: "10px" }}>
									<Space size={5} direction="vertical">
										<Space>
											<span>{permissions.label}</span>
											<span>
												{permissions.keys.reduce((acc: any, item: any) => {
													if (arrayList.includes(item.key)) {
														return acc + 1;
													}
													return acc;
												}, 0)}
												/{permissions.keys.length}
											</span>
										</Space>
										<span>{permissions.description}</span>
										<Space size={10} direction="vertical">
											<Checkbox
												disabled={disabled}
												onChange={(e: any) => {
													setFormReset(false);
													let checked = e.target.checked;
													permissions.keys.forEach((item: any) => {
														let foundIndex = arrayList.findIndex(
															(permissionKeys: any) => {
																return permissionKeys == item.key;
															}
														);

														if (foundIndex != -1 && !checked) {
															arrayList.splice(foundIndex, 1);
														} else if (checked) {
															arrayList.push(item.key);
														}
													});
													formRef.setFieldValue(element.name, [...arrayList]);
													setFilteredDataOptions([...arrayList]);
												}}
												checked={permissions.keys.every((item: any) => {
													return arrayList.includes(item.key);
												})}
											>
												Select all
											</Checkbox>

											{permissions.keys.map((item: any) => {
												return (
													<Checkbox
														disabled={disabled}
														onChange={(e: any) => {
															setFormReset(false);
															let checked = e.target.checked;
															let foundIndex = arrayList.findIndex(
																(permissionKeys: any) => {
																	return permissionKeys == item.key;
																}
															);
															if (foundIndex != -1 && !checked) {
																arrayList.splice(foundIndex, 1);
															} else if (checked) {
																arrayList.push(item.key);
															}
															formRef.setFieldValue(element.name, [
																...arrayList,
															]);
															setFilteredDataOptions([...arrayList]);
														}}
														checked={arrayList.includes(item.key)}
													>
														{item.label}
													</Checkbox>
												);
											})}
										</Space>
									</Space>
								</div>
							);
						})}
					</div>
				);
			case "image":
				const data: any = activeRecord;
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "8px",
						}}
					>
						<span>{element.label}</span>
						<UploadComponent
							disabled={disabled}
							label={element.name}
							prefix={""}
							initials={getInitials(activeRecord.name, 0)}
							setEmptyModalOpen={() => {}}
							image={data[element.name]}
							removeMessage={element.removeMessage}
							setImage={(image: any) => {
								setImage(image);
							}}
							handleImageSave={(image: any) => {
								if (typeof handleFormSave === "function") {
									activeRecord[element.name] = image;
									handleFormSave({ ...activeRecord });
								} else {
									element
										.updateImage(activeRowKey, { ...activeRecord, image })
										.then(() => {
											Emitter.emit("alert", {
												type: "success",
												message: `Your ${element.name} has been successfully updated.`,
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
											setImage(image);
										})
										.catch(() => {
											Emitter.emit("alert", {
												type: "error",
												message: "Failed to update. Please try again.",
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
										});
								}
							}}
							handleImageRemove={() => {
								if (typeof handleFormSave === "function") {
									activeRecord[element.name] = "";
									handleFormSave({ ...activeRecord });
								} else {
									element
										.removeImage(activeRowKey, null)
										.then(() => {
											Emitter.emit("alert", {
												type: "success",
												message: `Your ${element.name} has been successfully removed.`,
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
											setImage(null);
										})
										.catch(() => {
											Emitter.emit("alert", {
												type: "error",
												message: "Failed to update. Please try again.",
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
										});
								}
							}}
						></UploadComponent>
					</div>
				);
			case "counter":
				// Depreciated function. Not being used because cannot pass qc
				const format = (value: any, precision: any = null) => {
					if (value === "" || value === undefined || value === null) {
						return "";
					} else {
						// If there is decimal in the value, restrict user to only enter the amount of precision
						if (precision !== null && precision !== 0) {
							if (value.match(/\./g)) {
								let decimal = value.split(".")[1];
								decimal = decimal.substring(0, 1);
								return `${value.split(".")[0]}.${decimal}`;
							} else {
								return value;
							}
						} else {
							// Dont allow decimal to be entered
							return value.replace(/\./g, "");
						}
					}
				};
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							tooltip={
								element.tooltip
									? {
											title: element.tooltip,
											icon: GetAntIcon("question3"),
											placement: "right",
											overlayStyle: { maxWidth: "400px" },
									  }
									: undefined
							}
							rules={[{ required: element.required, message: "" }]}
						>
							<InputNumber
								disabled={disabled}
								min={element.min}
								max={element.max}
								step={element.step}
								onStep={(value: any) => {
									setFormReset(false);
								}}
								onChange={(ev: any) => {}}
								precision={element.precision}
								// formatter={(value: any) => {
								// 	return format(value, element.precision);
								// }}
								style={{
									width: element.width ? `${element.width * 4}%` : "100%",
								}}
							/>
						</Form.Item>
					</Tooltip>
				);
			case "timepicker":
				// Store the selected time
				let formTime: any = null;
				// Get the selected value by `element.name` in `formRef`
				const timeValue = formRef.getFieldValue(element.name);
				// // If `timeValue` is occupied
				// if (timeValue) {
				// 	// Convert the `timeValue` to `moment` object
				// 	formTime = dayjs(timeValue).local() || null;
				// }
				console.log("formTime", timeValue);

				return (
					<div
						style={{ gap: "10px", display: "flex", flexDirection: "column" }}
					>
						{/* <div
							style={{ gap: "8px", display: "flex", flexDirection: "column" }}
						>
							<Form.Item
								style={{ display: "none" }}
								name={element.name}
								rules={[
									{ required: element.required, message: "" },
									//Unusable in this context
									...customValidatorRules(element.validationRules || []),
								]}
							></Form.Item>
							<span>
								{element.label}{" "}
								{!element.required && (
									<span style={{ opacity: "0.45" }}>(optional)</span>
								)}
							</span>
							<Tooltip
								title={
									disabled && disabledTooltipMessage
										? disabledTooltipMessage
										: ""
								}
							>
								<TimePicker
									disabled={disabled}
									disabledTime={() => ({
										// disabledHours: () =>
										// 	Array.from({ length: 24 }, (_, i) => i), // Disable all hours
										// disabledMinutes: () =>
										// 	Array.from({ length: 60 }, (_, i) => i), // Disable all minutes
										// disabledSeconds: () =>
										// 	Array.from({ length: 60 }, (_, i) => i), // Disable all seconds
									})}
									placeholder={element.placeholder}
									style={{
										width: element.width ? `${element.width * 4}%` : "100%",
									}}
									value={formTime}
									format={element.format || "HH:mm"}
									minuteStep={15} // Set intervals of 15 minutes
									
									onChange={(time: any, timeString: any) => {
										// console.log(time, timeString);
										// // Clear the time if invalid or null
										// let formTime = null;
										// if (time) {
										// 	formTime = moment(time).startOf("minute").utc();
										// }
										// formRef.setFieldValue(element.name, formTime);
										console.log(time);
										formRef.setFieldValue(element.name, time);
										forceRerender(forceRender + 1);
										setFormReset(false);
									}}
								/>
							</Tooltip>
						</div> */}
						<Tooltip
							title={
								disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
							}
						>
							<Form.Item
								label={element.label}
								name={element.name}
								rules={[
									{ required: element.required, message: "" },
									//Unusable in this context
									...customValidatorRules(element.validationRules || []),
								]}
							>
								<TimePicker
									disabled={disabled}
									// disabledTime={() => ({
									// disabledHours: () =>
									// 	Array.from({ length: 24 }, (_, i) => i), // Disable all hours
									// disabledMinutes: () =>
									// 	Array.from({ length: 60 }, (_, i) => i), // Disable all minutes
									// disabledSeconds: () =>
									// 	Array.from({ length: 60 }, (_, i) => i), // Disable all seconds
									// })}
									placeholder={element.placeholder}
									style={{
										width: element.width ? `${element.width * 4}%` : "100%",
									}}
									// value={formTime}
									format={element.format || "HH:mm"}
									minuteStep={15} // Set intervals of 15 minutes
									onChange={(time: any, timeString: any) => {
										console.log("CHANGING DATA");
										// console.log(time, timeString);
										// // Clear the time if invalid or null
										// let formTime = null;
										// if (time) {
										// 	formTime = moment(time).startOf("minute").utc();
										// }
										// formRef.setFieldValue(element.name, formTime);
										// console.log(time);
										// formRef.setFieldValue(element.name, time);
										forceRerender(forceRender + 1);
										setFormReset(false);
									}}
								/>
							</Form.Item>
						</Tooltip>
					</div>
				);

			case "datepicker":
				// Store the selected date
				let formdate: any = null;
				// Get the selected value by `element.name` in `formRef`
				const dateValue = formRef.getFieldValue(element.name);
				// If `selectedValue` is occupied
				if (dateValue)
					// Convert the `selectedValue` to `dayjs` object or else do nothing,
					// then store the converted value into `formdate`
					formdate = dayjs(dateValue) || null;
				// Change to utx
				return (
					<div
						style={{ gap: "10px", display: "flex", flexDirection: "column" }}
					>
						<div
							style={{ gap: "8px", display: "flex", flexDirection: "column" }}
						>
							<Form.Item
								style={{ display: "none" }}
								name={element.name}
								rules={[{ required: element.required, message: "" }]}
							></Form.Item>
							<span>
								{element.label}{" "}
								{!element.required && (
									<span style={{ opacity: "0.45" }}>(optional)</span>
								)}
							</span>
							<Tooltip
								title={
									disabled && disabledTooltipMessage
										? disabledTooltipMessage
										: ""
								}
							>
								<DatePicker
									disabled={disabled}
									style={{
										width: element.width ? `${element.width * 4}%` : "100%",
									}}
									// Set `formdate` as value
									value={formdate}
									// Display the format of `formdate` specified in the `element.format` props or else use "DD MM YYYY" if none
									format={element.format || "DD MMM YYYY"}
									onChange={
										// `date` represents the value during `onChange`
										(date: any, dateString: any) => {
											// Declare a `formdate` for storing the date during `onChange`
											formdate = null;
											// If `date` is occupied
											if (date) {
												formdate =
													// Convert the `date` to dayjs object
													dayjs(date)
														.startOf("day")
														.utc()
														.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
											}
											// Set the `fordate` data onto the field value by `element.name` in `formRef`
											formRef.setFieldValue(element.name, formdate);
											// Triggers a re-render of the component
											forceRerender(forceRender + 1);
											setFormReset(false);
										}
									}
								></DatePicker>
							</Tooltip>
						</div>
					</div>
				);
			case "radio":
				// formRef.setFieldValue(element.name, element.initialValue);
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						>
							<Radio.Group
								className="custom-radio-group"
								name={element.name}
								onChange={() => {
									setFormReset(false);
								}}
								style={{ width: "100%" }}
								disabled={disabled}
							>
								{element.options.map((option: any) => {
									return (
										<Radio.Button
											className="custom-radio-button"
											style={{
												width:
													element.options.length !== 0
														? `calc(100%/${element.options.length})`
														: "100%",
												textAlign: "center",
											}}
											value={option.value}
											type="default"
										>
											{option.label}
										</Radio.Button>
									);
								})}
							</Radio.Group>
						</Form.Item>
					</Tooltip>
				);
			case "latlong":
				const toggleEditMode = (field: string) => {
					setEditLatLong({
						...editLatLong,
						[field]: !editLatLong[field],
					});
				};

				return (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: `2%`,
						}}
					>
						<Tooltip
							title={
								disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
							}
						>
							<Form.Item
								label={"Latitude"}
								name={[element.name, 0]}
								rules={[{ required: element.required, message: "" }]}
								style={{ flexGrow: "1" }}
								tooltip={
									"Enter latitude in decimal degrees (e.g., 52.123). The value will automatically convert to degrees, minutes, and seconds (DMS) format."
								}
							>
								<InputNumber
									disabled={disabled}
									min={-90}
									max={90}
									onFocus={() => {
										// When focus, set editLatLong to true to allow editing
										toggleEditMode("latitude");
									}}
									onBlur={() => {
										// When blur, set editLatLong to false to disable editing
										if (editLatLong["latitude"]) {
											toggleEditMode("latitude");
										}
									}}
									onStep={(value: any) => {
										setFormReset(false);
									}}
									formatter={(value: any) => {
										// Continue working here
										// Change to DMS format if not in edit mode
										if (editLatLong["latitude"] === false) {
											if (value) {
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
												const dmsFormattedValue = convertToDMS(
													value,
													"latitude"
												);
												// setFormReset(false);
												return dmsFormattedValue;
											}
										}
										return value;
									}}
									parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
								/>
							</Form.Item>
						</Tooltip>{" "}
						<Tooltip
							title={
								disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
							}
						>
							<Form.Item
								label={"Longitude"}
								name={[element.name, 1]}
								rules={[{ required: element.required, message: "" }]}
								style={{ flexGrow: "1" }}
								tooltip={
									"Enter longitude in decimal degrees (e.g., 52.123). The value will automatically convert to degrees, minutes, and seconds (DMS) format."
								}
							>
								<InputNumber
									disabled={disabled}
									min={-180}
									max={180}
									onFocus={() => {
										// When focus, set editLatLong to true to allow editing
										toggleEditMode("longitude");
									}}
									onBlur={() => {
										// When blur, set editLatLong to false to disable editing
										if (editLatLong["longitude"]) {
											toggleEditMode("longitude");
										}
									}}
									onStep={(value: any) => {
										setFormReset(false);
									}}
									formatter={(value: any) => {
										// Change to DMS format if not in edit mode
										if (editLatLong["longitude"] === false) {
											if (value) {
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
												const dmsFormattedValue = convertToDMS(
													value,
													"longitude"
												);
												// setFormReset(false);
												return dmsFormattedValue;
											}
										}
										return value;
									}}
									parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
								/>
							</Form.Item>
						</Tooltip>
					</div>
				);
			case "colorPicker":
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
							initialValue={"#299BA3"}
						>
							<ColorPicker
								disabled={disabled}
								showText
								// defaultValue="#000000"
								onChange={() => {
									setFormReset(false);
								}}
								style={{
									display: "flex",
									justifyContent: "flex-start",
									width: element.width ? `${element.width * 4}%` : "100%",
								}}
							></ColorPicker>
						</Form.Item>
					</Tooltip>
				);
			case "grid":
				return (
					<div
						style={{
							display: "grid",
							gridTemplateColumns: element.gridTemplateColumns
								? element.gridTemplateColumns
								: `repeat(${element.children.length}, 1fr)`,
							gap: `${element.gap}`,
						}}
					>
						{element.children?.map((child: any, index: any) =>
							setInputType(child, index, true)
						)}
					</div>
				);
			case "flex":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: element.direction || "row",
							flexWrap: element.wrap || "wrap",
							gap: element.gap || "2%",
							// columnGap: element.columnGap || "20px",
							rowGap: element.rowGap || "20px",
						}}
					>
						{element.children?.map((child: any, index: any) => (
							<div
								style={{
									flexGrow: 1,
									flexBasis: child.flexBasis,
									width: child.width,
								}}
							>
								{setInputType(child, index)}
							</div>
						))}
					</div>
				);
			case "checkbox":
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							valuePropName="checked"
							rules={[
								{
									required: element.required,
									message: "",
									validator: (rule, value) => {
										if (value) {
											return Promise.resolve();
										} else {
											return Promise.reject("Please check the checkbox");
										}
									},
								},
							]}
						>
							<Checkbox
								disabled={disabled}
								onChange={() => setFormReset(false)}
							>
								{element.text}
							</Checkbox>
						</Form.Item>
					</Tooltip>
				);
			case "phone_number":
				const countryCode = uniqueCountryCodes
					.map((code: any) => {
						return {
							label: code,
							value: code,
						};
					})
					.sort((a: any, b: any) => {
						if (a.label.toLowerCase() < b.label.toLowerCase()) {
							return -1;
						}
						if (a.label.toLowerCase() > b.label.toLowerCase()) {
							return 1;
						}
						return 0;
					});
				return (
					<Tooltip
						title={
							disabled && disabledTooltipMessage ? disabledTooltipMessage : ""
						}
					>
						<Form.Item
							label={element.label}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						>
							<Input
								// autoFocus={index == 0 && !activeRowKey && !isChild}
								autoComplete="off"
								count={
									element.count && {
										show: true,
										max: element.count,
									}
								}
								addonBefore={
									<Form.Item name="country_code" noStyle>
										<Select
											getPopupContainer={(trigger: any) => trigger.parentNode}
											style={{ width: "80px" }}
											showSearch
											removeIcon
											options={countryCode}
											disabled={element.disabled}
										></Select>
									</Form.Item>
								}
								onChange={(event: any) => {
									const numericValue =
										event.target.value.replace(/\D/g, "") || null;

									formRef.setFieldsValue({
										[element.name]: numericValue,
									});
								}}
								style={{
									width: element.width ? `${element.width * 4}%` : "100%",
								}}
								disabled={disabled}
							></Input>
						</Form.Item>
					</Tooltip>
				);
			case "divider":
				return (
					<Divider style={{ margin: 0 }} key={element.label} orientation="left">
						{element.label}
					</Divider>
				);
			case "disabled":
				let fieldValue = formRef.getFieldValue(element.name) || "";
				return (
					<div style={{ gap: "8px", display: "flex", flexDirection: "column" }}>
						<Form.Item
							style={{ display: "none" }}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						></Form.Item>
						<span>{element.label}</span>
						<Input
							style={{
								width: element.width ? `${element.width * 4}%` : "100%",
							}}
							disabled
							value={fieldValue}
						></Input>
					</div>
				);
			case "button":
				let popoverMessage = element.popOverMessage;
				if (disabled) {
					popoverMessage = disabledTooltipMessage || "";
				}
				const checkDisable = () => {
					if (element.disableKey === "activeRowKey") {
						return !activeRowKey;
					} else {
						return true;
					}
				};
				return (
					<>
						{checkDisable() == true || disabled ? (
							<Popover
								content={popoverMessage}
								placement="bottomLeft"
								arrow={{ pointAtCenter: true }}
								className={
									checkDisable() == true
										? "disabled-custom-form-btn"
										: "custom-form-btn"
								}
							>
								<span
									style={{
										width: "max-content",
										cursor: "not-allowed",
										margin: element?.margin || 0,
									}}
								>
									{element.label}
								</span>
							</Popover>
						) : (
							!element.hidden && (
								<Button
									style={{
										display: "flex",
										padding: element?.padding || 0,
										width: "max-content",
										margin: element?.margin || 0,
										height: 0,
									}}
									onClick={async () => {
										if (element?.isNavigate) {
											try {
												await context.handlePageChange();
												element.function();
											} catch (error) {}
										} else {
											element.function();
										}
									}}
									type={element.buttonType}
								>
									{element.label}
								</Button>
							)
						)}
					</>
				);

			case "reorder-list":
				dataObject = dataOptions?.[element.name]?.[activeRowKey] ||
					dataOptions?.[element.name] || [
						...(activeRecord?.[element.name] ?? []),
					];

				const handleReorder = (data: any) => {
					// reorder the sequence_id
					data.forEach((item: any, index: number) => {
						item.sequence_id = index;
					});
					formRef.setFieldValue(element.name, data);
					setFormReset(false);
				};
				if (dataObject?.length > 0) {
					return (
						<div className="reorder-list">
							<div style={{ display: "flex", gap: "2px" }}>
								<span>{element.label}</span>
								{element.tooltip && (
									<Tooltip title={element.tooltip} placement="right">
										<span style={{ color: "rgba(255,255,255,0.45)" }}>
											{GetAntIcon("question3")}
										</span>
									</Tooltip>
								)}
							</div>
							<Form.Item
								style={{ display: "none" }}
								name={element.name}
								rules={[{ required: element.required, message: "" }]}
							></Form.Item>
							<ReorderList
								draggable={element.draggable}
								disabled={disabled}
								data={dataObject}
								itemKey={element.labelKey}
								onReorder={handleReorder}
							/>
						</div>
					);
				} else {
					return <></>;
				}

			case "boxlist":
				const dataList: any = element.data || [];

				const getColumn2 = (childData: any) => {
					const identifier = element.identifierColumn2.split(".");
					const grandChildList = childData[identifier[0]];

					return (
						<div className="boxlist-input">
							<ul
								style={{
									margin: "5px 0px",
									paddingTop: "5px",
									paddingBottom: "5px",
								}}
							>
								{grandChildList.map((item: any) => {
									return <li>{item[identifier[1]]}</li>;
								})}
							</ul>
						</div>
					);
				};

				return (
					<>
						{dataList.length > 0 && (
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `1fr 2fr`,
									gap: "8px",
									rowGap: "8px",
									margin: "10px 0px",
								}}
							>
								{element.columns.map((column: string) => {
									return (
										<div
											style={{
												margin: "5px 0px",
											}}
										>
											{column}
										</div>
									);
								})}
								{dataList.map((data: any) => {
									return (
										<>
											<div>
												<div
													style={{
														padding: "10px",
													}}
													className="boxlist-input"
												>
													{data[element.identifierColumn1]}
												</div>
											</div>
											{getColumn2(data)}
										</>
									);
								})}
							</div>
						)}
					</>
				);

			case "template-exclusion":
				//Dead code

				const switchDisabled =
					readOnly ||
					dataOptions?.[element.disabledKey] ||
					activeRecord?.[element.disabledKey];

				if (activeRecord?.[element.name]) {
					return (
						<div
							style={{
								background: "rgba(255, 255, 255, 0.08)",
								borderRadius: "3px",
								padding: "10px",
								gap: "10px",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
							}}
						>
							<div
								style={{
									gap: "5px",
									display: "flex",
									flexDirection: "row",
									alignItems: "flex-start",
								}}
							>
								<span>Include in programme?</span>
								<Tooltip
									title={
										switchDisabled && props.specialDisabledTooltipMessage
											? props.specialDisabledTooltipMessage
											: ""
									}
								>
									<Switch
										// checkedChildren="Included"
										// unCheckedChildren="Not included"
										checked={!activeRecord?.disabled}
										disabled={switchDisabled}
										onChange={(value: any) => {
											if (value === false) {
												Emitter.emit("templateDisableModalOpen", {
													id: activeRecord?._id,
													title: element?.modalTitle,
													message: element?.modalMessage,
													level: element?.level,
												});
											} else if (value === true) {
												Emitter.emit("templateForceSave", {
													id: activeRecord?._id,
													level: element?.level,
												});
											}
										}}
									/>
								</Tooltip>
							</div>
							{activeRecord?.disabled && activeRecord?.disabled_reason && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										alignSelf: "stretch",
									}}
								>
									<span>Comment</span>
									<div
										style={{
											borderRadius: "2px",
											border: "1px solid #434343",
											padding: "5px 0px",
											width: "100%",
										}}
									>
										<span style={{ padding: "0px 12px" }}>
											{activeRecord?.disabled_reason}
										</span>
									</div>
								</div>
							)}
						</div>
					);
				} else {
				}

				break;

			default:
				return <Input disabled></Input>;
		}
	};

	// Populate form with data from the form list
	const setForm = () => {
		if (formList) {
			return (
				<>
					<Form
						onValuesChange={(changedValues: any, allValues: any) => {}}
						layout={"vertical"}
						form={formRef}
						requiredMark="optional"
						style={{
							gap: "20px",
							display: "flex",
							flexDirection: "column",
							width: "100%",
							paddingBottom: "15px",
						}}
						// This function triggers every time the form’s input values change (e.g., when a user types or selects something)
						onChange={(values: any) => {
							setFormReset(false);
						}}
						// This function triggers when the form is submitted and passes validation
						onFinish={(values: any) => {}}
					>
						{formList?.map((element: any, index: any) =>
							setInputType(element, index)
						)}
					</Form>
				</>
			);
		} else {
			return null;
		}
	};

	return setForm();
};

export default CustomForm;
