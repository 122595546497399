import { CaretRightOutlined } from "@ant-design/icons";
import {
	Collapse,
	Divider,
	Modal,
	Progress,
	Space,
	Tooltip,
	Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { motion } from "framer-motion";
import { DragEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { HomeContext } from "../containers/Home";
import TaskReopenModal from "../containers/TaskReopenModal";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import { GetAntIcon } from "../utils/ant_icons";
import { excludedMark, statusIcon } from "../utils/statusIcon";
import { checkProjectTeam } from "../utils/utils";

const { Text } = Typography;

export const KanbanBoardDnD = (props: any) => {
	const [cards, setCards] = useState<any>([]);
	const [filteredCards, setFilteredCards] = useState<any>(null);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [reopenForm] = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [cancelPopover, setCancelPopover] = useState<any>(false);

	const resetForm = (values: any = null, reset: any = true) => {
		if (reopenForm) {
			setFormReset(true);
			reset && reopenForm.resetFields();
			reopenForm.setFieldsValue(values ? { ...values } : null);
		}
	};

	useEffect(() => {
		setCards(props.cardData);
	}, []);

	useEffect(() => {
		setCards(props.cardData);
	}, [props.cardData]);

	useEffect(() => {
		let filteredData: any = [];
		const { searchValue, selectedFilters } = props;
		// I can make this a function that passed as props from the parent component

		const check = Object.keys(selectedFilters).some(
			(filterKey: any) => selectedFilters[filterKey].length > 0
		);

		if (check || searchValue != "") {
			if (searchValue != "") {
				filteredData = cards?.filter(
					(task: any) =>
						task?.task_name
							?.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						task.task_number === searchValue
				);
			} else {
				filteredData = cards;
			}

			if (check) {
				filteredData = filteredData?.filter((task: any) =>
					Object.keys(selectedFilters).every((filterKey: any) => {
						if (selectedFilters[filterKey].length == 0) {
							return true;
						}
						if (typeof task[filterKey] == "object") {
							return task[filterKey].some((key: any) =>
								selectedFilters[filterKey].includes(key)
							);
						} else {
							return selectedFilters[filterKey].includes(task[filterKey]);
						}
					})
				);
			}
		} else {
			filteredData = null;
		}

		setFilteredCards(filteredData);
	}, [props.searchValue, props.selectedFilters, cards]);

	return (
		<div
			className="kanban-columns-container"
			style={{
				display: "flex",
				flex: 1,
				alignItems: "flex-start",
				padding: "30px",
				gap: "25px",
				alignSelf: "stretch",
				overflow: "auto",
			}}
		>
			{props.columnList.map((column: any) => {
				return (
					<Column
						column={column}
						cards={filteredCards != null ? filteredCards : cards}
						headerList={props.cardHeader}
						// progressCount={props.progressCount}
						setCards={setCards}
						allExpandList={props.allExpandList}
						updateCardStatus={props.updateCardStatus}
						setBlockedModalOpen={props.setBlockedModalOpen}
						setActiveKey={props.setActiveKey}
						isSaved={props.isSaved}
						isCanceled={props.isCanceled}
					/>
				);
			})}
		</div>
	);
};

const Column = ({
	column,
	cards,
	headerList,
	// progressCount,
	setCards,
	allExpandList,
	updateCardStatus,
	setBlockedModalOpen,
	setActiveKey,
	isSaved,
	isCanceled,
}: any) => {
	const [active, setActive] = useState(false);
	const [textHighlight, setTextHighlight] = useState(false);
	const [reopenModalOpen, setReopenModalOpen] = useState<boolean>(false);
	const [confirmCloseTaskModalOpen, setConfirmCloseTaskModalOpen] =
		useState<boolean>(false);
	const [activeCard, setActiveCard] = useState<any>(null);

	const context: any = useContext(HomeContext);

	useEffect(() => {
		const checkTextHighlight = () => {
			const selection = window.getSelection();
			if (selection?.toString() !== "") {
				setTextHighlight(true);
			} else {
				setTextHighlight(false);
			}
		};
		document.addEventListener("mouseup", checkTextHighlight);
		document.addEventListener("mousedown", checkTextHighlight);
		return () => {
			document.removeEventListener("mouseup", checkTextHighlight);
			document.removeEventListener("mousedown", checkTextHighlight);
		};
	}, []);

	const filteredCards = cards?.filter((card: any) => {
		if (column.columnName === "Closed") {
			return card.closed === true;
		} else {
			if (card.closed !== true) return card.status === column.status;
		}
	});

	// Dragging events
	const handleDragStart = (e: DragEvent, card: any) => {
		e.dataTransfer.setData("cardId", card._id);
	};

	const handleDrop = (e: DragEvent) => {
		const cardId = e.dataTransfer.getData("cardId");

		// remove the card from the previous column and add it to this column
		let copy = [...cards];
		let card = copy.find((card: any) => card._id === cardId);

		if (!card || textHighlight) {
			setActive(false);
			return;
		}

		if (context.currentProject && column.status == "Closed") {
			const _responsibleParty = context.currentProject?.role_assignments.filter(
				(role: any) => card.responsible_roles.includes(role.role_id)
			);

			if (
				!(
					checkProjectTeam(context.userInfo.user.email, _responsibleParty) ||
					context.userInfo.user.email.toLowerCase() ==
						context.currentProject?.project_manager?.toLowerCase()
				)
			) {
				setActive(false);
				setActiveKey(cardId);
				return;
			}
		}
		if (column.status !== "Closed" && card.closed === true) {
			setActiveCard(card);
			setReopenModalOpen(true);
			setActive(false);
			setActiveKey(cardId);
			return;
		}
		if (column.status === "Closed" && card.closed !== true) {
			setActiveCard(card);
			setConfirmCloseTaskModalOpen(true);
			setActive(false);
			setActiveKey(cardId);
			return;
		}
		if (column.status === "Late" && card.status !== "Late") {
			setBlockedModalOpen(true);
			setActive(false);
			setActiveKey(cardId);
			return;
		}

		if (column.status === card.status) {
			setActive(false);
			return;
		}
		let newCard = { ...card, status: column.status };
		setActive(false);
		if (newCard.status !== "Late") {
			newCard.blocker = null;
			newCard.plan = null;
		}
		// update the card status in frontend
		setCards(
			copy
				.filter((c: any) => c._id !== cardId)
				.concat(newCard)
				.sort((a: any, b: any) => {
					if ("sequence_id" in a && "sequence_id" in b) {
						if (a.sequence_id && b.sequence_id) {
							return b.sequence_id - a.sequence_id;
						}
						return 0;
					}
					return 0;
				})
		);
		updateCardStatus(cardId, newCard, card); // update the card status in the backend
	};

	const handleDragOver = (e: DragEvent) => {
		e.preventDefault();
		setActive(true);
	};

	const handleDragLeave = (e: DragEvent) => {
		e.preventDefault();
		setActive(false);
	};

	const filterExpandList = (list: any, cardId: string) => {
		return list?.filter((item: any) => item.task_id === cardId) || [];
	};

	const handleReopenTask = (reason: string) => {
		if (activeCard) {
			const updatedCard = { ...activeCard, status: column.status };

			// Update the frontend state
			setCards((prevCards: any[]) =>
				prevCards
					.filter((c: any) => c._id !== activeCard._id)
					.concat(updatedCard)
					.sort((a: any, b: any) => {
						if ("sequence_id" in a && "sequence_id" in b) {
							return a.sequence_id - b.sequence_id;
						}
						return 0;
					})
			);

			// Call the backend to update the status
			updatedCard.closed = false;
			updatedCard.reopen_reason = reason;
			updateCardStatus(activeCard._id, updatedCard, activeCard);

			// Reset the state
			setActiveCard(null);
			setConfirmCloseTaskModalOpen(false);

			if (updatedCard.status == "Late") {
				setBlockedModalOpen(true);
			}
		} else {
			console.log("No active card");
		}
	};

	return (
		<>
			<div className="project-status-container">
				<div className="project-status-container-header">
					{GetAntIcon("square-check")}
					{column.columnName}
				</div>
				<div
					onDrop={
						textHighlight || column.droppable === false ? undefined : handleDrop
					}
					onDragOver={
						textHighlight || column.droppable === false
							? undefined
							: handleDragOver
					}
					onDragLeave={handleDragLeave}
					className="project-status-container-content"
					// style={{
					// 	cursor: isCardDraggable() ? "default" : "not-allowed",
					// 	backgroundColor: active ? "rgba(3, 40, 43, 0.4)" : "",
					// }}
					style={active ? { backgroundColor: "rgba(3, 40, 43, 0.4)" } : {}}
				>
					{filteredCards
						.sort((a: any, b: any) => {
							if ("sequence_id" in a && "sequence_id" in b) {
								// if (a.sequence_id && b.sequence_id) {
								return a.sequence_id - b.sequence_id;
								// }
								// return 0;
							}
							return 0;
						})
						.map((card: any) => {
							return (
								<Card
									key={card._id}
									card={card}
									column={column}
									headerList={headerList}
									handleDragStart={handleDragStart}
									expandList={filterExpandList(allExpandList, card._id)}
									confirmCloseTaskModalOpen={confirmCloseTaskModalOpen}
									setConfirmCloseTaskModalOpen={setConfirmCloseTaskModalOpen}
								/>
							);
						})}
				</div>
			</div>

			{confirmCloseTaskModalOpen && (
				<Modal
					closable={false}
					width={"40%"}
					title="Close task?"
					open={confirmCloseTaskModalOpen}
					centered
					destroyOnClose
					okText={"Close task"}
					onCancel={() => {
						setConfirmCloseTaskModalOpen(false);
					}}
					onOk={() => {
						if (activeCard) {
							const updatedCard = {
								...activeCard,
								closed: true,
							};

							// Update the frontend state
							setCards((prevCards: any[]) =>
								prevCards
									.filter((c: any) => c._id !== activeCard._id)
									.concat(updatedCard)
									.sort((a: any, b: any) => {
										if ("sequence_id" in a && "sequence_id" in b) {
											return a.sequence_id - b.sequence_id;
										}
										return 0;
									})
							);

							// Call the backend to update the status
							console.log(activeCard);
							console.log("update data", updatedCard);
							updateCardStatus(activeCard._id, updatedCard, activeCard);

							// Reset the state
							setActiveCard(null);
							setConfirmCloseTaskModalOpen(false);
						}
					}}
				>
					<Text>
						Closing this task will update its status to "Closed". You may still
						reopen the task if needed. Do you wish to proceed?
					</Text>
				</Modal>
			)}

			{reopenModalOpen && (
				<TaskReopenModal
					modalOpen={reopenModalOpen}
					setModalOpen={setReopenModalOpen}
					handleReopenTask={handleReopenTask}
					taskInfo={activeCard}
				/>
			)}
		</>
	);
};

const Card = ({
	card,
	column,
	headerList,
	handleDragStart,
	expandList,
	confirmCloseTaskModalOpen,
	setConfirmCloseTaskModalOpen,
}: any) => {
	const [percent, setPercent] = useState(card.progress);
	const [responsibleParty, setResponsibleParty] = useState<any>([]);
	const navigate = useNavigate();

	const { handleContextMenu } = useHandleContextMenu();
	const context: any = useContext(HomeContext);

	// useEffect(() => {
	// 	console.log("debug", confirmCloseTaskModalOpen);
	// }, [confirmCloseTaskModalOpen]);

	// Get the card name which is the combination of the header list
	const cardName = () => {
		let name: string[] = [];
		headerList.forEach((head: any) => {
			name.push(card[head]);
		});
		return name.join(" ");
	};

	const displayExpandList = (list: any) => {
		return list.map((item: any) => {
			let tooltipMessage: any = "";
			if (item.disabled && item.disabled_reason) {
				tooltipMessage = (
					<span>
						This deliverable is excluded from the well programme. You may still
						click to view the details. <br />
						<br /> <b>Comment:</b>
						<br />
						{item?.disabled_reason}
					</span>
				);
			}

			return (
				<Tooltip title={tooltipMessage}>
					<div
						className={
							item.disabled
								? "deliverable-item-disabled"
								: "project-title-clickable"
						}
						onClick={() => {
							navigate(`${card._id}`, {
								replace: false,
								state: { task_id: card._id, deliverable_id: item._id },
							});
						}}
						// onContextMenu={(e: any) => {
						// 	const location = `${window.location.href}/${card._id}`;
						// 	handleContextMenu(e, { link: location });
						// }}
						style={{
							display: "flex",
							gap: "10px",
							margin: "5px 0px",
							cursor: "pointer",
						}}
						// className="project-title-clickable"
					>
						{item.disabled && excludedMark()} {statusIcon(item.status)}{" "}
						{item.deliverable_name}
					</div>
				</Tooltip>
			);
		});
	};

	useEffect(() => {
		if (context.currentProject) {
			const _responsibleParty = context.currentProject?.role_assignments.filter(
				(role: any) => card.responsible_roles.includes(role.role_id)
			);
			setResponsibleParty(_responsibleParty);
		}
	}, [context.currentProject]);

	const isCardDraggable = (card: any) => {
		if (card?.closed) {
			return (
				checkProjectTeam(context.userInfo.user.email, responsibleParty) ||
				context.userInfo.user.email.toLowerCase() ==
					context.currentProject?.project_manager?.toLowerCase()
			);
		}
		return true;
	};

	return (
		<>
			<motion.div
				layout
				layoutId={card._id}
				draggable={
					card?.disabled || column.droppable === false || !isCardDraggable(card)
						? false
						: true
				}
				className={
					!card.disabled ? "project-card" : "project-card task-card-disabled"
				}
				onDragStart={(e) => handleDragStart(e, card)}
				style={{ cursor: "default" }} // temporary update, remove when drag and drop is ready
			>
				<Space
					key={card._id}
					className="project-card-container"
					styles={{ item: { width: "100%" } }}
				>
					<a
						onClick={() => {
							navigate(`${card._id}`, {
								replace: false,
								state: { task_id: card._id },
							});
						}}
						onContextMenu={(e: any) => {
							const location = `${window.location.href}/${card._id}`;
							handleContextMenu(e, { link: location });
						}}
					>
						<Typography.Text
							title=""
							className={"project-title-clickable"}
							style={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
							ellipsis={{
								tooltip: <span>{cardName()}</span>,
							}}
						>
							<span style={{ display: "flex", gap: "5px" }}>
								{card.disabled && excludedMark()}
								{cardName()}
							</span>
						</Typography.Text>
					</a>

					{expandList.length !== 0 && (
						<>
							<Progress
								style={{ width: "100%" }}
								percent={Math.round(card.progress)}
								format={() => {
									// count the number of deliverables that is completed
									const totalCompleted = expandList.filter(
										(item: any) => item.status === "Resolved"
									).length;
									return `${totalCompleted}/${expandList.length}`;
								}}
							/>
							<Collapse
								ghost
								expandIcon={({ isActive }) => (
									<div
										style={{
											display: "flex",
											gap: "5px",
											flex: "auto",
											width: "100%",
										}}
									>
										<CaretRightOutlined rotate={isActive ? 90 : 0} />
										<Divider />
									</div>
								)}
								expandIconPosition="end"
								items={[
									{
										key: "1",
										label: "Deliverables",
										children: <div>{displayExpandList(expandList)}</div>,
									},
								]}
							/>
						</>
					)}
				</Space>
			</motion.div>

			{confirmCloseTaskModalOpen && (
				<Modal
					closable={false}
					width={"40%"}
					title="Close task?"
					open={confirmCloseTaskModalOpen}
					centered
					destroyOnClose
					okText={"Close task"}
					onCancel={() => {
						setConfirmCloseTaskModalOpen(false);
					}}
					onOk={() => {
						// update
						console.log("debug", card);
					}}
				>
					<Text>
						Closing this task will update its status to "Closed". You may still
						reopen the task if needed. Do you wish to proceed?
					</Text>
				</Modal>
			)}
		</>
	);
};
