import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../containers/Home";
import { getProject } from "../services/api-server/projects";

function usePermission(permission: string, currentProjectCode: string | null = null): boolean {
    const context: any = useContext(HomeContext);
    const userInfo: any = context?.userInfo;

    const [role, setRole] = useState<string | null>(null);
    const [rolePermissions, setRolePermissions] = useState<any[]>([]);

    useEffect(() => {
        const fetchProjectAndSetRole = async () => {
            if (!userInfo) return;

            const USER_EMAIL = userInfo.user.email.toLowerCase();
            let ROLE = "general_user";

            if (userInfo.userRole.includes("tenant-admin")) {
                ROLE = "tenant_admin";
            } else if (userInfo.userRole.includes("tenant-members")) {
                ROLE = "tenant_members";
            } else if (currentProjectCode) {
                try {
                    const project: any = await getProject(context.currentTenantKey, currentProjectCode);
                    const PROJECT_MANAGER = project?.project_manager?.toLowerCase() || "";
                    const TEAM_MEMBERS: Array<string> = Array.from(
                        new Set(
                            project?.role_assignments?.flatMap((role: any) =>
                                role.appointee.map((email: string) => email.toLowerCase())
                            )
                        )
                    ) || [];
                    if (USER_EMAIL === PROJECT_MANAGER) {
                        ROLE = "project_manager";
                    } else if (TEAM_MEMBERS.includes(USER_EMAIL)) {
                        ROLE = "team_members";
                    }
                } catch (error) {
                    console.error("Failed to get project", error);
                }
            }
            setRole(ROLE);
            setRolePermissions(context.allData?.role_permissions || []);
        };

        fetchProjectAndSetRole();
    }, [context.currentTenantKey, currentProjectCode, userInfo, context.allData]);

    const calculatePermission = (): boolean => {
        if (userInfo?.userRole?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
            return true; // Super admin has all permissions
        }
        if (role && rolePermissions.length > 0) {
            const _role = rolePermissions.find((roleItem: any) => roleItem.key === role);
            return _role?.permissions?.includes(permission) || false;
        }
        return false;
    };

    return calculatePermission();
}

export default usePermission;
