/**
 * Terra's Login Page with Microsoft sign in
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import elementalLogo from "../assets/image/E-Logo_White.svg";
import terraLogo from "../assets/image/TerraLogo_Login.svg";
import {
	Layout,
	Button,
	Input,
	theme,
	Space,
	Form,
	Image,
	Divider,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import microsoftLogo from "../assets/image/Microsoft_logo.svg";
// import IluvatarLogo from "../assets/image/Iluvatar_loginlogo.png";
import { GetAntIcon, GetFileType } from "../utils/ant_icons";
import { url } from "../utils/_exports";
const { Content } = Layout;

const LoginPage = (props: any) => {
	const navigate = useNavigate();
	// When form is finished (Temp unavailable)

	useEffect(() => {
		if (
			localStorage.getItem(`idToken`) &&
			localStorage.getItem(`idToken`) !== undefined
		) {
			navigate(`/dashboard`, { replace: true });
		}
	});

	const onFinish = (values: any) => {
		if (values.username == "admin" && values.password == "admin") {
			localStorage.setItem("isLogin", "true");
			window.location.href = "/dashboard";
		}
	};

	// Login Via Microsoft EntraID
	const MicrosoftSignIn = () => {
		window.location.href = `${url}/login`;
	};

	return (
		<Layout className="login-body">
			<Content>
				<div className="container">
					<div className="left-container">
						<div className="container-center">
						</div>
					</div>
					<div className="right-container">
						<Space className="login-container" direction="vertical" size={50}>
							<Space
								style={{ display: "flex", alignItems: "center" }}
								className="login-header-container"
								direction="vertical"
							>
								<Image
									src={elementalLogo}
									preview={false}
									style={{ width: "275px" }}
								></Image>
								<span
									style={{
										fontSize: "13px",
										fontWeight: "275",
										lineHeight: "22px",
									}}
								>
									Engineering the Energy Transition
								</span>
							</Space>
							<Form
								name="login_form"
								className="login-form"
								onFinish={onFinish}
							>
								<Form.Item style={{ marginBottom: "0px" }}>
									{" "}
									<Button
										type="primary"
										htmlType="submit"
										onClick={MicrosoftSignIn}
										className="login-form-button normal-login-button"
									>
										Sign in via Elemental account
									</Button>
								</Form.Item>
								<Divider>Or</Divider>
								<Form.Item>
									<Link to="/">
										<Button
											style={{ display: "flex", justifyContent: "center" }}
											icon={
												<>
													{" "}
													<Image
														preview={false}
														height="20px"
														width="20px"
														src={microsoftLogo}
													/>
												</>
											}
											type="primary"
											onClick={MicrosoftSignIn}
											className="microsoft-login-button login-form-button ant-btn-secondary"
										>
											Sign in with Microsoft
										</Button>
									</Link>
								</Form.Item>
								<span
									style={{
										fontSize: "10px",
										lineHeight: "22px",
										fontWeight: "400",
										color: "#FFFFFF75",
									}}
								>
									Having problems?{" "}
									<a style={{ color: "#299BA3" }}>Contact us</a>
								</span>
							</Form>
						</Space>
						<div className="login-container-footer">
							<span style={{ fontSize: "14px", lineHeight: "22px" }}>
								Powered by
							</span>
							<Image
								preview={false}
								height="60px"
								width="150px"
								src={terraLogo}
							/>
						</div>
					</div>
				</div>
			</Content>
		</Layout>
	);
};

LoginPage.propTypes = {};

export default LoginPage;
