import React, { useState, useEffect, useRef, useContext } from "react";
import {
	Button,
	Card,
	Divider,
	Flex,
	Input,
	Popconfirm,
	Space,
	Tabs,
} from "antd";
import { GetAntIcon } from "../../utils/ant_icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonCancel from "../../components/FormButtonCancel";
import { useForm } from "antd/es/form/Form";
import FormButtonSave from "../../components/FormButtonSave";
import CustomForm from "../../components/CustomForm";
import well_delivered_svg from "../../assets/image/'e' outline well-delivered.png";
import well_secured_svg from "../../assets/image/'e' outline well-secured.svg";
import disableIcon from "../../assets/image/disable 'e'.png";
import { useNavigate } from "react-router";
import Emitter from "../../utils/emitter";
import { SearchOutlined } from "@ant-design/icons";
import { sendEmail } from "../../services/api-server/email";
import { HomeContext } from "../Home";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";
const WellDeliveredIntroduction = require("./WellDeliveredIntroduction.md");
const WellDeliveredDashboard = require("./WellDeliveredDashboard.md");
const WellDeliveredProjects = require("./WellDeliveredProjects.md");
const WellDeliveredUpdatesv1_0 = require("./WellDeliveredUpdatesv1.0.md");
const WellDeliveredUpdatesv1_1 = require("./WellDeliveredUpdatesv1.1.md");
const WellDeliveredUpdatesv1_1_1 = require("./WellDeliveredUpdatesv1.1.1.md");
const WellDeliveredUpdatesv1_2_0 = require("./WellDeliveredUpdatesv1.2.0.md");
const WellDeliveredUpdatesv1_2_1 = require("./WellDeliveredUpdatesv1.2.1.md");
const WellDeliveredUpdatesv1_2_2 = require("./WellDeliveredUpdatesv1.2.2.md");
const WellDeliveredUpdatesv1_3_0 = require("./WellDeliveredUpdatesv1.3.0.md");
const WellDeliveredUpdatesv1_3_1 = require("./WellDeliveredUpdatesv1.3.1.md");
const WellDeliveredUpdatesv1_4_0 = require("./WellDeliveredUpdatesv1.4.0.md");
const WellDeliveredUpdatesv1_4_1 = require("./WellDeliveredUpdatesv1.4.1.md");
const WellDeliveredUpdatesv1_4_2 = require("./WellDeliveredUpdatesv1.4.2.md");
const WellDeliveredUpdatesv1_4_3 = require("./WellDeliveredUpdatesv1.4.3.md");
const WellDeliveredUpdatesv1_5_0 = require("./WellDeliveredUpdatesv1.5.0.md");
const WellDeliveredUpdatesv1_5_1 = require("./WellDeliveredUpdatesv1.5.1.md");
const WellDeliveredUpdatesv2_0_0 = require("./WellDeliveredUpdatesv2.0.0.md");
const WellDeliveredUpdatesv2_0_1 = require("./WellDeliveredUpdatesv2.0.1.md");
const WellDeliveredUpdatesv2_1_0 = require("./WellDeliveredUpdatesv2.1.0.md");
const WellDeliveredUpdatesv2_2_0 = require("./WellDeliveredUpdatesv2.2.0.md");
const WellDeliveredUpdatesv2_3_0 = require("./WellDeliveredUpdatesv2.3.0.md");
// const OurOffice = require("./WellDeliveredUpdatesv2.0.1.md");
// const SendAMessage = require("./WellDeliveredUpdatesv2.1.0.md");
const defaultMarkdownFile = require("./WellDeliveredNoContent.md");

const help_svg = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="96"
		height="96"
		viewBox="0 0 96 96"
		fill="none"
	>
		<g clipPath="url(#clip0_5561_221906)">
			<path
				d="M47.8377 92.6994C23.0177 92.6894 2.90773 72.5594 2.91773 47.7394C2.91773 45.5994 3.06773 43.4594 3.37773 41.3394H0.527734C-6.45227 90.1794 57.5877 116.689 87.3977 74.5494L84.7677 73.3594C76.3777 85.4794 62.5777 92.7094 47.8377 92.6994Z"
				fill="currentColor"
			/>
			<path
				d="M8.23779 21.0994L10.8478 22.2794C24.9378 1.85936 52.9178 -3.28064 73.3378 10.8094C87.4178 20.5194 94.7578 37.3894 92.2778 54.3094H95.1078C102.018 6.02936 38.4178 -21.5506 8.23779 21.0994Z"
				fill="currentColor"
			/>
			<path
				d="M63.5124 32.5256C62.5671 30.4595 61.2235 28.6028 59.5086 27.0161C56.0246 23.782 51.3996 21.9995 46.4978 21.9995C41.596 21.9995 36.971 23.782 33.487 27.0094C31.7721 28.6028 30.4285 30.4528 29.4832 32.5256C28.4974 34.6862 27.9978 36.9751 27.9978 39.3382V41.1612C27.9978 41.5798 28.3354 41.9174 28.754 41.9174H32.4C32.8186 41.9174 33.1562 41.5798 33.1562 41.1612V39.3382C33.1562 32.6201 39.1383 27.1579 46.4978 27.1579C53.8573 27.1579 59.8394 32.6201 59.8394 39.3382C59.8394 42.0929 58.8604 44.6856 57.0036 46.8462C55.1671 48.9866 52.5744 50.5192 49.7049 51.1674C48.0642 51.5387 46.5856 52.4637 45.539 53.7871C44.4962 55.1059 43.9277 56.7375 43.9254 58.4189V60.5389C43.9254 60.9575 44.2629 61.2951 44.6816 61.2951H48.3275C48.7462 61.2951 49.0838 60.9575 49.0838 60.5389V58.4189C49.0838 57.3588 49.8197 56.4271 50.8392 56.1975C54.7823 55.3063 58.3608 53.1794 60.9197 50.2086C62.2093 48.703 63.2153 47.0285 63.9108 45.2123C64.6332 43.3285 64.9978 41.3502 64.9978 39.3382C64.9978 36.9751 64.4982 34.6794 63.5124 32.5256ZM46.4978 66.6966C44.4115 66.6966 42.7168 68.3913 42.7168 70.4776C42.7168 72.5639 44.4115 74.2586 46.4978 74.2586C48.5841 74.2586 50.2788 72.5639 50.2788 70.4776C50.2788 68.3913 48.5841 66.6966 46.4978 66.6966Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5561_221906">
				<rect width="95.63" height="95.56" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
);

export const well_delivered_contents = [
	{
		key: "introduction",
		label: "Introduction",
		markdownContent: WellDeliveredIntroduction,
	},
	{
		key: "dashboard",
		label: "Dashboard",
		markdownContent: WellDeliveredDashboard,
	},
	{
		key: "projects",
		label: "Projects",
		markdownContent: WellDeliveredProjects,
	},
	{
		key: "profile",
		label: "Profile",
		markdownContent: defaultMarkdownFile,
	},
	{
		key: "troubleshooting-faqs",
		label: "Troubleshooting & FAQs",
		markdownContent: defaultMarkdownFile,
	},
	{
		key: "updates-new-features",
		label: "Updates & new features",
		descendants: [
			{
				key: "v2.3.0",
				label: "Version 2.3.0",
				markdownContent: WellDeliveredUpdatesv2_3_0,
			},
			{
				key: "v2.2.0",
				label: "Version 2.2.0",
				markdownContent: WellDeliveredUpdatesv2_2_0,
			},
			{
				key: "v2.1.0",
				label: "Version 2.1.0",
				markdownContent: WellDeliveredUpdatesv2_1_0,
			},
			{
				key: "v2.0.1",
				label: "Version 2.0.1",
				markdownContent: WellDeliveredUpdatesv2_0_1,
			},
			{
				key: "v2.0.0",
				label: "Version 2.0.0",
				markdownContent: WellDeliveredUpdatesv2_0_0,
			},
			{
				key: "v1.5.1",
				label: "Version 1.5.1",
				markdownContent: WellDeliveredUpdatesv1_5_1,
			},
			{
				key: "v1.5.0",
				label: "Version 1.5.0",
				markdownContent: WellDeliveredUpdatesv1_5_0,
			},
			{
				key: "v1.4.3",
				label: "Version 1.4.3",
				markdownContent: WellDeliveredUpdatesv1_4_3,
			},
			{
				key: "v1.4.2",
				label: "Version 1.4.2",
				markdownContent: WellDeliveredUpdatesv1_4_2,
			},
			{
				key: "v1.4.1",
				label: "Version 1.4.1",
				markdownContent: WellDeliveredUpdatesv1_4_1,
			},
			{
				key: "v1.4.0",
				label: "Version 1.4.0",
				markdownContent: WellDeliveredUpdatesv1_4_0,
			},
			{
				key: "v1.3.1",
				label: "Version 1.3.1",
				markdownContent: WellDeliveredUpdatesv1_3_1,
			},
			{
				key: "v1.3.0",
				label: "Version 1.3.0",
				markdownContent: WellDeliveredUpdatesv1_3_0,
			},
			{
				key: "v1.2.2",
				label: "Version 1.2.2",
				markdownContent: WellDeliveredUpdatesv1_2_2,
			},
			{
				key: "v1.2.1",
				label: "Version 1.2.1",
				markdownContent: WellDeliveredUpdatesv1_2_1,
			},
			{
				key: "v1.2.0",
				label: "Version 1.2.0",
				markdownContent: WellDeliveredUpdatesv1_2_0,
			},
			{
				key: "v1.1.1",
				label: "Version 1.1.1",
				markdownContent: WellDeliveredUpdatesv1_1_1,
			},
			{
				key: "v1.1",
				label: "Version 1.1.0",
				markdownContent: WellDeliveredUpdatesv1_1,
			},
			{
				key: "v1.0",
				label: "Version 1.0.0",
				markdownContent: WellDeliveredUpdatesv1_0,
			},
		],
	},
];

const featured_topics = [
	{
		label: "Getting started",
		key: "getting-started",
		icon: "play",
	},
	// {
	// 	label: "Roles & permissions",
	// 	key: "roles-permissions",
	// 	icon: "unlock",
	// },
	{
		label: "Troubleshooting & FAQs",
		key: "troubleshooting-faqs",
		icon: "question3",
	},
	{
		label: "Updates & new features",
		key: "updates-new-features",
		icon: "star",
	},
	// {
	// 	label: "Security & privacy",
	// 	key: "security-privacy",
	// 	icon: "security",
	// },
];

export const products = [
	{
		key: "well-delivered",
		label: "Well Delivered",
		propTitle: "Well Delivered",
		image: well_delivered_svg,
		contents: well_delivered_contents,
	},
	{
		key: "well-secured",
		label: "Well Performed",
		propTitle: "Well Performed",
		image: well_secured_svg,
		disable: true,
	},
];

const HelpCenter = (props: any) => {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [sideContainerOpen, setSideContainerOpen] = useState(true);
	const [wellDeliveredContents, setWellDeliveredContents] = useState<any>(
		well_delivered_contents
	);
	const initialForm = useRef(null);
	const { handleContextMenu } = useHandleContextMenu();

	useEffect(() => {
		let initialFormData: any = {
			name: props.params.user.name,
			email: props.params.user.email,
			country_code: props.params.user.country_code,
			phone_number: props.params.user.phone_number,
		};
		initialForm.current = initialFormData;
		formRef.setFieldsValue(initialForm.current);

		// fetch(markdownFile)
		// 	.then((res) => res.text())
		// 	.then((text) => setMarkdownContent(text))
		// 	.catch((error) => {
		//
		// 	});
	});

	const handleFormCancel = () => {
		//
		formRef.resetFields();
		formRef.setFieldsValue(initialForm.current);
	};

	const handleFormSave = () => {
		const data = formRef.getFieldsValue();
		// Add current time in utc into data
		data["time"] = new Date().toUTCString();
		Emitter.emit("loading", null);
		sendEmail(data)
			.then((res) => {
				Emitter.emit("alert", {
					type: "success",
					message: `Request successfully submitted.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				formRef.resetFields();
				formRef.setFieldsValue(initialForm.current);
			})
			.catch((err) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Request submission failed. Try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				// formRef.resetFields();
				formRef.setFieldsValue(initialForm.current);
			})
			.finally(() => {
				Emitter.emit("finish-loading", null);
			});
	};

	// Side Container Contents
	const sideContainer = () => {
		return (
			<div
				className={`help-center-side-container ${
					sideContainerOpen ? "show-modal" : "hide-modal"
				}`}
			>
				<div className="side-container">
					{/* Side Container Header */}
					<div className="generic-header">
						{/* Side Container Title */}
						<div>Submit a request</div>
						{/* Close Button */}
						<Button
							className="side-container-close-button"
							type="link"
							onClick={() => {
								//
								setSideContainerOpen(false);
							}}
						>
							Close
						</Button>
					</div>
					{/* Side Container Body */}
					<div className="generic-content">
						<Space direction="vertical" style={{ width: "100%", gap: "15px" }}>
							<div style={{ padding: "20px 0px" }}>
								{/* Contact support team Link */}
								{/* <Button
									className="ant-btn-link"
									type="link"
									style={{
										paddingLeft: "0px",
										paddingRight: "0px"
									}}
									onClick={async () => {
										navigate("/help-center/contact-support-team");
									}}
									>
									{GetAntIcon("form")}
									Contact support team
								</Button> */}
								<CustomForm
									setFormReset={setFormReset}
									// Sets the tabKey prop to "help-center", to identify this form or tab uniquely
									tabKey={"help-center"}
									formRef={formRef}
								/>
							</div>
						</Space>
					</div>
					<div className="generic-footer">
						{/* Cancel */}
						<FormButtonCancel
							form={formRef}
							handleFormCancel={handleFormCancel}
							formReset={formReset}
						/>
						{/* Submit */}
						<FormButtonSave
							form={formRef}
							handleFormSave={handleFormSave}
							formReset={formReset}
							labelText="Submit"
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div
				className={`generic-container help-center ${
					sideContainerOpen ? "show-modal" : "hide-modal"
				}`}
			>
				{/* Side Container Button */}
				<Button
					className={`side-container-button ${
						sideContainerOpen && "show-modal"
					}`}
					type="primary"
					onClick={() => {
						//
						setSideContainerOpen(true);
					}}
				>
					{GetAntIcon("form")}
				</Button>

				{/* Help Center Body */}
				<div className="help-center-generic-content">
					<Flex className="help-center-header" gap={30} align="center">
						{help_svg}
						<div>How may we help you?</div>
					</Flex>
					{/* Search Input */}
					<Input
						placeholder="Search"
						className="input-clearable search-input"
						allowClear
						prefix={<SearchOutlined />}
					/>
					{/* Featured topics Divider */}
					<Divider orientation="left">Featured topics</Divider>
					{/* Featured topics contents */}
					<div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
						{featured_topics.map((topic: any) => {
							return (
								<Button
									className="ant-btn-secondary"
									icon={GetAntIcon(topic.icon)}
									style={{
										borderRadius: "2px",
									}}
									ghost
								>
									{topic.label}
								</Button>
							);
						})}
					</div>
					{/* Products Divider */}
					<Divider
						style={{ margin: 0, color: "rgba(125, 125, 125, 1)" }}
						orientation="left"
					>
						Products
					</Divider>
					{/* Products contents */}
					<div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
						{products.map((product: any) => {
							return (
								<Button
									className="ant-btn-secondary"
									style={{
										background: "rgba(20, 20, 20, 0.50)",
										width: "194px",
										height: "145px",
										border: "1px solid #434343",
									}}
									onClick={async () => {
										try {
											// It calls a method handlePageChange() from the context (most likely from a global state or context provider) and waits for it to finish before continuing.
											await context.handlePageChange();
											// This emits an event called "setBreadcrumb" with product.key to update the breadcrumb (for navigation tracking).
											Emitter.emit("setBreadcrumb", product.key);
											navigate(`/help-center/${product.key}`, {
												replace: true, // Replaces the current page in the browser history, so the user cannot go back to the previous page with the back button.
												state: { ...product }, // Passes the product object as state to the new page, so it can be accessed there.
											});
										} catch (err) {}
									}}
									onContextMenu={(e: any) => {
										const location = `${window.location.href}/${product.key}`;
										!product.disable &&
											handleContextMenu(e, { link: location });
									}}
									// This disables the button if product.disable is true. When the button is disabled, it can’t be clicked or interacted with.
									disabled={product.disable}
								>
									<Space direction="vertical">
										<img
											src={product.disable ? disableIcon : product.image}
											style={{ width: "80px", height: "80px" }}
										/>
										<div
											style={{
												fontSize: "16px",
												fontWeight: "600",
												lineHeight: "20px",
											}}
										>
											{product.label}
										</div>
									</Space>
								</Button>
							);
						})}
					</div>
				</div>
			</div>
			{/* Side Container */}
			{sideContainer()}
		</>
	);
};

export default HelpCenter;
