import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";
import moment from "moment";
import dayjs from "dayjs";

function TimeEntryModal(props: any) {
	const context: any = useContext(HomeContext);
	// Form reference
	const [formRef] = useForm();
	// Watch Form Input Changes
	const watchStartTimeInput = useWatch("start_time", formRef);
	const watchEndTimeInput = useWatch("end_time", formRef);
	const [activeRowKey, setActiveRowKey] = useState<any>(props.selectedUUID);
	const [initialForm, setInitialForm] = useState<any>(null);
	// Cancel Popover State
	const [cancelPopover, setCancelPopover] = useState<any>(false);
	// Delete Popover State
	const [deletePopover, setDeletePopover] = useState<boolean>(false);
	// Form Reset State
	const [formReset, setFormReset] = useState<boolean>(true);

	const handleFormCancel = () => {
		props.setOpenModal(false);
		// setCancelPopover(false);
		resetForm();
	};

	const handleFormSave = async (values: any) => {
		// Iterate through every input in the time entry modal form and store their values in string.
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				// Remove any whitespace in the input value
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("timewriting").then((cruda: any) => {
			if (activeRowKey) {
				const form = cruda.getFormList("timewriting");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				// Runs `updateLookupItem` function in the `CRUDA > timewriting` file and pass data into parameters.
				cruda
					// Put `null` on unused props
					.updateLookupItem(
						null, // name of the file in the CRUDA folder
						null, // the `if else` condition for running the API
						activeRowKey, // uuid to check if the item exists
						null, // current tenant key for perform operations tenant specific data only
						values, // all input data in `getFormList`
						initialForm, // `initialData` before any changes
						null, // object for storing `otherData` props
						props.userData, // user data (optional)
						null // current array of input field `name` values
					)
					.then((updatedItem: any) => {
						// Signals the emitter that has an "alert" keyword attached to it
						Emitter.emit("alert", {
							// Payload for this emitter
							type: "success",
							message: `Time entry has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000, // 3 seconds
						});
						if (props.refreshTimeEntries) props.refreshTimeEntries(updatedItem);
						// A dynamic way to detect page changes while form is being updated
						context?.handleFormUpdating(false, activeRowKey);
						// Delayed closing of the modal
						setTimeout(() => {
							props.setOpenModal(false);
						}, 300);

						setCancelPopover(false); // Closes the cancel popconfirm (if opened)
						setDeletePopover(false); // Closes the delete popconfirm (if opened)
						resetForm();
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This time entry already exists, please ensure the time entry ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
				console.log(
					"activeRowKey: ",
					activeRowKey,
					"values: ",
					values,
					"initialForm: ",
					initialForm,
					"formList: ",
					formList
				);
			} else {
				console.log("Adding TimeEnetry data", values);
				cruda
					.addLookupItem(
						"timewriting",
						[],
						values,
						null,
						null,
						null,
						props.userData
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Time entry has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});

						if (values.start_time && values.end_time) {
							values.start_time = dayjs(values.start_time);
							values.end_time = dayjs(values.end_time);
						}
						context?.handleFormUpdating(false, "new");
						setActiveRowKey(addedItem?.uuid);
						if (props.refreshTimeEntries) props.refreshTimeEntries(addedItem);

						setTimeout(() => {
							props.setOpenModal(false);
						}, 300);
						setCancelPopover(false); // Closes the cancel popconfirm (if opened)
						resetForm(values, true);
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This time entry already exists, please ensure the time entry ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = () => {
		context?.handleFormUpdating(false, activeRowKey);
		// Delete the time entry item by `activeRowKey` (uuid)
		console.log("Deleting data", activeRowKey);
		// `getCrudaClass` goes into the `CRUDA` folder and iterate through all the file names,
		//  then return the file that matches the `tabKey` value.
		getCrudaClass("timewriting").then((cruda: any) => {
			cruda
				// Runs `deleteLookupItem` and pass in the `activeRowKey` (uuid)
				.deleteLookupItem(null, null, activeRowKey)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Time entry has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					props.deleteTimeEntry(activeRowKey);
					props.setOpenModal(false);
					setDeletePopover(false);
					setActiveRowKey(null);
					resetForm();

					// Redirect to the selected page on delete
					if (props.redirectOnDelete) {
						props.redirectOnDelete();
					}
				})
				.catch((error: any) => {
					console.log("Error during deletion: ", error);
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {});
		});
	};

	// Handle form reset
	const resetForm = (values: any = null, reset: any = true) => {
		// If the form reference exists
		if (formRef) {
			// Set the `formReset` to true
			setFormReset(true);
			// Reset the form fields
			reset && formRef.resetFields();
			// Set the form fields value if values exist
			formRef.setFieldsValue(values ? { ...values } : null);
			// Set the initial form values (for editing)
			setInitialForm(values);
			// console.log("`resetForm` Values", values);
		}
	};

	// Handle form reset values
	useEffect(() => {
		resetForm(props.initialValues, true);
		setActiveRowKey(props.initialValues.uuid);
	}, [props.matchedEntry, props.initialValues]);

	// Handle Calculate Start & end time Duration
	const handleCalculateDurationInMinutes = () => {
		const startTime = formRef.getFieldValue("start_time");
		const endTime = formRef.getFieldValue("end_time");
		// console.log("startTime: ", startTime, "endTime: ", endTime);

		let durationInMinutes = undefined; // Default to undefined

		if (startTime && endTime) {
			const startMoment = moment(startTime); // Convert to moment object
			const endMoment = moment(endTime); // Convert to moment object
			// console.log("startMoment: ", startMoment, "endMoment: ", endMoment);

			if (startMoment.isValid() && endMoment.isValid()) {
				durationInMinutes = endMoment.diff(startMoment, "minutes"); // Use diff with moment objects
				// console.log("Calculated duration in minutes: ", durationInMinutes);
			}
		}

		formRef.setFieldsValue({
			duration: durationInMinutes,
		});

		// console.log("Final duration in minutes: ", durationInMinutes);
	};

	useEffect(() => {
		handleCalculateDurationInMinutes();

		// console.log("Some");
	}, [watchStartTimeInput, watchEndTimeInput]);

	return (
		// <Modal
		// 	closable={false}
		// 	width={"40%"}
		// 	open={props.openModal}
		// 	centered
		// 	destroyOnClose
		// 	footer={() => {
		// 		return (
		// 			<div
		// 				style={{
		// 					height: "auto",
		// 					padding: "0",
		// 					width: "100%",
		// 					display: "flex",
		// 					justifyContent: props.matchedEntry ? "space-between" : "flex-end",
		// 					gap: "16px",
		// 				}}
		// 			>
		// 				{/* Delete Popconfirm */}
		// 				{props.matchedEntry && (
		// 					<Popconfirm
		// 						placement="topLeft"
		// 						title={
		// 							<div style={{ maxWidth: "300px", padding: "10px" }}>
		// 								<>
		// 									Permanently delete this well?
		// 									<br />
		// 									This action cannot be undone.
		// 								</>
		// 							</div>
		// 						}
		// 						open={deletePopover}
		// 						//description="Permanently delete this item? This action cannot be undone."
		// 						onConfirm={handleFormDelete}
		// 						overlayClassName="popconfirm-danger"
		// 						onCancel={() => {
		// 							setDeletePopover(false);
		// 						}}
		// 						okText="Delete"
		// 						okType="danger"
		// 						icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
		// 						cancelText={<span>Cancel</span>}
		// 						cancelButtonProps={{ ghost: true }}
		// 					>
		// 						<Button
		// 							disabled={!activeRowKey}
		// 							className="ant-btn-secondary"
		// 							style={{ marginRight: "auto" }}
		// 							danger
		// 							onClick={() => {
		// 								setDeletePopover(true);
		// 							}}
		// 						>
		// 							Delete
		// 						</Button>
		// 					</Popconfirm>
		// 				)}
		// 				{/* Cancel Popconfirm */}
		// 				<Popconfirm
		// 					icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
		// 					title={
		// 						<div style={{ maxWidth: "300px", padding: "10px" }}>
		// 							<>
		// 								You have unsaved changes. Discard them or continue editing?
		// 							</>
		// 						</div>
		// 					}
		// 					open={cancelPopover}
		// 					placement="topRight"
		// 					cancelButtonProps={{ ghost: true }}
		// 					cancelText="Discard changes"
		// 					onCancel={() => {
		// 						setCancelPopover(false);
		// 						setTimeout(() => {
		// 							handleFormCancel();
		// 						}, 300);
		// 					}}
		// 					okText="Continue editing"
		// 					okType="default"
		// 					onConfirm={() => {
		// 						setCancelPopover(false);
		// 					}}
		// 				>
		// 					{/* Cancel Button */}
		// 					<Button
		// 						className="ant-btn-secondary"
		// 						onClick={() => {
		// 							if (formReset) {
		// 								handleFormCancel();
		// 							} else {
		// 								setCancelPopover(true);
		// 							}
		// 						}}
		// 					>
		// 						Cancel
		// 					</Button>
		// 				</Popconfirm>
		// 				{/* Save Popconfirm */}
		// 				<Popconfirm
		// 					icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
		// 					title={
		// 						<div style={{ maxWidth: "300px", padding: "10px" }}>
		// 							<>
		// 								You have unsaved changes. Discard them or continue editing?
		// 							</>
		// 						</div>
		// 					}
		// 					placement="topRight"
		// 				>
		// 					{/* Save Button */}
		// 					<FormButtonSave
		// 						form={formRef}
		// 						formReset={formReset}
		// 						setFormReset={setFormReset}
		// 						handleFormSave={handleFormSave}
		// 					></FormButtonSave>
		// 				</Popconfirm>
		// 			</div>
		// 		);
		// 	}}
		// >
		// 	<div>Time entry</div>
		// 	<CustomForm
		// 		setFormReset={setFormReset}
		// 		formRef={formRef}
		// 		tabKey={"timewriting"} // link to name of the file inside `utils > CRUDA` folder
		// 		// `dataOptions` is an object that contains the data needed for the form
		// 		dataOptions={{
		// 			// Declare the fields and pass props data as objects
		// 			projects: props.projects,
		// 			tenants: props.tenants,
		// 		}}
		// 	/>
		// </Modal>

		<Popconfirm
			open={props.openModal}
			showCancel={false} // Hides the cancel button
			okButtonProps={{ style: { display: "none" } }} // Hides the confirm button
			overlayStyle={{ maxHeight: "100%", overflowY: "auto" }} // Adjust height and allow scrolling
			icon={null} // Remove the default icon
			placement="top"
			title={
				<div style={{ width: "100%", minWidth: "500px" }}>
					{/* Header */}
					<div style={{ fontSize: "18px", marginBottom: "16px" }}>
						Time Entry
					</div>

					{/* Form */}
					<CustomForm
						setFormReset={setFormReset}
						formRef={formRef}
						tabKey={"timewriting"} // Link to name of the file inside `utils > CRUDA` folder
						dataOptions={{
							projects: props.projects,
							tenants: props.tenants,
						}}
					/>

					{/* Footer Actions */}
					<div
						style={{
							display: "flex",
							justifyContent: props.matchedEntry ? "space-between" : "flex-end",
							gap: "16px",
						}}
					>
						{/* Delete Popconfirm */}
						{props.matchedEntry && (
							<Popconfirm
								placement="topLeft"
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											Permanently delete this well?
											<br />
											This action cannot be undone.
										</>
									</div>
								}
								open={deletePopover}
								//description="Permanently delete this item? This action cannot be undone."
								onConfirm={() => {
									// Closes delete popconfirm if it is open
									setDeletePopover(false);
									setCancelPopover(false);
									setTimeout(() => {
										// Runs `handleFormDelete` (closes the modal)
										handleFormDelete();
									}, 300); // Delay `handleFormDelete` by 300ms to wait for `setDeletePopover` to close first before closing the modal
								}}
								overlayClassName="popconfirm-danger"
								onCancel={() => {
									setDeletePopover(false);
								}}
								okText="Delete"
								okType="danger"
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								cancelText={<span>Cancel</span>}
								cancelButtonProps={{ ghost: true }}
							>
								<Button
									disabled={!activeRowKey}
									className="ant-btn-secondary"
									style={{ marginRight: "auto" }}
									danger
									onClick={() => {
										setDeletePopover(true);
									}}
								>
									Delete
								</Button>
							</Popconfirm>
						)}

						<div
							style={{
								display: "flex",
								gap: "16px",
							}}
						>
							{/* Cancel Popconfirm */}
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									// Closes cancel popconfirm if `Discard changes` is clicked
									setCancelPopover(false);
									setTimeout(() => {
										// Runs `handleFormCancel` (closes the modal)
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									// Closes cancel popconfirm if `Continue editing` is clicked
									setCancelPopover(false);
								}}
							>
								{/* Cancel Button */}
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											// Closes delete popconfirm if it is open
											setDeletePopover(false);

											setTimeout(() => {
												// Runs `handleFormCancel` (closes the modal)
												handleFormCancel();
											}, 300); // Delay `handleFormCancel` by 1ms to wait for `setDeletePopover` to close first before closing the modal
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>

							{/* Save Popconfirm */}
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								placement="topRight"
							>
								{/* Save Button */}

								<FormButtonSave
									form={formRef}
									formReset={formReset}
									setFormReset={setFormReset}
									handleFormSave={handleFormSave}
								></FormButtonSave>
							</Popconfirm>
						</div>
					</div>
				</div>
			}
			okText="Save"
			cancelText="Cancel"
		/>
	);
}

export default TimeEntryModal;
