import { Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useRef, useState } from "react";
import CustomForm from "../../components/CustomForm";
import FormButtonCancel from "../../components/FormButtonCancel";
import FormButtonSave from "../../components/FormButtonSave";
import { HomeContext } from "../../containers/Home";
import useUpdateAllData from "../../hooks/useUpdateAllData";
import { updateDeliverableRoles } from "../../services/api-server/deliverables";
import { updateProjectItem } from "../../services/api-server/projects";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";

const ProjectConfiguration = (props: any) => {
	const context: any = useContext(HomeContext);
	const currentProject = props.currentProject;
	const data = props.data || {};

	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
	const [extraData, setExtraData] = useState<any[]>([]);
	const [projectData, setProjectData] = useState<any>(null);

	// const [initialForm, setInitialForm] = useState<any>(null);
	const initialForm = useRef(null);
	const { updateAllData } = useUpdateAllData();

	useEffect(() => {
		// set project data
		if (currentProject != "Add new project" && currentProject) {
			let project = data.projects.filter(
				(item: any) => item.project_code == currentProject
			);
			//
			setProjectData(project[0]);
			let initialFormData: any = {
				project_manager: project[0].project_manager,
				role_assignments: project[0].role_assignments,
			};
			initialForm.current = initialFormData;
			formRef.setFieldsValue(initialForm.current);
		}
	}, [formRef, data]);

	useEffect(() => {
		//
	}, [formReset]);

	const handleFormCancel = () => {
		if (currentProject != "Add new employee") {
			resetForm(initialForm.current);
		} else {
			resetForm();
		}
	};

	const unlinkRoleInDeliverables = (deletedRoles: any) => {
		deletedRoles.map((role: any) => {
			const roleId = role.role_id;
			updateDeliverableRoles(context?.currentTenantKey, roleId).catch(
				(error: any) => {
					console.log("Error of update deliverable roles", error);
				}
			);
		});
	};

	const handleFormSave = (values: any, type: string) => {
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		// 	//
		Emitter.emit("loading", true);

		getCrudaClass("roles-configuration").then((crudaClass: any) => {
			const form = crudaClass.getFormList("roles-configuration");
			const formList: any = [];
			form.forEach((element: any) => {
				if (element.children && !("name" in element)) {
					element.children.forEach((child_element: any) => {
						if (child_element.name) formList.push(child_element.name);
					});
				} else {
					if (element.name) formList.push(element.name);
				}
			});

			if (currentProject != "Add new project" && currentProject) {
				const initialData: any = initialForm.current;
				updateProjectItem(
					context?.currentTenantKey,
					currentProject,
					values,
					initialData,
					props.user,
					formList
				)
					.then((updatedItem: any) => {
						let project = context.allData.projects.find(
							(item: any) => item.project_code == currentProject
						);
						project.role_assignments = values?.role_assignments;
						context.allData.projects = context.allData.projects.map(
							(item: any) =>
								item.project_code == currentProject ? project : item
						);

						Emitter.emit("alert", {
							type: "success",
							message: "Project has been successfully updated.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm(values);
						props?.handleProjectConfigSave();

						const missingRoles = values?.role_assignments.filter(
							(role: any) => {
								if (context.allData?.roles) {
									return !context.allData?.roles.find(
										(assignment: any) => assignment?._id == role?.role_id
									);
								}
							}
						);

						// compare initial data and values and get the removed roles from initial data if any
						const removedRoles = initialData?.role_assignments.filter(
							(role: any) =>
								!values?.role_assignments.find(
									(assignment: any) => assignment?.role_id == role?.role_id
								)
						);
						// combine missing roles and removed roles
						removedRoles.push(...missingRoles);
						unlinkRoleInDeliverables(removedRoles);
						updateAllData(updatedItem, "update", "projects");
					})
					.finally(() => {
						Emitter.emit("loading", false);
					});
			}
		});
	};

	const handleFormDelete = (values: any, type: string) => {
		//
	};

	const resetForm = (values: any = null, data: any = null) => {
		let filteredCountries: any = [];
		if (formRef) {
			setFormReset(true);
			if (values) {
				Object.keys(values).forEach((key: any) => {
					// values[key] = checkLookup(key, values[key], data || allData);
					//
				});
			}
			formRef.resetFields();
			formRef.setFieldsValue(values);

			initialForm.current = values;
		}
	};

	return (
		<div
			className="main-container"
			style={{
				width: "70%",
				display: "flex",
				flexDirection: "column",
				borderRadius: "3px",
			}}
		>
			<div className="generic-header" style={{ height: "50px" }}>
				Settings
			</div>
			<div
				className="generic-content"
				style={{
					flex: "1",
					gap: "0px",
					maxHeight: "100%",
					overflow: "hidden",
					flexWrap: "nowrap",
				}}
			>
				<Tabs
					tabPosition="left"
					defaultValue={"team-roles"}
					onChange={(key: any) => {
						// setTableColumns([]);
						// setDataTable([]);
						// setSideContainerOpen(false);
						// setTabKey(key);
						// setExpandRowKey([]);
						// setFilteredExtraData(null);
						// setActiveRowKey(null);
						// setActiveGroupKey(null);
					}}
					defaultActiveKey={"company"}
					items={[{ label: "Team roles", key: "team-roles" }]}
				></Tabs>
				<div
					style={{
						flex: "1",
						height: "100%",
						display: "flex",
						maxWidth: "100%",
						overflow: "auto",
						flexDirection: "column",
					}}
				>
					<CustomForm
						setFormReset={setFormReset}
						tabKey={"roles-configuration"}
						formRef={formRef}
						dataOptions={data}
					/>
				</div>
			</div>
			<div className="generic-footer">
				<FormButtonCancel
					form={formRef}
					handleFormCancel={handleFormCancel}
					formReset={formReset}
				></FormButtonCancel>
				<FormButtonSave
					form={formRef}
					handleFormSave={handleFormSave}
					formReset={formReset}
				></FormButtonSave>
			</div>
		</div>
	);
};

export default ProjectConfiguration;
