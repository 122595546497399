/**
 * 	Terra's main page, contains the Layout, Header, Footer, and Side Menu.
 *  Any other content will be available in the <Outlet/> element, which renders
 * 	components based on the current endpoint in the URL
 */

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
	Divider,
	Dropdown,
	Image,
	Layout,
	Popconfirm,
	Space,
	Spin,
} from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useNavigate } from "react-router";
import file from "../../package.json";
import terraLogoFooterDark from "../assets/image/TerraLogo_Footer_Darkmode.svg";
import terraLogoFooterLight from "../assets/image/TerraLogo_Footer_Lightmode.svg";
import elementalLoadingLogo from "../assets/image/elemental 'e'.svg";
import elementalLoadingOutline from "../assets/image/outline 'e'.svg";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import ContextMenu from "../components/ContextMenu";
import LeaveWithChangesModal from "../components/LeaveWithChangesModal";
import NotificationComponent from "../components/NotificationComponent";
import { MainContext } from "../contexts/context";
import MainMenu from "../services/MainMenu";
import { sendErrorNotification } from "../services/api-server/base";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { getCompanyList } from "../services/api-server/company";
import { getRolePermissionList } from "../services/api-server/general_roles";
import { getTenantSpecificUsers } from "../services/api-server/graphql";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { getOperationTypeList } from "../services/api-server/operation_type";
import { getProjectTagList } from "../services/api-server/project_tags";
import { getAllProjects, getProjects } from "../services/api-server/projects";
import { getWellPurposeTypeList } from "../services/api-server/purpose_types";
import { getWellPurposeList } from "../services/api-server/purposes";
import { getRegionList } from "../services/api-server/region";
import {
	getRolePermissions,
	getUserRole,
} from "../services/api-server/roles_permissions";
import { getWellStatusList } from "../services/api-server/status";
import { getWellStatusTypeList } from "../services/api-server/status_types";
import { getTagGroupList } from "../services/api-server/tag_group";
import { getWellProgrammeTemplateList } from "../services/api-server/well_programme_template";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { getInitials, logout } from "../utils/utils";
import NoAccess from "./NoAccess";

const { Header, Content, Footer } = Layout;

export const HomeContext = React.createContext({});

const Home = (props: any) => {
	const formUpdatingRef = useRef<any>([]);
	const [menuProps, setMenuProps] = useState<any>([]);
	const [allData, setAllData] = useState<any>(null);
	const [isLoading, setLoading] = useState<any>(false);
	const [selectedKey, setSelectedKey] = useState<any>([]);
	const [isCollapsed, setCollapsed] = useState<boolean>(true);
	const [currentTenantData, setCurrentTenantData] = useState<any>(null);
	const [currentTenantKey, setCurrentTenantKey] = useState<any>(null);
	const [currentProject, setCurrentProject] = useState<any>(null);
	const [currentWell, setCurrentWell] = useState<any>({});
	const [currentOperation, setCurrentOperation] = useState<any>({});
	const [currentPhase, setCurrentPhase] = useState<any>({});
	const [currentTask, setCurrentTask] = useState<any>({});

	// Data states
	const [rolePermissions, setRolePermissions] = useState<any[]>([]);
	const [userRole, setUserRole] = useState<any>(null);

	const tenantData = props?.tenants;
	const [pageChange, setPageChange] = useState<boolean>(false);
	const [popconfirmDialogOpen, setPopconfirmDialogOpen] =
		useState<boolean>(false);
	const [tenantDropdownOpen, setTenantDropdownOpen] = useState<boolean>(false);

	const [errorBoundaryKey, incrementErrorBoundaryKey] = useState(0);

	const [contextMenuPosition, setContextMenuPosition] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [contextMenuInfo, setContextMenuInfo] = useState<any>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (currentTenantKey && currentTenantData) {
			// Emitter.emit("loading", true);
			let allData: any = {};
			Promise.all([
				getUserRole(currentTenantKey, props.userInfo.user.email).then(
					(_data: any) => {
						if (_data) {
							console.log("user role", _data);
							setUserRole(_data);
						}
					}
				),

				getBusinessUnitList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.strategic_business_unit = _data;
					}
				}),

				getTagGroupList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.tag_group = _data;
					}
				}),

				getProjectTagList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.project_tags = _data;
					}
				}),

				getOperatingOfficeList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.operating_office = _data;
					}
				}),

				getProjects(currentTenantKey, currentTenantData?.company_list).then(
					(_data: any) => {
						if (_data) {
							allData.projects = _data;
						}
					}
				),

				getRegionList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.region = _data;
					}
				}),

				getCompanyList(currentTenantKey, [], true).then((_data: any) => {
					if (_data) {
						allData.company = _data;
					}
				}),

				// General role permissions
				getRolePermissionList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.roles = _data;
					}
				}),

				// New role permissions
				getRolePermissions(currentTenantKey).then((_data: any) => {
					if (_data) {
						// allData.role_permissions = _data;
						setRolePermissions(_data);
					}
				}),

				getTenantSpecificUsers(currentTenantData?.uuid).then(
					(tenantUsers: any) => {
						if (tenantUsers) {
							allData.users = tenantUsers;
						}
						if (currentTenantData?.project_managers) {
							let projectManagerList = currentTenantData?.project_managers;
							allData.project_managers = projectManagerList;
						}
					}
				),
				getWellPurposeList().then((_data: any) => {
					if (_data) {
						allData.well_purpose = _data;
					}
				}),
				getWellPurposeTypeList().then((_data: any) => {
					if (_data) {
						allData.well_purpose_type = _data;
					}
				}),
				getWellStatusList().then((_data: any) => {
					if (_data) {
						allData.well_status = _data;
					}
				}),
				getWellStatusTypeList().then((_data: any) => {
					if (_data) {
						allData.well_status_type = _data;
					}
				}),
				getOperationTypeList().then((_data: any) => {
					if (_data) {
						allData.operation_type = _data;
					}
				}),
				getWellProgrammeTemplateList(currentTenantKey).then((_data: any) => {
					if (_data) {
						allData.well_programme_template = _data;
					}
				}),
			]).then((data: any) => {
				setAllData(allData);
			});

			// FOR JORDAN SEOW
			let tenantValues = tenantData.map((tenant: any) => ({
				value: tenant.value,
				isTenantMember:
					tenant?.tenant_admin?.includes(props?.userInfo?.user?.email) ||
					tenant?.tenant_members?.includes(props?.userInfo?.user?.email),
			}));
			getAllProjects(
				currentTenantKey,
				tenantValues,
				props?.userInfo?.user?.email
			).then((response: any) => {
				console.log(response);
			});
		}
	}, [currentTenantData, currentTenantKey]);

	const handleErrorFallback = (props: any) => {
		return (
			<NoAccess
				text={"An Error has occurred, please try again later."}
				setErrorBoundaryKey={() => {
					incrementErrorBoundaryKey(errorBoundaryKey + 1);
				}}
			/>
		);
	};

	const logError = (error: any, info: any) => {
		if (process.env.NODE_ENV === "production") {
			error.location = window.location.pathname;
			error.level = "component";
			let JSONerror = JSON.stringify(error, Object.getOwnPropertyNames(error));
			sendErrorNotification(JSONerror);
		}
	};

	useEffect(() => {
		Emitter.on("menuChange", (menuProps: any) => {
			if (
				localStorage.getItem("selectedFilters") &&
				menuProps.container !== "projectSettings"
			) {
				localStorage.removeItem("selectedFilters");
			}
			setMenuProps(menuProps);
		});

		Emitter.on("loading", (payload: any) => {
			setLoading(payload);
		});
	}, []);

	useEffect(() => {
		console.log("Is loading:", isLoading);
	}, [isLoading]);

	useEffect(() => {
		const tenant = localStorage.getItem("currentTenantKey");
		if (tenantData) {
			if (tenant) {
				let foundTenant = tenantData?.find(
					(element: any) => element?.value === tenant
				);
				if (foundTenant) {
					setCurrentTenantKey(tenant);
					setCurrentTenantData({ ...foundTenant });
					localStorage.setItem("currentTenantKey", tenant);
				} else {
					if (tenantData.length > 0) {
						setCurrentTenantData({ ...props.tenants[0] });
						setCurrentTenantKey(props.tenants[0]?.value);
						localStorage.setItem("currentTenantKey", props.tenants[0]?.value);
					}
				}
			} else {
				setCurrentTenantData({ ...props.tenants[0] });
				setCurrentTenantKey(props.tenants[0]?.value);
				localStorage.setItem("currentTenantKey", props.tenants[0]?.value);
			}
		}
	}, [props.tenants]);

	useEffect(() => {
		let menu = props.menu;
		let currentlocation = "";
		currentlocation = props.currentLocation.split("/");

		let menuItem = menu.find((element: any) => {
			let route: any = element?.item?.to.split("/").pop();
			return currentlocation?.includes(route);
		});
		if (menuItem) {
			incrementErrorBoundaryKey(errorBoundaryKey + 1);
			if (menuItem?.item?.requires_admin == true) {
				setMenuProps([{ title: "Administration" }, menuItem?.item]);
			} else {
				setMenuProps([menuItem?.item]);
			}

			setSelectedKey(menuItem.item.key);
		}
		handleFormUpdating(false);
	}, [props.currentLocation]);

	const onCollapse = (collapseState: any) => {
		setCollapsed(collapseState);
	};

	const handleFormUpdating = (bool: any, key: any = null) => {
		if (key === null) {
			key = "new";
		}
		setPageChange(false);
		if (bool == true && key) {
			formUpdatingRef.current[key] = true;
		} else if (bool == false && key) {
			delete formUpdatingRef.current[key];
		}
	};

	const handlePageChange = () => {
		// Inside the function, we create a new Promise.
		// The resolve function is called when the operation completes successfully, and the reject function is called if something goes wrong or conditions aren't met.
		return new Promise((resolve, reject) => {
			// This updates a state (presumably a useState hook) named pageChange to true. This likely signals that a page change is happening.
			setPageChange(true);
			// This calculates the length of the object stored in formUpdatingRef.current (likely a reference to track some form's state). It counts how many keys (properties) the object has, which helps determine if any forms are being updated.
			let length = Object.keys(formUpdatingRef.current).length;

			if (length > 0) {
				// This sets up an event listener that waits for the "leave" event. When this event is emitted, the following actions are performed:
				Emitter.on("leave", () => {
					// This resolves the promise, indicating that the page change was successful.
					resolve(true);
					// Clears the formUpdatingRef.current object to reset form-related state.
					formUpdatingRef.current = {};
					// Updates the pageChange state back to false to indicate that the page change process is completed.
					setPageChange(false);
					// These remove the event listeners for "leave" and "stay" so they don't trigger multiple times.
					Emitter.remove("leave");
					Emitter.remove("stay");
					// Clears the tabKey from session storage.
					sessionStorage.removeItem("tabKey");
				});
				// This sets up an event listener for the "stay" event. When this event is emitted, the following actions happen:
				Emitter.on("stay", () => {
					// Rejects the promise, indicating that the page change should not happen (the user is staying on the current page).
					reject(false);
					// Updates the pageChange state back to false.
					setPageChange(false);
					// Removes the event listeners for "leave" and "stay", so they are no longer active.
					Emitter.remove("leave");
					Emitter.remove("stay");
				});
				// This block executes if length <= 0, meaning there are no forms currently being updated.
			} else {
				// Clears the tabKey from session storage, which is related to the current tab (possibly tracking which tab was selected).
				sessionStorage.removeItem("tabKey");
				// Resolves the promise, indicating that the page change is allowed to proceed.
				resolve(true);
				// Resets the formUpdatingRef.current object.
				formUpdatingRef.current = {};
				// Updates the pageChange state back to false.
				setPageChange(false);
			}
		});
	};

	const handleTenantSwitch = (data: any) => {
		localStorage.setItem("currentTenantKey", data.value);
		window.location.href = "/dashboard";
	};

	const displayLogo = (data: any) => {
		return data?.logo ? (
			<Image
				style={{
					border: "1px solid #d9d9d9",
					borderRadius: "180px",
					minWidth: "32px",
					maxWidth: "32px",
				}}
				width={32}
				height={32}
				preview={false}
				src={data?.logo}
			></Image>
		) : (
			<div
				className="profile-picture-initials"
				style={{
					border: "1px solid #d9d9d9",
					width: "32px",
					minWidth: "32px",
					maxWidth: "32px",
					height: "32px",
				}}
			>
				<span className="profile-initials" style={{ fontSize: "12px" }}>
					{getInitials(data?.name || data?.displayName, 0)}
				</span>
			</div>
		);
	};

	const [tenantName, setTenantName] = useState<any>(null);

	useEffect(() => {
		const currentTenant = tenantData.find(
			(tenant: any) => tenant?.value === currentTenantKey
		);
		if (currentTenant) {
			setTenantName(currentTenant.name || currentTenant.displayName);
		}
	}, [tenantData, currentTenantKey]);

	const renderTenantDropdown = () => {
		const renderTenantButtons = (data: any) => {
			return (
				<Popconfirm
					disabled={data?.value === currentTenantKey}
					placement="right"
					overlayInnerStyle={{
						maxWidth: "300px",
						padding: "16px",
					}}
					title=""
					description={`Switch to ${
						data.name || data.displayName
					}? This will log you out of the current session `}
					onOpenChange={(boolean: any) => {
						setPopconfirmDialogOpen(boolean);
					}}
					onCancel={() => {
						handleTenantSwitch(data);
					}}
					overlayClassName="popconfirm-danger popconfirm-split-buttons"
					okText="Stay on current tenant"
					okType="default"
					icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
					cancelText={<span>Switch</span>}
					cancelButtonProps={{ ghost: true }}
				>
					<div
						className={
							data?.value === currentTenantKey
								? "tenant-button-active"
								: "tenant-button"
						}
					>
						{displayLogo(data)}
						<span>{data.name || data.displayName}</span>
					</div>
				</Popconfirm>
			);
		};
		return (
			<div
				style={{
					minWidth: "300px",
					padding: "14px 10px",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					alignItems: "flex-start",
				}}
			>
				{tenantData
					.sort((a: any, b: any) => {
						if (a?.value === currentTenantKey) {
							return -1;
						} else if (b?.value === currentTenantKey) {
							return 1;
						} else {
							return 0;
						}
					})
					.map((data: any) => renderTenantButtons(data))}
			</div>
		);
	};

	return (
		<MainContext.Consumer>
			{({ menuOption }) => {
				return (
					<HomeContext.Provider
						value={{
							isLoading: isLoading,
							allData: allData,
							userRole: userRole,
							rolePermissionsData: rolePermissions,
							tenantData: tenantData,
							currentTenantData: currentTenantData,
							currentTenantKey: currentTenantKey,
							currentProject: currentProject,
							currentWell: currentWell,
							currentOperation: currentOperation,
							currentPhase: currentPhase,
							currentTask: currentTask,
							userInfo: props.userInfo,
							setUserRole: setUserRole,
							setCurrentTenantData: setCurrentTenantData,
							setCurrentProject: setCurrentProject,
							setCurrentWell: setCurrentWell,
							setCurrentOperation: setCurrentOperation,
							setCurrentPhase: setCurrentPhase,
							setCurrentTask: setCurrentTask,
							handleFormUpdating: handleFormUpdating,
							handlePageChange: handlePageChange,
							setContextMenuPosition: setContextMenuPosition,
							setContextMenuInfo: setContextMenuInfo,
						}}
					>
						<Layout className="site-whole">
							<MainMenu
								collapsed={isCollapsed}
								onCollapse={(collapseState: boolean) => {
									onCollapse(collapseState);
								}}
								selectedKey={selectedKey}
								option={menuOption}
								roles={props.userInfo.userRole}
							></MainMenu>
							<Layout className="site-layout">
								<Header className="site-header">
									{/* Breadcrumb Component */}
									<BreadcrumbComponent />
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "20px",
										}}
									>
										{/* Notifications */}
										{props?.tenants?.length > 0 && (
											<NotificationComponent userInfo={props.userInfo} />
										)}
										{/* Tenants Dropdown */}
										<div>
											<Dropdown
												open={popconfirmDialogOpen || tenantDropdownOpen}
												dropdownRender={() => (
													<div
														style={{
															paddingLeft: 16,
															paddingRight: 16,
															paddingTop: 14,
															paddingBottom: 14,
															display: "flex",
															flexDirection: "column",
															gap: 16,
															boxShadow:
																"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
															borderRadius: "8px",
														}}
													>
														{/* User details section */}
														<div
															style={{
																paddingLeft: 14,
																paddingRight: 14,
															}}
														>
															<div style={{ display: "flex", gap: "12px" }}>
																{/* User image */}
																<div
																	style={{
																		width: "38px",
																		height: "38px",
																		minWidth: "38px",
																		maxWidth: "38px",
																		cursor: "pointer",
																	}}
																	onClick={async () => {
																		try {
																			navigate(`/profile`);
																		} catch (error) {
																			console.log(error);
																		}
																	}}
																>
																	{props.userInfo.user?.profile_picture ? (
																		<Image
																			style={{
																				border: "1px solid #d9d9d9",
																				borderRadius: "180px",
																				minWidth: "32px",
																				maxWidth: "32px",
																			}}
																			width={32}
																			height={32}
																			preview={false}
																			src={props.userInfo.user?.profile_picture}
																		></Image>
																	) : (
																		<div
																			className="profile-picture-initials"
																			style={{
																				border: "1px solid #d9d9d9",
																				width: "32px",
																				minWidth: "32px",
																				maxWidth: "32px",
																				height: "32px",
																			}}
																		>
																			<span
																				className="profile-initials"
																				style={{ fontSize: "12px" }}
																			>
																				{getInitials(
																					props.userInfo.user?.name ||
																						props.userInfo.user?.displayName,
																					0
																				)}
																			</span>
																		</div>
																	)}
																</div>
																{/* User name and email */}
																<div
																	style={{
																		display: "flex",
																		flexDirection: "column",
																		gap: "6px",
																	}}
																>
																	<div
																		style={{
																			display: "flex",
																			flexDirection: "column",
																		}}
																	>
																		<div
																			className="user-section"
																			style={{
																				cursor: "pointer",
																				fontWeight: "bold",
																			}}
																			onClick={async () => {
																				try {
																					navigate(`/profile`);
																				} catch (error) {
																					console.log(error);
																				}
																			}}
																		>
																			{props.userInfo.user.name}
																		</div>
																		{/* User email */}
																		{props.userInfo.user.email}
																	</div>
																	<div>
																		<div
																			className="user-section"
																			style={{
																				paddingTop: 4,
																				paddingBottom: 4,
																				display: "flex",
																				gap: "8px",
																				alignItems: "center",
																				cursor: "pointer",
																			}}
																			onClick={async () => {
																				try {
																					window.open(
																						"https://myaccount.microsoft.com/?ref=MeControl",
																						"_blank"
																					);
																				} catch (error) {
																					console.log(error);
																				}
																			}}
																		>
																			<span>View Microsoft 365 account</span>
																			<span style={{ fontSize: "10px" }}>
																				{GetAntIcon("export")}
																			</span>
																		</div>
																	</div>
																	<div
																		className="user-section-sign-out"
																		style={{
																			cursor: "pointer",
																		}}
																		onClick={async () => {
																			localStorage.removeItem("idToken"); // Remove idToken
																			localStorage.removeItem("accessToken"); // Remove accessToken
																			logout(); // Call logout function
																		}}
																	>
																		Sign out
																	</div>
																</div>
															</div>
														</div>
														{props?.tenants?.length > 0 && (
															<>
																<Divider
																	style={{
																		margin: 0,
																		color: "rgba(125, 125, 125, 1)",
																		fontSize: 14,
																	}}
																	orientation="left"
																>
																	Switch tenant
																</Divider>
																{renderTenantDropdown()}
															</>
														)}
													</div>
												)}
												placement="bottomRight"
												trigger={["hover"]}
												onOpenChange={(boolean: any) => {
													setTenantDropdownOpen(boolean);
												}}
											>
												{props.userInfo.user?.profile_picture ? (
													<Image
														style={{
															border: "1px solid #d9d9d9",
															borderRadius: "180px",
															minWidth: "32px",
															maxWidth: "32px",
														}}
														width={32}
														height={32}
														preview={false}
														src={props.userInfo.user?.profile_picture}
													></Image>
												) : (
													<div
														className="profile-picture-initials"
														style={{
															border: "1px solid #d9d9d9",
															width: "32px",
															minWidth: "32px",
															maxWidth: "32px",
															height: "32px",
														}}
													>
														<span
															className="profile-initials"
															style={{ fontSize: "12px" }}
														>
															{getInitials(
																props.userInfo.user?.name ||
																	props.userInfo.user?.displayName,
																0
															)}
														</span>
													</div>
												)}
											</Dropdown>
										</div>
									</div>
								</Header>
								<Content className="site-content">
									<Spin
										//Maximum ZIndex
										style={{ zIndex: 99999 }}
										size="large"
										tip={
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												{/* <span style={{ fontSize: "30px" }}>{ModelLoadDesc}</span> <Progress style={{ width: "50%" }} percent={ModelLoadPercent} /> */}
											</div>
										}
										indicator={
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													top: 0,
													insetInlineStart: 0,
													height: "100%",
													width: "100%",
													margin: 0,
												}}
											>
												<img
													style={{ position: "absolute", width: "10%" }}
													src={elementalLoadingLogo}
												/>
												<img
													className="spin"
													style={{ position: "absolute", width: "15%" }}
													src={elementalLoadingOutline}
												/>
											</div>
										}
										spinning={isLoading}
									>
										<ErrorBoundary
											resetKeys={[errorBoundaryKey]}
											fallbackRender={handleErrorFallback}
											onError={logError}
										>
											<Outlet />
										</ErrorBoundary>
									</Spin>
								</Content>
								<Footer className="site-footer">
									<span>Version {file.version}</span>
									<Space size={15}>
										<span>&copy; 2024 Elemental Energies</span>
										<Image
											preview={false}
											width="50px"
											src={
												props.userInfo.user?.theme === "light"
													? terraLogoFooterLight
													: terraLogoFooterDark
											}
										/>
									</Space>
								</Footer>

								<LeaveWithChangesModal
									isFormUpdating={formUpdatingRef.current}
									setFormUpdating={handleFormUpdating}
									pageChange={pageChange}
								/>

								<ContextMenu
									position={contextMenuPosition}
									info={contextMenuInfo}
								/>
							</Layout>
						</Layout>
					</HomeContext.Provider>
				);
			}}
		</MainContext.Consumer>
	);
};

Home.propTypes = {
	userInfo: PropTypes.any,
	currentLocation: PropTypes.any,
	menu: PropTypes.any,
	tenants: PropTypes.any,
};

export default Home;
