import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Layout, Image, Popconfirm, Popover, Button } from "antd";
import { setHomeLogo } from "../utils/company_logo";
import { GetAntIcon } from "../utils/ant_icons";
import { logout } from "../utils/utils";
import { superAdminRole } from "../utils/_exports";
import { useNavigate } from "react-router";
import Emitter from "../utils/emitter";
import { HomeContext } from "../containers/Home";
import usePermission from "../hooks/usePermission";

const { Sider } = Layout;

export const MainMenuItems = () => {
	const context: any = useContext(HomeContext); // I need to declare context here to use usePermission.
	const allowedViewProjects = usePermission("View.Project");
	const allowedViewWells = usePermission("View.Well");

	const initialMainMenu: any = [
		{
			key: "dashboard-menu",
			label: "Dashboard",
			propTitle: "Dashboard",
			container: "dashboard",
			icon: GetAntIcon("global"),
			to: "/dashboard",
		},
		{
			key: "project-menu",
			label: "Projects",
			propTitle: "Projects",
			container: "projects",
			icon: GetAntIcon("project"),
			isPermissible: allowedViewProjects,
			to: "/projects",
			child: [
				{
					propTitle: "Add new project",
					to: "/add-new-project",
					key: "add-new-project",
				},
				{
					propTitle: "Project settings",
					to: "/project-settings",
					key: "project-settings",
				},
				{
					propTitle: "Operation settings",
					to: "/operation-settings",
					key: "Operation settings",
				},
			],
		},
		{
			key: "well-menu",
			label: "Wells",
			propTitle: "Wells",
			container: "wells",
			icon: GetAntIcon("well-icon"),
			isPermissible: allowedViewWells,
			to: "/wells",
			child: [
				{
					propTitle: "Well settings",
					to: "/well-settings",
					key: "Well settings",
				},
			],
		},
	];

	const initialExtraMenu: any = [
		// Help Menu Button
		{
			key: "help-menu",
			label: "Help center",
			propTitle: "Help center",
			className: "extra-menu-item",
			container: "helpCenter",
			to: "/help-center",
			icon: GetAntIcon("question3"),
			child: [
				{
					propTitle: "Well Delivered",
					to: "/well-delivered",
					key: "Well delivered",
				},
			],
		},
		// Administration Button
		{
			key: "admin-menu",
			label: "Administration",
			propTitle: "Administration",
			className: "extra-menu-item",
			container: "admin",
			to: "/administration",
			icon: GetAntIcon("setting"),
			requires_admin: true,
		},
	];

	const profileMenu: any = [
		// Profile
		{
			key: "user-profile",
			label: "My profile",
			propTitle: "My Profile",
			container: "profile",
			to: "/profile",
		},
	];

	return { initialMainMenu, initialExtraMenu, profileMenu };
};

const MainMenu = (props: any) => {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();
	const [selectedKeys, setSelectedKeys] = useState<any>([]);

	const { initialMainMenu, initialExtraMenu, profileMenu } = MainMenuItems();

	// Render Menu
	const renderMenu = () => {
		const createMainMenu = (roles: any) => {
			if (context.allData && context.currentTenantData) {
				let email = context.allData.userInfo.user.email;
				let isAdmin =
					roles?.includes(superAdminRole) ||
					context?.currentTenantData?.tenant_admin?.includes(email);

				return initialMainMenu.map((menuItem: any) => {
					if ("isPermissible" in menuItem) {
						const isPermissible = menuItem.isPermissible;
						if (!isPermissible) {
							return null;
						} else {
							// Check if the menu item has children
							if (menuItem.children) {
								// Filter the child items based on requires_admin
								const filteredChildren = menuItem.children
									.map((childItem: any) => {
										if (childItem.requires_admin) {
											return roles?.includes(superAdminRole) ? childItem : null;
										}
										return childItem;
									})
									.filter(Boolean); // Remove nulls

								// Only return the parent if it has visible children
								if (filteredChildren.length > 0) {
									return { ...menuItem, children: filteredChildren };
								}
								return null;
							}

							// Return the menuItem if no specific requires_admin logic is applied
							return menuItem;
						}
					}

					// Handle requires_admin on parent level
					if (menuItem.requires_admin && !isAdmin) {
						return null;
					}
					// Check if the menu item has children
					if (menuItem.children) {
						// Filter the child items based on requires_admin
						const filteredChildren = menuItem.children
							.map((childItem: any) => {
								if (childItem.requires_admin) {
									return roles?.includes(superAdminRole) ? childItem : null;
								}
								return childItem;
							})
							.filter(Boolean); // Remove nulls

						// Only return the parent if it has visible children
						if (filteredChildren.length > 0) {
							return { ...menuItem, children: filteredChildren };
						}
						return null;
					}

					// Return the menuItem if no specific requires_admin logic is applied
					return menuItem;
				});
			} else return [];
		};

		const handleMainMenu = async (info: any) => {
			try {
				await context.handlePageChange();
				sessionStorage.removeItem("main-tabkey");
				sessionStorage.removeItem("admin-tabkey");
				sessionStorage.removeItem("tabkey");
				if (info.item.props.to) {
					setSelectedKeys([info.key]);
					Emitter.emit("menuChange", [info.item.props]);
					navigate(info.item.props.to);
				}
			} catch {}
		};
		return (
			<Menu
				className="main-menu"
				mode="inline"
				defaultSelectedKeys={["dashboard-menu"]}
				selectedKeys={props.selectedKey || selectedKeys}
				items={createMainMenu(props.roles)}
				onClick={(info: any) => {
					handleMainMenu(info);
				}}
			></Menu>
		);
	};

	// Render Extra Menu
	const renderExtraMenu = () => {
		// Inner function to create the filtered extra menu based on roles
		const createExtraMenu = (roles: any) => {
			// Map through `initialExtraMenu` to create menu items
			return initialExtraMenu.map((menuItem: any) => {
				// If menuItem requires admin and user is not an admin or tenant-admin, exclude this menuItem
				if (
					menuItem.requires_admin &&
					!roles?.includes(superAdminRole) &&
					!roles?.includes("tenant-admin")
				) {
					return null;
				}

				// Check if the menuItem has child items (submenu)
				if (menuItem.children) {
					// Filter the children based on admin requirements
					const filteredChildren = menuItem.children
						.map((childItem: any) => {
							// Only include child items requiring admin if user has the necessary role
							if (childItem.requires_admin) {
								return roles?.includes(superAdminRole) ||
									roles.includes("tenant-admin")
									? childItem
									: null;
							}
							return childItem; // Include child if no admin requirement
						})
						.filter(Boolean); // Remove any nulls from filtered children

					// Return the parent item only if it has visible children
					if (filteredChildren.length > 0) {
						return { ...menuItem, children: filteredChildren };
					}
					return null; // Exclude the parent if no visible children
				}

				// Return the menuItem if no specific requires_admin logic is applied
				return menuItem;
			});
		};

		// Function to handle actions when a menu item is clicked
		const handleExtraMenu = async (info: any) => {
			try {
				await context.handlePageChange(); // Handle page change context
				sessionStorage.removeItem("admin-tabkey"); // Clear session storage for admin-tabkey
				sessionStorage.removeItem("tabkey"); // Clear session storage for tabkey

				// Navigate to a new page if the menu item has a `to` property
				if (info.item.props.to) {
					Emitter.emit("menuChange", [info.item.props]); // Emit menu change event
					setSelectedKeys([info.key]); // Update selected menu key

					// Check if admin requirement is met and navigate
					if (info.item.props.requires_admin) {
						navigate(info.item.props.to);
					} else {
						navigate(info.item.props.to);
					}
				}

				// If `logout` is clicked
				if (info.key === "logout") {
					localStorage.removeItem("idToken"); // Remove idToken
					localStorage.removeItem("accessToken"); // Remove accessToken
					logout(); // Call logout function
				}

				// If `manage-account` is clicked
				if (info.key === "manage-account") {
					window.open(
						"https://myaccount.microsoft.com/?ref=MeControl",
						"_blank"
					);
				}
			} catch (error) {
				console.error(error);
			}
		};

		return (
			<Menu
				selectedKeys={props.selectedKey || selectedKeys}
				className="extra-menu"
				style={{
					marginTop: "auto",
				}}
				mode="inline"
				items={createExtraMenu(props.roles)}
				onClick={(info: any) => {
					handleExtraMenu(info);
				}}
			/>
		);
	};

	return (
		<>
			{props.option != "Mega" ? (
				<Sider
					className="main-sider"
					collapsed={props.collapsed}
					onCollapse={props.onCollapse}
					collapsible
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "20px",
							marginBottom: "15px",
						}}
					>
						{setHomeLogo()}
					</div>
					{renderMenu()}
					{renderExtraMenu()}
				</Sider>
			) : (
				<></>
			)}
		</>
	);
};

MainMenu.propTypes = {
	collapsed: PropTypes.bool,
	onCollapse: PropTypes.func,
	option: PropTypes.any,
	roles: PropTypes.array,
	selectedKey: PropTypes.any,
};

export default MainMenu;
