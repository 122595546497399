import { Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { getAllProjects, getProjects } from "../services/api-server/projects";
import { getAllTimeEntriesByTenant } from "../services/api-server/timewriting";
import { GetAntIcon } from "../utils/ant_icons";
import { HomeContext } from "./Home";
import TimewritingWeeklySummary from "./TimewritingWeeklySummary";
import TimeBlock from "./TimeBlock";
import Emitter from "../utils/emitter";
import moment from "moment";
import TimeEntryModal from "../components/Modal/TimeEntryModal";
import TimesheetManagement from "./TimesheetManagement";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// function usePosts() {
// 	return useQuery({
// 	  queryKey: ['posts'],
// 	  queryFn: async (): Promise<Array<Post>> => {
// 		const response = await fetch('https://jsonplaceholder.typicode.com/posts')
// 		return await response.json()
// 	  },
// 	})
//   }

dayjs.extend(utc);
dayjs.extend(timezone);

function Timewriting(props: any) {
	const [activePage, setActivePage] = useState<any>("weekly-summary");
	// Data Fetching States
	const [projects, setProjects] = useState<any>([]);
	const [projectsByTenant, setProjectsByTenant] = useState<any>([]);
	const [tenants, setTenants] = useState<any>([]);
	const [timeEntries, setTimeEntries] = useState<any>([]);
	// Button Reference (Add time entry button)
	const buttonRef = useRef<HTMLDivElement>(null);
	// Modal States
	const [openModal, setOpenModal] = useState(false);
	const [matchedEntry, setMatchedEntry] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	// Date Handling
	const [weekRange, setWeekRange] = useState({ start: "", end: "" });
	const [weekOffset, setWeekOffset] = useState(0);
	const [daysOfWeek, setDaysOfWeek] = useState<
		// day: "2025-01-14", string: "Monday", dayNumber: "01"
		{ day: string; name: string; dayNumber: string }[]
	>([]);
	const [selectedCol, setSelectedCol] = useState<any>(null);
	const [isEnabledLastTwoRows, setIsEnabledLastTwoRow] = useState<any>(false);
	const [modalElementType, setModalElementType] = useState<any>(null);
	const [modalPosition, setModalPosition] = useState<{
		top: number | null;
		left: number | null;
		right: number | null;
	}>({
		top: null,
		left: null,
		right: null,
	});
	// Initial Modal Values
	const [initialValues, setInitialValues] = useState<any>({
		project_id: null,
		uuid: null,
		tenant_key: null,
		date: moment(),
		time_type: null,
		duration_hours: 0,
		duration_mins: 0,
		start_time: 0,
		end_time: 0,
		description: null,
	});

	// Data Fetching Handling
	const context: any = useContext(HomeContext);
	console.log(props);

	useEffect(() => {
		if (context.currentTenantKey && context.tenantData) {
			let tenantValues = context.tenantData.map((tenant: any) => ({
				value: tenant.value,
				isTenantMember:
					tenant?.tenant_admin?.includes(props?.params?.user?.email) ||
					tenant?.tenant_members?.includes(props?.params?.user?.email),
			}));

			// Get all projects data across all tenants
			getAllProjects(
				context.currentTenantKey,
				tenantValues,
				props?.params?.user?.email
			)
				.then((response: any) => {
					// Sort projects alphabetically by `project_name`
					const sortedProjects = response.sort((a: any, b: any) => {
						if (a.project_name.toLowerCase() < b.project_name.toLowerCase())
							return -1;
						if (a.project_name.toLowerCase() > b.project_name.toLowerCase())
							return 1;
						return 0;
					});
					setProjects(sortedProjects);
					// console.log("Projects: ", sortedProjects);
				})
				.catch((error: any) => {
					console.log(error);
				});

			// Get all projects data by specific tenant
			getProjects(
				context.currentTenantKey,
				context.currentTenantData.company_list
			)
				.then((response: any) => {
					setProjectsByTenant(response);
					// console.log("Projects By Tenant: ", response, context.currentTenantData);
				})
				.catch((error: any) => {
					console.log(error);
				});

			// Get all time entries data across all tenants
			getAllTimeEntriesByTenant()
				.then((response: any) => {
					if (Array.isArray(response)) {
						const validEntries = response.map((entry: any) => ({
							...entry,
							date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
						}));
						setTimeEntries(validEntries);
						console.log("Time Entries: ", validEntries);
					} else {
						console.warn("Response is not an array, setting to empty array.");
						setTimeEntries([]);
					}
				})
				.catch((error: any) => {
					console.error("Error fetching time entries:", error);
					setTimeEntries([]);
				});
		}

		setTenants(context.tenantData);
		// console.log(
		// 	"Tenants: ",
		// 	tenants,
		// 	"Current tenant key: ",
		// 	context.currentTenantKey
		// );
	}, [context.currentTenantKey, context.tenantData]);

	useEffect(() => {
		const today = new Date();
		const startOfWeek = new Date(
			today.setDate(today.getDate() - today.getDay() + 1 + weekOffset * 7)
		);
		const dayNames: string[] = [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday",
		];

		const formatDate = (date: Date) =>
			date.toLocaleDateString("en-US", {
				day: "numeric",
				month: "long",
				year: "numeric",
			});

		const generatedDays = Array.from({ length: 7 }, (_, i) => {
			const currentDay = new Date(startOfWeek);
			currentDay.setDate(startOfWeek.getDate() + i); // Increment days
			return {
				day: currentDay.toISOString().slice(0, 10), // Format: YYYY-MM-DD
				name: dayNames[i],
				dayNumber: String(currentDay.getDate()), // Use exact date of the day
			};
		});

		setWeekRange({
			start: formatDate(startOfWeek),
			end: formatDate(new Date(startOfWeek.setDate(startOfWeek.getDate() + 6))), // Sunday
		});
		setDaysOfWeek(generatedDays);
	}, [weekOffset]);

	// Alert Handling
	const handleShowAlert = (alertMessage: any) => {
		Emitter.emit("alert", {
			type: "success",
			message: alertMessage,
			description: "",
			top: true,
			closeable: false,
			timeout: 3000,
		});
	};

	// Refresh Time Entries
	//get timeEntry from timeEntries if exist
	const refreshTimeEntries = () => {
		getAllTimeEntriesByTenant()
			.then((response: any) => {
				if (Array.isArray(response)) {
					const validEntries = response.map((entry: any) => ({
						...entry,
						date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
					}));
					setTimeEntries(validEntries);
					console.log("Time Entries: ", validEntries);
				} else {
					console.warn("Response is not an array, setting to empty array.");
					setTimeEntries([]);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching time entries:", error);
				setTimeEntries([]);
			});
	};

	// delete timeEntry from timeEntries if exist
	const deleteTimeEntry = (deleteEntryKey: any) => {
		let currentTimeEntries = [...timeEntries];
		//find timeEntry index from timeEntries by uuid
		const timeEntryIndex = currentTimeEntries.findIndex(
			(entry: any) => entry.uuid === deleteEntryKey
		);

		//remove timeEntry from timeEntries based on index
		if (timeEntryIndex !== -1) {
			currentTimeEntries.splice(timeEntryIndex, 1);
			setTimeEntries(currentTimeEntries);
		}
	};

	// Handle Calculate Day/Column Duration
	const handleCalculateDayDuration = (day: any) => {
		let totalDayHours = 0;
		let totalDayMinutes = 0;

		// Iterate through each `timeEntries` array
		timeEntries?.forEach((entry: any) => {
			// ``
			if (entry.date === day) {
				if (entry.time_type === "duration") {
					totalDayHours += entry.duration_hours;
					totalDayMinutes += entry.duration_mins;
				} else if (entry.time_type === "startend") {
					const startTimeMoment = moment(entry.start_time);
					const endTimeMoment = moment(entry.end_time);

					if (startTimeMoment && endTimeMoment) {
						const durationInMinutes = endTimeMoment.diff(
							startTimeMoment,
							"minutes"
						);

						totalDayMinutes += durationInMinutes;
					}
				}
			}
		});

		totalDayHours += Math.floor(totalDayMinutes / 60);
		totalDayMinutes = totalDayMinutes % 60;

		return `${totalDayHours}h ${totalDayMinutes}m`;
	};

	// Handle Calculate Week Duration
	const handleCalculateWeekDuration = () => {
		let totalWeekHours = 0;
		let totalWeekMinutes = 0;

		// Loop through each day in the week
		daysOfWeek.forEach((day) => {
			// Call handleCalculateDayDuration(day.day), which `return` the duration for a single day as a string (e.g., "8h 30m").
			// Split the string into parts `dayHours` and `dayMinutes` and parse the numbers.
			let [dayHours, dayMinutes] = handleCalculateDayDuration(day.day)
				.split(" ") // Split the string into an array, e.g., ["8h", "30m"]
				.map(
					(val, i) =>
						i % 2 === 0 // Check if the current index is even
							? parseInt(val.replace("h", "")) // Remove the "h" from hours and parse it as an integer
							: parseInt(val.replace("m", "")) // Remove the "m" from minutes and parse it as an integer
				);

			// Add the day's hours and minutes to the total week hours and minutes
			totalWeekHours += dayHours;
			totalWeekMinutes += dayMinutes;
		});

		totalWeekHours += Math.floor(totalWeekMinutes / 60); // Divide minutes by 60 to get additional hours
		totalWeekMinutes = totalWeekMinutes % 60; // Get the remaining minutes after converting to hours

		return `${totalWeekHours}h ${totalWeekMinutes}m`;
	};

	// Handle Calculate Month Duration
	const handleCalculateMonthDuration = () => {
		let totalMonthHours = 0;
		let totalMonthMinutes = 0;

		// Get the month from the start of the selected week range
		const startDate = new Date(weekRange.start);
		const currentMonth = startDate.getMonth();
		const currentYear = startDate.getFullYear();

		// Get the first and last day of the selected month
		const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // First day of current month
		const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0); // Last day of current month

		// Iterate through each day of the month
		for (
			let date = new Date(firstDayOfMonth);
			date <= lastDayOfMonth;
			date.setDate(date.getDate() + 1)
		) {
			const formattedDay = date.toISOString().slice(0, 10); // Format: YYYY-MM-DD

			// Calculate the duration for the current day using handleCalculateDayDuration
			const dayDuration = handleCalculateDayDuration(formattedDay);
			if (dayDuration) {
				let [dayHours, dayMinutes] = dayDuration
					.split(" ")
					.map((val, i) =>
						i % 2 === 0
							? parseInt(val.replace("h", ""))
							: parseInt(val.replace("m", ""))
					); // Only get hours, ignore minutes
				totalMonthHours += dayHours;
				totalMonthMinutes += dayMinutes;
			}
		}

		totalMonthHours += Math.floor(totalMonthMinutes / 60);
		totalMonthMinutes = totalMonthMinutes % 60;

		return `${totalMonthHours}h`; // Return only the total hours for the selected month
	};

	const handleShowModalCell = (
		event: React.MouseEvent<HTMLElement>, // Captures the mouse click event on a div element.
		day: string, // The day associated with the cell
		projectId: string, // Unique identifier for the project
		elementType: "div" | "button"
	) => {
		const target = event.currentTarget as HTMLElement;
		const cellRect = target.getBoundingClientRect();
		let modalPositionConfig;

		// Determine if the project is one of the last two projects
		const enableLastTwoRows = isLastTwoProjects(projectId);
		setIsEnabledLastTwoRow(enableLastTwoRows);

		// Modal shows on the right
		if (["Monday", "Tuesday", "Wednesday", "Thursday"].includes(day)) {
			if (elementType === "button") {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415 // Altered Modal
						: cellRect.top - 305, // Default Modal
					right: null,
					left: cellRect.right - 60,
				};
			} else {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415
						: cellRect.top - 255,
					right: null,
					left: cellRect.right - 80,
				};
			}
		} else {
			// If element type is button
			if (elementType === "button") {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415 // Altered Modal
						: cellRect.top - 305, // Default Modal
					right: window.innerWidth - cellRect.left + 10,
					left: null,
				};
			} else {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415
						: cellRect.top - 255,
					right: window.innerWidth - cellRect.left - 10,
					left: null,
				};
			}
		}

		setOpenModal(true);
		setModalPosition(modalPositionConfig);
		setModalElementType(elementType);
		setSelectedCol(day);
	};

	// Helper function to determine if the project is one of the last two
	const isLastTwoProjects = (projectId: string): boolean => {
		// Example logic to determine if a project is in the last two rows
		const lastTwoProjectIds = getLastTwoProjectIds();
		return lastTwoProjectIds.includes(projectId);
	};

	// Function to retrieve the IDs of the last two projects
	const getLastTwoProjectIds = (): string[] => {
		// Replace this with your logic for retrieving the last two project IDs
		const allProjectIds = projects.map((project: any) => project._id);
		return allProjectIds.slice(-2); // Get the last two project IDs
	};

	const handleSetTimeEntryRowCol = (day: string, projectId: any) => {
		const entry = timeEntries.find((entry: any) => {
			const entryDate = entry.date;

			if (activePage === "weekly-summary") {
				return entry.project_id === projectId && entryDate === day;
			}

			return false;
		});

		if (entry) {
			const matchedEntry = entry.date === day ? entry : null;

			if (matchedEntry) {
				if (activePage === "weekly-summary") {
					if (entry.time_type === "duration") {
						return `${matchedEntry.duration_hours}h ${matchedEntry.duration_mins}m`;
					} else {
						const durationInMinutes = entry.duration;

						const hours = Math.floor(durationInMinutes / 60);
						const minutes = durationInMinutes % 60;
						return `${hours}h ${minutes}m`;
					}
				}
			}
		}
	};

	const handleSelectedTimeEntryData = (
		day?: string,
		projectId?: any,
		time: any = null
	) => {
		// Find the time entry that matches both `day` and `projectId`
		const timeEntry = timeEntries.find((entry: any) => {
			const entryDate = new Date(entry.date).toISOString().slice(0, 10);

			return entry.project_id === projectId && entryDate === day;
		});

		// console.log("Time Entry: ", timeEntry);
		if (timeEntry) {
			// Set `matchedEntry` status to true
			setMatchedEntry(true);
			// Populate `initialValues`
			setInitialValues({
				...timeEntry,
				date: moment(timeEntry.date),
				...(timeEntry.time_type === "startend" && {
					// `start_time` and `end_time` are originally saved as unix timestamp of type `Number`
					// Convert `timeEntry.start_time` to `Number` -> `utc` -> `moment`
					start_time: dayjs(Number(timeEntry.start_time)),
					end_time: dayjs(Number(timeEntry.end_time)),
				}),
			});
			// Set `isLoading` to tsrue to prevent the modal from closing
			setIsLoading(true);

			// console.log(
			// 	"Initial Values: ",
			// 	initialValues,
			// );
			return;
		} else {
			if (!time) {
				setInitialValues({
					project_id: projectId,
					tenant_key: context.currentTenantKey,
					time_type: "duration",
					date: moment(day),
				});
			} else {
				const timeSet = new Set(time);
				// Convert the Set to an array and map it to Moment objects
				const dateArray = Array.from(timeSet).map((dateString: any) =>
					moment(dateString, "H-YYYY-MM-DD")
				);

				// Find the min and max dates
				let minDate = moment.min(dateArray).clone();
				let maxDate = moment.max(dateArray).clone();

				// Check if maxDate's hour is 23 before deciding the time to add
				if (maxDate.hour() === 23) {
					// If it's 23, add 45 minutes
					maxDate = maxDate.add(45, "minutes");
				} else {
					// Otherwise, add 1 hour
					maxDate = maxDate.add(1, "hours");
				}

				setInitialValues({
					project_id: projectId,
					tenant_key: context.currentTenantKey,
					start_time: dayjs(minDate.valueOf()),
					end_time: dayjs(maxDate.valueOf()),
					time_type: "startend",
					date: moment(day),
				});
			}
		}
	};

	return (
		<>
			{/* Timewriting Container */}
			<div className="generic-container" style={{ gap: "20px" }}>
				<div
					className="main-container"
					style={{
						flex: 1,
						width: "100%",
						maxHeight: "100%",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
						borderRadius: "5px",
					}}
				>
					{/* Container Panel */}
					<div className="project-header">
						{/* Tab Buttons */}
						<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
							{/* Weekly Summary Tab Button */}
							<div
								className={
									activePage === "weekly-summary" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("weekly-summary");
								}}
							>
								{GetAntIcon("project")}
								<span>Weekly summary</span>
							</div>
							{/* Time Block Tab Button */}
							<div
								className={
									activePage === "time-block" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									alignItems: "center",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("time-block");
								}}
							>
								{GetAntIcon("timewriting")}
								<span>Time block</span>
							</div>
							{/* Timesheet Management Tab Button */}
							<div
								className={
									activePage === "timesheet-management"
										? "project-active-button"
										: ""
								}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("timesheet-management");
								}}
							>
								{GetAntIcon("clock-filled")}
								<span>Timesheet management</span>
							</div>
						</div>
						{/* Add time entry Button */}
						<div
							style={{
								display: "flex",
								flex: "1",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							<div
								ref={buttonRef}
								style={{
									height: "31.74px",
									padding: "4px 15px",
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
									borderRadius: "2px",
									cursor: "pointer",
								}}
								className="ant-btn-primary"
								onClick={(e) => {
									setOpenModal(true);
									setInitialValues({
										tenant_key: context.currentTenantKey,
										time_type: "duration",
									});
								}}
							>
								+ Add time entry
							</div>
						</div>
					</div>
					{/* Container Content */}
					<div
						className="generic-content project-overview-container"
						style={{
							overflowY: "hidden",
							flex: 1,
							display: "flex",
							flexWrap: "inherit",
							flexDirection: "column",
							alignItems: "flex-start",
							padding: 0,
							gap: 0,
							overflowX: "auto",
							maxHeight: "100%",
						}}
					>
						{/* Table */}
						<div
							className="timewriting-table"
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								height: "100%",
							}}
						>
							{/* Table Header */}
							<div
								className="timewriting-table-header"
								style={{
									display: "flex",
									height: "100%",
									flexDirection: "column",
									backgroundColor: "#0C0C2A",
								}}
							>
								{/* Table Header First Row */}
								<div
									style={{
										gridColumn: "1 / -1",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										padding: "15px 12px",
									}}
								>
									{/* First Row Left Container */}
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "16px",
										}}
									>
										<Button
											shape="circle"
											onClick={() => setWeekOffset((prev) => prev - 1)}
										>
											{GetAntIcon("leftarrow")}
										</Button>
										<span>
											{weekRange.start} - {weekRange.end}
										</span>
										<Button
											shape="circle"
											onClick={() => setWeekOffset((prev) => prev + 1)}
										>
											{GetAntIcon("rightarrow")}
										</Button>
									</div>
									{/* First Row Right Container */}
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "15px",
										}}
									>
										<div>
											<span style={{ fontStyle: "italic" }}>
												Weekly hours ={" "}
											</span>
											{handleCalculateWeekDuration()}
											<span> | </span>
											<span style={{ fontStyle: "italic" }}>
												Monthly hours ={" "}
											</span>
											{handleCalculateMonthDuration()}
										</div>
										<span>
											<Button className="ant-btn-primary">
												Submit for review
											</Button>
										</span>
									</div>
								</div>
								{activePage == "weekly-summary" && (
									<TimewritingWeeklySummary
										// Modal
										openModal={openModal}
										setOpenModal={setOpenModal}
										projects={projects}
										tenants={tenants}
										timeEntries={timeEntries}
										daysOfWeek={daysOfWeek}
										weekRange={weekRange}
										handleCalculateDayDuration={handleCalculateDayDuration}
										matchedEntry={matchedEntry}
										setMatchedEntry={setMatchedEntry}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										isLastTwoProjects={isLastTwoProjects}
										getLastTwoProjectIds={getLastTwoProjectIds}
										handleShowModalCell={handleShowModalCell}
										handleSetTimeEntryRowCol={handleSetTimeEntryRowCol}
										handleSelectedTimeEntryData={handleSelectedTimeEntryData}
									/>
								)}
								{activePage == "time-block" && (
									<TimeBlock
										// Modal
										openModal={openModal}
										setOpenModal={setOpenModal}
										projects={projects}
										tenants={tenants}
										timeEntries={timeEntries}
										daysOfWeek={daysOfWeek}
										weekRange={weekRange}
										handleShowModalCell={handleShowModalCell}
										handleCalculateDayDuration={handleCalculateDayDuration}
										handleCalculateWeekDuration={handleCalculateWeekDuration}
										handleCalculateMonthDuration={handleCalculateMonthDuration}
										handleSetTimeEntryRowCol={handleSetTimeEntryRowCol}
										handleSelectedTimeEntryData={handleSelectedTimeEntryData}
									/>
								)}
								{activePage == "timesheet-management" && (
									<TimesheetManagement
										projects={projects}
										tenants={tenants}
										timeEntries={timeEntries}
										// Week Navigation
										daysOfWeek={daysOfWeek}
										weekRange={weekRange}
										// Duration Calculation
										handleCalculateDayDuration={handleCalculateDayDuration}
										handleCalculateWeekDuration={handleCalculateWeekDuration}
										handleCalculateMonthDuration={handleCalculateMonthDuration}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Time Entry Modal */}
			<TimeEntryModal
				// Modal Open Handling
				userData={props?.params?.user}
				openModal={openModal}
				setOpenModal={setOpenModal}
				tenants={tenants}
				projects={projects}
				// Modal Input Data Preset Handling
				matchedEntry={matchedEntry}
				setMatchedEntry={setMatchedEntry}
				initialValues={initialValues}
				setInitialValues={setInitialValues}
				refreshTimeEntries={refreshTimeEntries}
				deleteTimeEntry={deleteTimeEntry}
			/>
		</>
	);
}

export default Timewriting;
